import { Icon, Typography } from "@/components/atoms";
import { getPhpHostUrl } from "@/helpers/apiHelpers";

interface RatingLinkProps extends React.HTMLAttributes<HTMLDivElement> {
  name: string;
  path: string;
  hasIcon?: boolean;
}

export const RatingLink = (ratingLinkProps: RatingLinkProps) => {
  const { name, path, hasIcon = false, className, ...props } = ratingLinkProps;
  const phpHostUrl = getPhpHostUrl();

  if (!path) {
    return (
      <div className={className} {...props}>
        <Typography variant="paragraph">{name}</Typography>
      </div>
    );
  }

  return (
    <div className={className} {...props}>
      <a href={`${phpHostUrl}${path}`} className="flex w-fit items-center gap-x-2 hover:text-info">
        <Typography variant="paragraph">{name}</Typography>
        {hasIcon && <Icon name="ExportSquare" size="xs" className="flex-shrink-0" />}
      </a>
    </div>
  );
};
