import { PageHeader, PrivateNetworkFleetLeadersPanel } from "@/components/organisms";

export const PrivateNetworkFleetLeaders = () => {
  return (
    <div className="mb-12 flex flex-1 flex-col">
      <PageHeader title="Private Network" />
      <PrivateNetworkFleetLeadersPanel />
    </div>
  );
};
