import ExecutiveAssistant from "@/assets/images/signup/executive-assistant.svg";
import Driver from "@/assets/images/signup/driver.svg";
import TravelManager from "@/assets/images/signup/travel-manager.svg";
import Operator from "@/assets/images/signup/operator.svg";
import Passenger from "@/assets/images/signup/passenger.svg";
import { UserType, UserTypeData } from "./types";

export const userTypes: Record<UserType, UserTypeData> = {
  operator: {
    type: "operator",
    name: "Transport Business Owner",
  },
  driver: {
    type: "driver",
    name: "Driver",
  },
  travel_manager: {
    type: "travel_manager",
    name: "Event / Travel Manager",
  },
  executive_assistant: {
    type: "executive_assistant",
    name: "Executive Assistant",
  },
  passenger: {
    type: "passenger",
    name: "Customer / Rider",
  },
};

export const userTypeImage: Record<string, string> = {
  passenger: Passenger,
  executive_assistant: ExecutiveAssistant,
  travel_manager: TravelManager,
  driver: Driver,
  operator: Operator,
};

export const operatorInfo = [
  {
    title: "Your Basic Information",
    description: "Provide some basic information. This will form the account you will log in with for managing day to day operations.",
  },
  {
    title: "Business Information",
    description: "Tell us a little about your business. This will help so we know who you are",
  },
  {
    title: "Additional Information",
    description:
      "Now please provide a few numbers. These will help us set up your account correctly and do some capacity planning on our end. If you are unsure, go ahead and provide an estimate.",
  },
  {
    title: "Compliance Information",
    description:
      "You must provide your company registration information for each state that you operate in. This allows us to maintain compliance with all drivers and operators in the network and ensure trips are only done by authorised persons.",
  },
];

export const hearFromUsOptions = [
  "A friend/colleague referred me to you",
  "We used to use you in my old place of work",
  "Billboard Advertising",
  "Media / Newspapers/ Online articles",
  "Google",
  "Billboard Advertising",
  "Facebook",
  "Instagram",
  "LinkedIn",
  "A link from another website",
  "Tripadvisor",
  "Point Hacks",
  "QR Code",
  "Other",
];

export const businessTypeOptions = [
  { name: "Professional limo transfer business", value: "LIMOUSINE" },
  { name: "Specialty limousines business (stretch limos, hummers etc)", value: "SPECIAL_VEHICLE" },
  { name: "Professional Minibus & people mover business", value: "MINI_BUS" },
  { name: "Rideshare transfers business", value: "RIDE_SHARE" },
  { name: "All the above", value: "ALL" },
  { name: "Other", value: "OTHER" },
];

export const platformGoalsOptions = [
  "I want to increase my revenue",
  "I want to automate all of the booking process",
  "I want to grow interstate by using the RideMinder network",
  "I want to earn a commission from the work that I offload locally",
  "I want to earn a commission from the work that I offload interstate",
  "I want the system to charge my clients automatically",
  "I want the system to pay the drivers automatically",
  "All of the above",
];