import { Avatar, Icon, Modal, Typography } from "@/components/atoms";
import { useAppSelector } from "@/redux/hooks";
import { activePassengerSelector, passengersSelector } from "@/redux/slices/passenger/selectors";
import { Passenger } from "@/redux/slices/passenger/types";
import { clsx, getAssetUrl } from "@/utils";
import { useNavigate } from "react-router-dom";

interface AccountsModalProps {
  open: boolean;
  onClose: () => void;
}

export const AccountsModal = ({ open, onClose }: AccountsModalProps) => {
  const navigate = useNavigate();
  const activePassenger = useAppSelector(activePassengerSelector);
  const passengers = useAppSelector(passengersSelector);

  const handleAccountSelect = (passenger: Passenger) => {
    navigate(`/passenger/${passenger.id}/my-bookings`);
    onClose();
  };

  const renderHeader = () => (
    <div className="flex flex-row items-center">
      <Typography variant="title" className="flex-1">
        Accounts
      </Typography>
      <Icon name="close" isCustom className="flex cursor-pointer justify-end" onClick={onClose} />
    </div>
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      className="w-[85vw] md:w-[550px] lg:w-[550px]"
    >
      {renderHeader()}
      <Typography variant="paragraph" className="text-neutral-dark-gray mt-3">Here are the accounts you have access to:</Typography>
      <div className="flex flex-col mt-2 gap-2.5 h-[254px] overflow-y-auto">
        {passengers.map(passenger => (
          <div key={passenger.id} onClick={() => handleAccountSelect(passenger)} className={clsx("flex flex-row items-center py-3 px-5 gap-4 rounded bg-neutral-gray cursor-pointer hover:border hover:border-primary", {
            "bg-primary-light pointer-events-none": passenger.id === activePassenger.id
          })}>
            <Avatar size="xs" src={getAssetUrl(passenger.avatar || "")} alt={`${passenger.firstName} ${passenger.lastName}`} />
            <Typography variant="action">{`${passenger.firstName} ${passenger.lastName}${passenger.isPrimary ? " (You)" : ""}`}</Typography>
          </div>
        ))}
      </div>
    </Modal>
  );
};
