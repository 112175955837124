import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Panel, Skeleton, Typography } from "@/components/atoms";
import { useLazyGetNetworkItemQuery } from "@/redux/apis/network/networkApi";
import { addToast } from "@/utils";
import { PrivateNetworkFleetLeaders } from "./tables/PrivateNetworkFleetLeaders";
import { Pagination } from "@/components/molecules";
import { usePagination } from "@/hooks";
import { networkFleetLeaderPageSelector } from "@/redux/slices/network/selectors";
import { useAppSelector } from "@/redux/hooks";

export const PrivateNetworkFleetLeadersPanel = () => {
  const navigate = useNavigate();
  const { id, networkOperatorId: operatorId } = useParams<{ id: string; networkOperatorId: string }>();
  const [getNetworkItem, { isFetching: isFetchingNetworkItem }] = useLazyGetNetworkItemQuery();
  const { current, count, total, size } = useAppSelector(networkFleetLeaderPageSelector);
  const { handlePageClick, handleSizeChange } = usePagination();
  const [networkName, setNetworkName] = useState("");

  if (!id || !operatorId) {
    navigate("../");
    throw new Error("Missing Network ID or Operator ID");
  }

  useEffect(() => {
    getNetworkItem(id)
      .unwrap()
      .then(({ data }) => setNetworkName(data.operator.name))
      .catch((e) => {
        const message = e.data?.detail;
        addToast("danger", message ? message : "Something went wrong.");
      });
  }, [id, navigate, getNetworkItem, operatorId]);

  if (isFetchingNetworkItem) return <Skeleton />;

  return (
    <section className="contents">
      <nav className="pb-4">
        <Button startIcon="ArrowLeft" variant="tertiary" className="!bg-transparent" onClick={() => navigate("../")}>
          Back
        </Button>
      </nav>
      <Panel className="flex flex-col">
        <header>
          <div className="flex flex-row items-center gap-2">
            <Typography variant="h3" className="leading-8">
              {`${networkName ? `${networkName}'s` : ""} Fleet Leaders`}
            </Typography>
          </div>
          <Typography className="text-neutral-dark-gray">{`${networkName ?? ""} list of Fleet Leaders`}</Typography>
        </header>
        <PrivateNetworkFleetLeaders operatorName={networkName} />
      </Panel>
      <Pagination
        onPageClick={handlePageClick}
        onSizeChange={handleSizeChange}
        currentPage={current}
        pageSize={size}
        totalItems={total}
        pageCount={count}
      />
    </section>
  );
};
