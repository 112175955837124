import { isEmpty } from "ramda";
import { Controller, useFormContext } from "react-hook-form";
import { CheckboxInput, Typography } from "@/components/atoms";
import { FleetFilterFormData } from "../types";

interface TierFilterProps {
  options: Array<{ name: string; value: string }>;
}

export const TierFilter = ({ options }: TierFilterProps) => {
  const { control, setValue, watch } = useFormContext<FleetFilterFormData>();
  const watchTier = watch("tier");

  const renderSelectAll = () => {
    const watchTierLength = watchTier.length;
    return (
      <CheckboxInput
        id="tierSelectAll"
        label="Select all"
        onChange={({ target }) =>
          target.checked
            ? setValue(
                "tier",
                options.map((i) => i.value)
              )
            : setValue("tier", [])
        }
        checked={watchTierLength > 0 && watchTierLength === options.length}
      />
    );
  };

  if (isEmpty(options)) return null;

  return (
    <div className="rounded-lg bg-neutral-surface-gray p-5">
      <Typography variant="paragraph">Select Tier</Typography>
      <Controller
        control={control}
        name="tier"
        render={({ field }) => (
          <div className="mt-[18px] flex flex-col gap-6">
            {renderSelectAll()}
            <div className="grid grid-cols-4 gap-y-6">
              {options.map((i) => (
                <CheckboxInput
                  key={i.value}
                  label={i.name}
                  value={i.value}
                  checked={field.value.includes(i.value)}
                  onChange={({ target }) => {
                    if (target.checked) {
                      field.onChange([...field.value, target.value]);
                    } else {
                      field.onChange(field.value.filter((value) => value !== target.value));
                    }
                  }}
                  variant="check"
                />
              ))}
            </div>
          </div>
        )}
      />
    </div>
  );
};
