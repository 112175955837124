import { Controller, useFormContext } from "react-hook-form";
import { Toggle, Typography } from "@/components/atoms";
import { FleetFilterFormData } from "../types";

interface IsFleetLeaderFilterProps {
  onClickInfo: () => void;
}

export const IsFleetLeaderFilter = ({ onClickInfo }: IsFleetLeaderFilterProps) => {
  const { control } = useFormContext<FleetFilterFormData>();

  return (
    <div className="relative flex flex-col items-center justify-center gap-6 rounded-lg bg-neutral-surface-gray p-[18px]">
      <Controller
        name="isFleetLeader"
        control={control}
        render={({ field }) => (
          <div className="mt-1 flex items-center gap-4">
            <Toggle checked={field.value} onChange={(checked) => field.onChange(checked)} />
            <Typography variant="paragraph">Show Fleet Leaders Only</Typography>
          </div>
        )}
      />
      <Typography variant="action" className="cursor-pointer text-info" onClick={onClickInfo}>
        What is a Fleet Leader?
      </Typography>
    </div>
  );
};
