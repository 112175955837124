import { useForm } from "react-hook-form";
import { Button, Icon, Loading, TextArea, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useBanDriverMutation } from "@/redux/apis/block/blockApi";
import { OffloadDriver } from "@/redux/slices/driver/types";
import { addToast } from "@/utils";

type FormData = {
  banReason: string;
};

interface BanDriverFormProps {
  driver?: OffloadDriver;
  onClose: () => void;
  refetchDrivers: () => void;
}

export const BanDriverForm = ({ driver, onClose, refetchDrivers }: BanDriverFormProps) => {
  const [banDriver, { isLoading }] = useBanDriverMutation();
  const { register, handleSubmit } = useForm<FormData>();

  const onSubmit = handleSubmit((data) => {
    if (driver) {
      const { banReason } = data;

      banDriver({ id: driver.id, comment: banReason })
        .unwrap()
        .then(() => {
          addToast("success", `Banned ${driver.name}`);
          refetchDrivers();
          onClose();
        })
        .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
    } else {
      onClose();
    }
  });

  const renderModalHeader = () => {
    return (
      <div className="sm:flex sm:items-start">
        <div className="my-3 w-full text-center sm:mt-0 sm:text-left">
          <div className="flex flex-row items-center">
            <Typography variant="title" className="flex-1">
              Reason for Banning Driver
            </Typography>
            <Icon name="close" isCustom className="flex cursor-pointer justify-end" onClick={onClose} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="relative">
      {isLoading && <Loading />}
      {renderModalHeader()}
      <form className="flex w-[550px] max-w-full flex-col" onSubmit={onSubmit}>
        <TextArea placeholder="Enter your reason" maxLength={1024} {...register("banReason")} />
        <Typography variant="paragraph" className="mt-4 text-neutral-dark-gray">
          Banning a driver will stop them completing work on your behalf. The reason you will enter will not be visible to the driver
        </Typography>
        <Button type="submit" size="lg" className="mt-8">
          Submit Response
        </Button>
      </form>
    </div>
  );
};
