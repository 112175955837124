import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithProvider } from "@/redux/baseQuery";
import { transformRatingHistory, transformRatings } from "./helpers";
import { EditRatingParams, EditRatingResponse, GetRatingHistoryResponse, GetRatingsParams, GetRatingsResponse } from "./types";

export const ratingApi = createApi({
  reducerPath: "ratingApi",
  baseQuery: baseQueryWithProvider("rating"),
  endpoints: (builder) => ({
    getRatings: builder.query<GetRatingsResponse, GetRatingsParams>({
      query: ({ page, pageSize }) => {
        let queryString = "/driver?";
        if (page) queryString = `${queryString}&page=${page}`;
        if (pageSize) queryString = `${queryString}&page_size=${pageSize}`;
        return queryString;
      },
      providesTags: ["Ratings"],
      transformResponse: transformRatings,
    }),
    editRating: builder.mutation<EditRatingResponse, EditRatingParams>({
      query: ({ id, ...patch }) => ({
        url: `/driver/${id}`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: ["Ratings"],
    }),
    getRatingHistory: builder.query<GetRatingHistoryResponse, string>({
      query: (id) => `/driver/${id}/history?page_size=25`,
      transformResponse: transformRatingHistory,
    }),
  }),
  tagTypes: ["Ratings"],
});

export const { useLazyGetRatingsQuery, useEditRatingMutation, useLazyGetRatingHistoryQuery } = ratingApi;
