import { MyVehiclesActions, MyVehiclesPanel } from "@/components/organisms";
import { MyVehiclesActionMenu } from "@/components/organisms/Vehicles/MyVehicles/MyVehiclesActionMenu";
import { AddVehicle } from "./subpages/AddVehicle";
import { EditVehicle } from "./subpages/EditVehicle";
import { ViewVehicle } from "./subpages/ViewVehicle";

export const MyVehicles = () => {
  return (
    <div className="mb-12 flex flex-1 flex-col py-2">
      <MyVehiclesPanel />
    </div>
  );
};

MyVehicles.ActionMenu = MyVehiclesActionMenu;
MyVehicles.Actions = MyVehiclesActions;
MyVehicles.AddVehicle = AddVehicle;
MyVehicles.EditVehicle = EditVehicle;
MyVehicles.ViewVehicle = ViewVehicle;
