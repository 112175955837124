import { useMemo, useRef, useState } from "react";
import { Button, Icon, Loading, Modal, Spinner, Typography } from "@/components/atoms";
import { useGetVehicleFormOptionsQuery, useVerifyVehicleMutation } from "@/redux/apis/vehicle/vehicleApi";
import { Vehicle } from "@/redux/slices/vehicle/types";
import { formatCurrency } from "@/utils";
import { VehicleVerificationErrorAlert } from "../../AddVehicle/common/VehicleVerificationErrorAlert";
import { getVerificationErrorType } from "../../AddVehicle/helpers";
import { ConfirmRemoveVerificationModal } from "./ConfirmRemoveVerificationModal";
import { ConfirmVerificationChangeModal } from "./ConfirmVerificationChangeModal";

interface UpdateVerificationModalProps extends React.HTMLAttributes<HTMLDivElement> {
  vehicle: Vehicle;
  open: boolean;
  onClose: () => void;
}

export const UpdateVerificationModal = ({ vehicle, open, onClose, ...props }: UpdateVerificationModalProps) => {
  const oldVehicle = useRef<Vehicle | null>();
  const [step, setStep] = useState<string | undefined>();
  const [verifyVehicle, { isLoading, error }] = useVerifyVehicleMutation();
  const { data } = useGetVehicleFormOptionsQuery();

  const fee = useMemo(() => {
    if (!data) return 0;

    return data.fees.verificationFee;
  }, [data]);

  const modelMake = `${vehicle.model.make} ${vehicle.model.model}`;

  const errorType = useMemo(() => {
    if (!error) return undefined;

    return getVerificationErrorType(error);
  }, [error]);

  const handleVerify = () => {
    oldVehicle.current = vehicle;

    verifyVehicle(vehicle.uuid)
      .unwrap()
      .then(() => {
        setStep("confirm-verification-change");
      })
      .catch(() => {
        console.warn("Failed to verify vehicle");
      });
  };

  return (
    <>
      <Modal open={open} onClose={onClose} className="!max-w-[550px] !p-5">
        <div className="relative" {...props}>
          {isLoading && <Loading />}

          <div className="mt-5 flex">
            <Typography variant="title" className="flex-1">
              Update Vehicle Verificaton
            </Typography>
            <div className="flex justify-end">
              <Icon name="close" isCustom className="flex cursor-pointer" onClick={onClose} />
            </div>
          </div>
          <Typography variant="paragraph" className="mt-2 text-neutral-dark-gray">
            To update the verified details, simply re-verify this vehicle by confirming the plate number and state.
          </Typography>
          <div className="flex max-w-lg flex-col">
            {errorType && <VehicleVerificationErrorAlert errorType={errorType} />}

            <div className="rounded bg-neutral-surface-gray p-2.5">
              <Typography variant="paragraph" className="text-neutral-dark-gray">
                {modelMake} VIN
              </Typography>
              <Typography variant="h3" className="uppercase">
                {vehicle.vin}
              </Typography>
            </div>
          </div>
          <div className="mt-6 space-y-2 ">
            <Button variant="primary" onClick={handleVerify} size="lg" className="w-full" disabled={isLoading}>
              {isLoading ? <Spinner /> : `Reverify for ${formatCurrency(fee, 2, "AUD")}`}
            </Button>
            <Button variant="secondary" onClick={() => setStep("remove-verification")} size="lg" className="w-full">
              Remove Verification
            </Button>
          </div>
        </div>
      </Modal>
      <ConfirmRemoveVerificationModal open={step === "remove-verification"} onClose={() => setStep(undefined)} />
      <ConfirmVerificationChangeModal
        oldVehicle={oldVehicle.current ?? undefined}
        newVehicle={vehicle}
        open={step === "confirm-verification-change"}
        onClose={() => setStep(undefined)}
      />
    </>
  );
};
