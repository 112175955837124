import { z } from "zod";

export const fleetFilterFormSchema = z.object({
  isFleetLeader: z.boolean(),
  state: z.array(z.string()),
  tier: z.array(z.string()),
  accountStatus: z.array(z.string()),
  driverRating: z.array(z.string()),
  vehicleType: z.array(z.string()),
  vehicleBrand: z.array(z.string()),
  vehicleYearFrom: z.string(),
  vehicleYearTo: z.string(),
  vehicleColor: z.array(z.string()),
});

export const offloadFilterFormSchema = z.object({
  state: z.array(z.string()),
  driverRating: z.array(z.string()),
  vehicleBrand: z.array(z.string()),
  vehicleYearFrom: z.string(),
  vehicleYearTo: z.string(),
  vehicleColor: z.array(z.string()),
});

export const defautFleetFilters = {
  isFleetLeader: false,
  state: [],
  tier: [],
  accountStatus: [],
  driverRating: [],
  vehicleType: [],
  vehicleBrand: [],
  vehicleYearFrom: "",
  vehicleYearTo: "",
  vehicleColor: [],
};

export const defaultOffloadFilters = {
  state: [],
  driverRating: [],
  vehicleBrand: [],
  vehicleYearFrom: "",
  vehicleYearTo: "",
  vehicleColor: [],
};
