import { format, parseISO } from "date-fns";
import { Link } from "react-router-dom";
import { DropdownMenu, Icon, Tooltip, Typography } from "@/components/atoms";
import { DataTableColumnDef } from "@/components/molecules";
import { Client } from "@/redux/slices/client/types";
import { ClientsTableData } from "./types";

export const generateClientColumns = () => {
  const columns: DataTableColumnDef<ClientsTableData>[] = [
    { accessorKey: "name", header: "Name", cell: (props) => props.row.original.name, id: "name", size: 250 },
    {
      accessorKey: "emailMobile",
      header: "Email / Mobile",
      cell: (props) => props.row.original.emailMobile,
      id: "email",
    },
    {
      accessorKey: "company",
      header: "Company",
      cell: (props) => props.row.original.company,
      size: 200,
      enableSorting: false,
    },
    {
      accessorKey: "accountType",
      header: "Account Type",
      cell: (props) => props.row.original.accountType,
      enableSorting: false,
      size: 100,
    },
    {
      accessorKey: "hearAboutUs",
      header: "Hear about us",
      cell: (props) => props.row.original.hearAboutUs,
      enableSorting: false,
      size: 300,
    },
    { accessorKey: "joined", header: "Joined", cell: (props) => props.row.original.joined, enableSorting: false, size: 100 },
    { accessorKey: "status", header: "Status", cell: (props) => props.row.original.status, enableSorting: false, size: 100 },
    {
      accessorKey: "action",
      header: "Action",
      cell: (props) => props.row.original.action,
      size: 75,
      enableSorting: false,
    },
  ];

  return columns;
};

export const generateClientData = (
  items: Client[],
  dateFormat: string,
  setPendingRemoveAccess: (client: Client) => void,
  onReactivateClick: (client: Client) => void
) => {
  const data = items.map(
    (i) =>
      ({
        name:
          i.status === "active" ? (
            <Tooltip content="Go to Manage Account" placement="bottom">
              <Link to={getManageAccountPath(i.id)} className="hover:text-info" target="_blank">
                <Typography variant="paragraph">{i.name}</Typography>
              </Link>
            </Tooltip>
          ) : (
            <Typography variant="paragraph">{i.name}</Typography>
          ),
        emailMobile: (
          <div className="inline-block">
            <Typography variant="paragraph">{i.email}</Typography>
            <Typography variant="small" className="text-neutral-dark-gray">
              {i.phone}
            </Typography>
          </div>
        ),
        company:
          i.companyName &&
          (i.status === "active" ? (
            <Tooltip content="View Company" placement="bottom">
              <Link to={`/companies/${i.companyId}`} className="hover:text-info" target="_blank">
                <Typography variant="paragraph">{i.companyName}</Typography>
              </Link>
            </Tooltip>
          ) : (
            <Typography variant="paragraph">{i.companyName}</Typography>
          )),
        accountType: i.accountType,
        hearAboutUs: i.hearAboutUs,
        joined: format(parseISO(i.joined), dateFormat),
        status: renderClientStatus(i.status),
        action: renderClientActions(i, setPendingRemoveAccess, onReactivateClick),
      } as ClientsTableData)
  );

  return data;
};

const renderClientStatus = (status: string) => {
  switch (status) {
    case "active":
      return (
        <Typography variant="paragraph" className="flex flex-row items-center gap-2">
          <span className="h-2 w-2 flex-shrink-0 rounded-full bg-success" /> Active
        </Typography>
      );
    case "removed":
      return (
        <Typography variant="paragraph" className="flex flex-row items-center gap-2">
          <span className="h-2 w-2 flex-shrink-0 rounded-full bg-danger" /> Inactive
        </Typography>
      );
    default:
      return <></>;
  }
};

const renderClientActions = (
  client: Client,
  setPendingRemoveAccess: (client: Client) => void,
  onReactivateClick: (client: Client) => void
) => {
  return (
    <>
      <DropdownMenu button={<Icon name="options" isCustom size="lg" />} position="bottom-start" className="!flex max-w-[24px]">
        {client.status === "active" ? (
          <>
            <Link to={getManageAccountPath(client.id)} target="_blank">
              <DropdownMenu.Item>
                <Icon name="User" className="mr-2.5 text-primary-dark" size="sm" />
                <Typography className="text-primary-dark">Manage Account</Typography>
              </DropdownMenu.Item>
            </Link>
            <DropdownMenu.Item onClick={() => setPendingRemoveAccess(client)}>
              <Icon name="Trash" className="mr-2.5 text-danger" size="sm" />
              <Typography className="text-primary-dark">Remove Access</Typography>
            </DropdownMenu.Item>
          </>
        ) : (
          <DropdownMenu.Item onClick={() => onReactivateClick(client)}>
            <Icon name="Refresh2" className="mr-2.5 text-primary-dark" size="sm" />
            <Typography className="text-primary-dark">Reactivate Account</Typography>
          </DropdownMenu.Item>
        )}
      </DropdownMenu>
    </>
  );
};

export const getManageAccountPath = (id: string) => `/passenger/${id}/my-bookings`;
