import { useAppAbility } from "@/casl";
import { Avatar, Button, DropdownMenu, Icon, IconButton, Tooltip, Typography } from "@/components/atoms";
import { Tabs } from "@/components/molecules";
import { useLayout, useOperator, usePage, useProfile } from "@/hooks";
import { useAppDispatch, useAppResetApi, useAppSelector } from "@/redux/hooks";
import { logOut } from "@/redux/slices/auth/authSlice";
import { activePassengerSelector, passengersSelector } from "@/redux/slices/passenger/selectors";
import { getAssetUrl } from "@/utils";
import { useNavigate } from "react-router-dom";
import { GeneralLogo } from "../General/GeneralLogo";
import { renderPassengerDisplayName } from "./helpers";
import { PassengerModalsSteps } from "./types";

interface PassengerHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  setStep: (step: PassengerModalsSteps) => void;
}

export const PassengerHeader = ({ setStep, className, ...props }: PassengerHeaderProps) => {
  const { setFullScreen } = useLayout();
  const resetApi = useAppResetApi();
  const dispatch = useAppDispatch();
  const ability = useAppAbility();
  const { roles } = useOperator();
  const activeProfile = useProfile();
  const isPassenger = roles.length === 1 && roles.includes("Passenger");

  const navigate = useNavigate();
  const { currentTitle, pathname } = usePage();
  const prevPage = pathname.substring(0, pathname.lastIndexOf('/'));
  
  const activePassenger = useAppSelector(activePassengerSelector);
  const passengers = useAppSelector(passengersSelector);

  const handleLogout = () => {
    dispatch(logOut());
    resetApi();
  };

  const navigateAdmin = () => {
    navigate("/bookings");
    setFullScreen(false);
  };

  const renderAccountDropdownButton = () => (
    <DropdownMenu
      button={(
        <DropdownMenu.Button 
          as={Button} 
          size="sm" 
          variant="tertiary" 
          startIcon={(
            <Avatar 
              size="xs" 
              src={getAssetUrl(activePassenger.avatar || "")} 
              alt={`${activePassenger.firstName} ${activePassenger.lastName}`}
            />
          )} endIcon="ArrowDown2" iconVariant="Bold">
            {renderPassengerDisplayName(activePassenger, "Account")}
        </DropdownMenu.Button>
      )}
      isCustomButton
      position="bottom-start"
      className="py-3"
    >
      <DropdownMenu.Item onClick={() => navigate(`${prevPage}/my-profile`)} className="w-[188px]">
        <Typography variant="paragraph">{renderPassengerDisplayName(activePassenger, "Profile")}</Typography>
      </DropdownMenu.Item>
      <DropdownMenu.Item onClick={() => navigate(`${prevPage}/company`)} className="w-[188px]">
        <Typography variant="paragraph">Company</Typography>
      </DropdownMenu.Item>
      <DropdownMenu.Item onClick={() => navigate(`${prevPage}/settings`)} className="w-[188px]">
        <Typography variant="paragraph">Settings</Typography>
      </DropdownMenu.Item>
      <DropdownMenu.Item onClick={() => navigate(`${prevPage}/account-access`)} className="w-[188px]">
        <Typography variant="paragraph">Account Access</Typography>
      </DropdownMenu.Item>
      <DropdownMenu.Item onClick={() => navigate(`${prevPage}/notifications`)} className="w-[188px]">
        <Typography variant="paragraph">Notifications</Typography>
      </DropdownMenu.Item>
      <DropdownMenu.Item onClick={() => navigate(`${prevPage}/preferences`)} className="w-[188px]">
        <Typography variant="paragraph">Preferences</Typography>
      </DropdownMenu.Item>
      <DropdownMenu.Item onClick={() => navigate(`${prevPage}/receipts`)} className="w-[188px]">
        <Typography variant="paragraph">Receipts</Typography>
      </DropdownMenu.Item>
      <DropdownMenu.Item onClick={() => navigate(`${prevPage}/payment-information`)} className="w-[188px]">
        <Typography variant="paragraph">Payment Information</Typography>
      </DropdownMenu.Item>
      {activePassenger.isPrimary && (
        <DropdownMenu.Item onClick={handleLogout} className="w-[188px]">
          <Typography variant="paragraph">Logout</Typography>
        </DropdownMenu.Item>
      )}
    </DropdownMenu>
  );

  return (
    <div className={className} {...props}>
      {!activePassenger.isPrimary && (
        <div className="flex relative items-center justify-center top-0 py-2 px-2 border-neutral-mid-gray bg-primary-light">
          <div className="flex flex-row w-full max-w-[1040px]">
            <div className="flex flex-1 flex-row items-center gap-3">
              <Avatar size="xs" src={getAssetUrl(activeProfile.avatar || "")} alt={`${activeProfile.firstName} ${activeProfile.lastName}`}/>
              <Typography variant="action">{`${activeProfile.firstName} ${activeProfile.lastName[0]}. (You)`}</Typography>
            </div>
            <div className="flex flex-row items-center gap-4">
              <Typography variant="action" className="text-neutral-dark-gray">{`You're currently managing ${activePassenger.firstName} ${activePassenger.lastName}'s account`}</Typography>
              <Button size="sm" variant="secondary" onClick={() => isPassenger ? navigate("/my-bookings") : navigateAdmin()}>Stop Managing</Button>
            </div>
          </div>
        </div>
      )}
      <div className="flex relative items-center justify-center top-0 px-2 bg-white shadow-lg z-20">
        <div className="flex flex-row w-full max-w-[1040px]">
          <div className="flex-1 py-3">
            <GeneralLogo />
          </div>
          <div className="flex flex-row items-center">
            <div className="flex flex-row items-center gap-x-2.5 h-full">
              <Tabs.Tab to={`${prevPage}/my-bookings`} name="My Bookings" onClick={() => null} variant="standard" current={currentTitle === "My Bookings"} className="h-full" />
              <Tabs.Tab to={`${prevPage}/favourites`} name="Favourites" onClick={() => null} variant="standard" current={currentTitle === "Favourites"} className="h-full" />
            </div>
            {renderAccountDropdownButton()}
            <div className="flex flex-row items-center gap-x-2.5 ml-4 py-3">
              {passengers.length > 1 && <Button size="md" variant="secondary" onClick={() => setStep("accounts")}>Accounts</Button>}
              <Button size="md" variant="primary" onClick={() => navigate(`${prevPage}/add-booking`)}>Add Booking</Button>
              {ability.can("manage", "providerClient") && <IconButton size="md" variant="secondary" iconName="Setting2" className="h-[32px] sm:h-[37px]" onClick={() => navigate(`${prevPage}/admin`)} />}
            </div>
          </div>
        </div>
      </div>
      <div className="flex relative items-center justify-center top-0 py-1 px-2 border-b-[1px] border-neutral-mid-gray bg-white z-10">
        <div className="flex flex-row items-center justify-end w-full max-w-[1040px] gap-1">
          <Button size="xs" variant="tertiary" startIcon="Add" onClick={() => setStep("invite-account-manager")}>Invite Account Manager</Button>
          <Tooltip content="This feature lets your registered executive assistant manage your account with full access. You can revoke access anytime."  placement="bottom" maxWidth={394}>
            <Icon name="InfoCircle" variant="Bold" size="md" className="rotate-180 text-neutral-dark-gray" />
          </Tooltip>
        </div>
      </div>
    </div>
  );
};