import { Option, Page } from "@/redux/types";

export type CompanyState = {
  companies: Company[];
  associatedClients: AssociatedClient[];
  companiesPage: Page;
  associatedClientsPage: Page;
  companiesPageState: CompaniesPageState;
  associatedClientsPageState: AssociatedClientsPageState;
  options: CompanyOptions;
};

export type Company = {
  id: string;
  name: string;
  address: string;
  phone: string;
  businessRegistrationType: string;
  businessRegistrationId: string;
  pricing: {
    includeClientExtrasInBaseCost: boolean;
    allowDriverExtras: boolean;
    profileId: string | null;
    commission: {
      overrideEnabled: boolean;
      percentage: number | null;
    };
    adjustments: Array<{
      amount: number;
      type: string;
      description: string;
    }>;
  };
  invoice: {
    enabled: boolean;
    generateOnDate: number | null;
    generateOnFrequency: string | null;
    generateOnLevel: string | null;
    paymentTerms: number | null;
  };
  notes: {
    admin: string;
    driverNotes: string;
    reference: string;
  };
  keyContact: {
    name: string;
    email: string;
    phone: string;
  };
  billingContacts: Array<{
    name: string;
    email: string;
    phone: string;
  }>;
  createdAt: string;
  metadata: {
    clientCount: number;
  };
};

export type AssociatedClient = {
  id: string;
  name: string;
  phone: string;
  email: string;
};

export type CompaniesPageState = {
  search: string;
  selectedCompany?: Company;
  action?: CompanyAction;
};

export type CompanyAction = "removeCompany";

export type AssociatedClientsPageState = {
  unableToAddClients: UnableToAddClient[];
  action?: AssociatedClientAction;
};

export type UnableToAddClient = Pick<AssociatedClient, "id" | "name">;
export type AssociatedClientAction = "addClients" | "unableToAddClient";

export type CompanyOptions = {
  businessRegistrationType: Option;
  invoiceGenerateLevel: Option;
  invoiceGenerateFrequency: Option;
  pricingProfile: Option;
};

export const companyOptionsInitialState = {
  businessRegistrationType: {},
  invoiceGenerateLevel: {},
  invoiceGenerateFrequency: {},
  pricingProfile: {},
};
