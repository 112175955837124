import { DataTableColumnDef } from "@/components/molecules";
import { TransferTypeTableData } from "./types";

export const generateColumns = (data: TransferTypeTableData[]) => {
  const columns: DataTableColumnDef<TransferTypeTableData>[] = [
    {
      accessorKey: "name",
      header: `Name (${data.length})`,
      size: 300,
      cell: (props) => props.row.original.name,
      enableSorting: false,
    },
    { accessorKey: "description", header: "Description", enableSorting: false },
    { accessorKey: "costIncrease", header: "Cost Increase", enableSorting: false },
    { accessorKey: "active", header: "Active", cell: (props) => props.row.original.active, enableSorting: false },
    { header: " ", cell: (props) => props.row.original.actions, size: 100, enableSorting: false },
  ];

  return columns;
};

export const generateEditorColumns = (data: TransferTypeTableData[]) => {
  const columns: DataTableColumnDef<TransferTypeTableData>[] = [
    {
      accessorKey: "reorder",
      header: "",
      cell: (props) => props.row.original.reorder,
      enableSorting: false,
    },
    {
      accessorKey: "name",
      header: `Name (${data.length})`,
      size: 300,
      cell: (props) => props.row.original.name,
      enableSorting: false,
    },
    { accessorKey: "description", header: "Description", enableSorting: false },
    { accessorKey: "costIncrease", header: "Cost Increase", enableSorting: false },
    { accessorKey: "active", header: "Active", cell: (props) => props.row.original.active, enableSorting: false },
    { header: " ", cell: (props) => props.row.original.actions, size: 100, enableSorting: false },
  ];

  return columns;
};
