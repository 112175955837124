import { Modal } from "@/components/atoms";
import { NetworkItem } from "@/redux/slices/network/types";
import { DisconnectRelationship } from "./contents/DisconnectRelationship";
import { PrivateNetworkHelp } from "./contents/PrivateNetworkHelp";
import { InviteNetworkCodeForm } from "./forms/InviteNetworkCodeForm";
import { InviteNetworkEmailForm } from "./forms/InviteNetworkEmailForm";
import { PrivateNetworkStep } from "./types";

interface PrivateNetworkModalsProps {
  pendingDisconnectRelationship?: NetworkItem;
  setPendingDisconnectRelationship: (network?: NetworkItem) => void;
  step?: PrivateNetworkStep;
  setStep: (step?: PrivateNetworkStep) => void;
}

export const PrivateNetworkModals = ({
  pendingDisconnectRelationship,
  setPendingDisconnectRelationship,
  step,
  setStep,
}: PrivateNetworkModalsProps) => {
  return (
    <>
      <Modal open={!!pendingDisconnectRelationship} onClose={() => setPendingDisconnectRelationship(undefined)} className="max-w-[514px]">
        <DisconnectRelationship
          pendingDisconnectRelationship={pendingDisconnectRelationship}
          setPendingDisconnectRelationship={setPendingDisconnectRelationship}
        />
      </Modal>
      <Modal open={step === "inviteCode"} onClose={() => setStep(undefined)} className="max-w-[550px]">
        <InviteNetworkCodeForm setStep={setStep} />
      </Modal>
      <Modal open={step === "inviteEmail"} onClose={() => setStep(undefined)} className="max-w-[550px]">
        <InviteNetworkEmailForm setStep={setStep} />
      </Modal>
      <Modal open={step === "help"} onClose={() => setStep(undefined)} className="max-w-[550px]">
        <PrivateNetworkHelp setStep={setStep} />
      </Modal>
    </>
  );
};
