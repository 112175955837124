import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { passengerApi } from "@/redux/apis";
import { Passenger, PassengerState } from "./types";

const initialState: PassengerState = {
  passengers: [],
  activePassenger: {} as Passenger,
};

export const passengerSlice = createSlice({
  name: "passenger",
  initialState,
  reducers: {
    setActivePassenger: (state, action: PayloadAction<Passenger>) => {
      state.activePassenger = action.payload;
    },
    addPassenger: (state, action: PayloadAction<Passenger>) => {
      state.passengers = [action.payload, ...state.passengers];
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(passengerApi.endpoints.getPassengers.matchFulfilled, (state, { payload }) => {
      state.passengers = payload.data.passengers;
    });
  },
});

export default passengerSlice.reducer;
export const { setActivePassenger, addPassenger } = passengerSlice.actions;
