import { useEffect } from "react";
import { useForm, useFormContext } from "react-hook-form";
import { z } from "zod";
import { BasicTable, Button, CheckboxInput, Icon, IconButton, Modal, Spinner, Tooltip, Typography } from "@/components/atoms";
import { useGetCategoryVerificationQuery } from "@/redux/apis/vehicle/vehicleApi";
import { getAssetUrl } from "@/utils";
import { useEditVehicleContext } from "../context";
import { EditVehicleFormData } from "../form";

interface VehicleCategoryModalProps {
  open: boolean;
  onClose: () => void;
  defaultCategories?: string[];
}

const schema = z.object({
  categories: z.array(z.string()),
});

type FormData = z.infer<typeof schema>;

export const VehicleCategoryModal = ({ open, onClose, defaultCategories }: VehicleCategoryModalProps) => {
  const {
    vehicle: { uuid, model, registrationNumber },
  } = useEditVehicleContext();

  const { setValue: setMainFormValue } = useFormContext<EditVehicleFormData>();

  const { watch, reset, setValue } = useForm<FormData>({
    defaultValues: {
      categories: defaultCategories,
    },
  });

  const { data, isLoading } = useGetCategoryVerificationQuery(uuid);
  const selected = watch("categories");

  useEffect(() => {
    reset({ categories: defaultCategories });
  }, [defaultCategories, reset]);

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    if (target.checked) setValue("categories", [...(selected || []), target.value]);
    else
      setValue(
        "categories",
        (selected || []).filter((value) => value !== target.value)
      );
  };

  const onApply = () => {
    setMainFormValue("details.categories", selected, { shouldDirty: true });
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose} className="w-[750px] !px-5">
      <header className="flex">
        <Typography variant="title" className="flex-1">
          Select Vehicle Category
        </Typography>
        <IconButton iconName="close" iconSize="lg" isCustomIcon variant="custom" onClick={onClose} />
      </header>
      <div className="mt-2 flex gap-2 ">
        <img
          src={getAssetUrl(model.avatar, {
            width: 48,
            height: 48,
            fit: "contain",
            background: "transparent",
          })}
          className="h-6 w-6 rounded bg-neutral-gray"
        />
        <Typography variant="action">
          {model.make} {model.model} ({registrationNumber})
        </Typography>
      </div>
      <section className="mt-2">
        {data && !isLoading ? (
          <div className=" max-h-[50vh] overflow-y-auto p-1">
            <BasicTable className="[&>table]:divide -opacity-100 ring-opacity-100">
              <BasicTable.Row className="[&>th]:bg-neutral-gray">
                <BasicTable.HeaderCell className="w-[220px] border-r border-neutral-mid-gray">Vehicle Categories</BasicTable.HeaderCell>
                {/* <BasicTable.HeaderCell>Type</BasicTable.HeaderCell> */}
                {/* <BasicTable.HeaderCell>Class</BasicTable.HeaderCell> */}
                {/* <BasicTable.HeaderCell>Color</BasicTable.HeaderCell> */}
                <BasicTable.HeaderCell>Capacity</BasicTable.HeaderCell>
                <BasicTable.HeaderCell>Bags</BasicTable.HeaderCell>
                {/* <BasicTable.HeaderCell>Engine</BasicTable.HeaderCell> */}
                {/* <BasicTable.HeaderCell>Make</BasicTable.HeaderCell> */}
                {/* <BasicTable.HeaderCell>Model</BasicTable.HeaderCell> */}
              </BasicTable.Row>
              <>
                {data.map((category) => (
                  <BasicTable.Row key={category.uuid}>
                    <BasicTable.Cell className=" border-r border-neutral-mid-gray">
                      <div className="flex max-h-[24px] items-center gap-1.5 ">
                        <CheckboxInput
                          value={category.uuid}
                          label={category.name}
                          variant="check"
                          onChange={handleChange}
                          checked={selected?.includes(category.uuid)}
                        />
                        {(!category.validity.capacityBags || !category.validity.capacityPax) && (
                          <Tooltip content="Vehicle Category does not match current settings">
                            <Icon name="Danger" variant="Bold" size="sm" className="text-warning" />
                          </Tooltip>
                        )}
                      </div>
                    </BasicTable.Cell>
                    <BasicTable.Cell>
                      {category.validity.capacityBags ? (
                        <Icon className="mr-2 self-center text-success" name="TickCircle" variant="Bold" size="md" />
                      ) : null}
                    </BasicTable.Cell>
                    <BasicTable.Cell>
                      {category.validity.capacityPax ? (
                        <Icon className="mr-2 self-center text-success" name="TickCircle" variant="Bold" size="md" />
                      ) : null}
                    </BasicTable.Cell>
                  </BasicTable.Row>
                ))}
              </>
            </BasicTable>
          </div>
        ) : (
          <Spinner />
        )}
        <Button variant="primary" onClick={onApply} size="lg" className="mt-5 w-full">
          Apply Vehicle Category
        </Button>
      </section>
    </Modal>
  );
};
