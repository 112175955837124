import { equals } from "ramda";
import { FormProvider, useForm } from "react-hook-form";
import { Button, Divider, Icon, IconButton, Loading, Typography, SidePanel } from "@/components/atoms";
import { usePagination } from "@/hooks/usePagination";
import { useGetFleetOptionsQuery } from "@/redux/apis/driver/driverApi";
import { useAppSelector } from "@/redux/hooks";
import { fleetOptionsSelector } from "@/redux/slices/driver/selectors";
import { Filter } from "@/redux/types";
import { AccountStatusFilter } from "./filters/AccountStatusFilter";
import { DriverRatingFilter } from "./filters/DriverRatingFilter";
import { IsFleetLeaderFilter } from "./filters/IsFleetLeaderFilter";
import { StateFilter } from "./filters/StateFilter";
import { TierFilter } from "./filters/TierFilter";
import { VehicleColorFilter } from "./filters/VehicleColorFilter";
import { VehicleTypeBrandFilter } from "./filters/VehicleTypeBrandFilter";
import { VehicleYearFilter } from "./filters/VehicleYearFilter";
import { defautFleetFilters } from "./fixtures";
import { FleetFilterFormData } from "./types";

interface FleetFilterPanelProps {
  open: boolean;
  onClose: () => void;
  filter: Filter;
  setFilter: (filter: Filter) => void;
  onClickFleetLeaderInfo: () => void;
}

export const FleetFilterPanel = (fleetFilterPanelProps: FleetFilterPanelProps) => {
  const { open, onClose, filter, setFilter, onClickFleetLeaderInfo } = fleetFilterPanelProps;
  const { isFetching } = useGetFleetOptionsQuery();
  const options = useAppSelector(fleetOptionsSelector);
  const { clearPagination } = usePagination();
  const methods = useForm<FleetFilterFormData>({ defaultValues: defautFleetFilters });
  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = methods;

  const handleOnClose = (data?: FleetFilterFormData) => {
    if (isDirty && data) reset(data);
    onClose();
  };

  const onSubmit = handleSubmit((data) => {
    const filterData: Filter = {};

    data.isFleetLeader ? (filterData["is_fleet_leader"] = "1") : delete filterData["is_fleet_leader"];
    data.state.map((val, i) => (filterData[`state[${i.toString()}]`] = val));
    data.tier.map((val, i) => (filterData[`tier[${i.toString()}]`] = val));
    data.accountStatus.map((val, i) => (filterData[`account_status[${i.toString()}]`] = val));
    data.driverRating.map((val, i) => (filterData[`rating[${i.toString()}]`] = val));
    data.vehicleType.map((val, i) => (filterData[`vehicle_type[${i.toString()}]`] = val));
    data.vehicleBrand.map((val, i) => (filterData[`vehicle_brand[${i.toString()}]`] = val));
    if (data.vehicleYearFrom) filterData["vehicle_year_from"] = data.vehicleYearFrom;
    if (data.vehicleYearTo) filterData["vehicle_year_to"] = data.vehicleYearTo;
    data.vehicleColor.map((val, i) => (filterData[`vehicle_color[${i.toString()}]`] = val));

    if (!equals(filterData, filter)) {
      clearPagination();
      setFilter(filterData);
    }
    handleOnClose(data);
  });

  return (
    <SidePanel open={open} onClose={onClose} focused>
      <div className="flex items-center justify-between p-5">
        <div className="flex flex-row items-center gap-2">
          <Icon name="Sort" size="lg" />
          <Typography variant="title">Filter</Typography>
        </div>
        <div className="ml-3 flex h-7 items-center">
          <IconButton iconName="ArrowRight" onClick={() => handleOnClose()} variant="tertiary" className="-mr-4 hover:bg-transparent" />
        </div>
      </div>
      <FormProvider {...methods}>
        <form className="relative flex h-full flex-col" onSubmit={onSubmit}>
          {isFetching && <Loading />}
          <div className="h-full flex-1 overflow-y-auto">
            <div className="flex flex-col gap-y-4 px-4 py-6">
              <IsFleetLeaderFilter onClickInfo={onClickFleetLeaderInfo} />
              <StateFilter />
              <TierFilter options={options.tiers} />
              <AccountStatusFilter options={options.accountStatuses} />
              <VehicleTypeBrandFilter />
              <DriverRatingFilter />
              <VehicleYearFilter from={options.vehicleYears.from} to={options.vehicleYears.to} />
              <VehicleColorFilter options={options.vehicleColors} />
            </div>
          </div>
          <div>
            <Divider />
            <div className="flex flex-col gap-3 px-4 py-5">
              <Button type="submit" className="w-full text-center" size="lg">
                Filter Results
              </Button>
              <Button className="w-full text-center" size="lg" variant="secondary" onClick={() => reset(defautFleetFilters)}>
                Reset Filters
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </SidePanel>
  );
};
