import { Icon, Typography, Modal, Button } from "@/components/atoms";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { MakeAndModelField, VehicleColorField, VehicleYearField } from "../forms/fields";
import { addYourVehicleFormSchema } from "../forms/schemas";
import { AddYourVehicleFormData, SignUpDriverFormData } from "../forms/types";

interface AddYourVehicleModalProps {
  open: boolean;
  onClose: () => void;
}

export const AddYourVehicleModal = ({ open, onClose }: AddYourVehicleModalProps) => {
  const {
    setValue,
  } = useFormContext<SignUpDriverFormData>();
  const methods = useForm<AddYourVehicleFormData>({ resolver: zodResolver(addYourVehicleFormSchema) });
  const {
    handleSubmit,
    formState: { isDirty },
  } = methods;

  const onSubmit = (data: AddYourVehicleFormData) => {
    if (!data) return;

    setValue("vehicle", {
      makeAndModel: data.vehicleMakeAndModel,
      year: data.vehicleYear,
      color: data.vehicleColor,
    }, { shouldDirty: true });
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose} className="w-full max-w-[550px]">
      <Header onClose={onClose} />
      <FormProvider {...methods}>
        <form id="add-your-vehicle-form" onSubmit={handleSubmit(onSubmit)}>
          <MakeAndModelField className="mt-6" />
          <VehicleYearField className="mt-6" />
          <VehicleColorField className="mt-6" />
          <Button form="add-your-vehicle-form" variant="primary" size="lg" type="submit" disabled={!isDirty} className="w-full mt-6">Add Vehicle</Button>
        </form>
      </FormProvider>
    </Modal>
  );
};

const Header = ({ onClose }: { onClose: () => void; }) => (
  <div className="sm:flex sm:items-start">
    <div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
      <div className="flex flex-row items-center">
        <Typography variant="title" className="flex-1">
          Add your vehicle
        </Typography>
        <Icon name="close" isCustom className="flex cursor-pointer justify-end" onClick={onClose} />
      </div>
    </div>
  </div>
);