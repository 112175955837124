import { Button, ErrorMessage, Icon, Loading, Modal, TextInput, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useInviteAccountManagerMutation } from "@/redux/apis/passenger/passengerApi";
import { useAppSelector } from "@/redux/hooks";
import { activePassengerSelector } from "@/redux/slices/passenger/selectors";
import { addToast } from "@/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { inviteAccountManagerFormSchema } from "../fixtures";
import { InviteAccountManagerFormData } from "../types";

interface InviteAccountManagerModalProps {
  open: boolean;
  onClose: () => void;
}

export const InviteAccountManagerModal = ({ open, onClose }: InviteAccountManagerModalProps) => {
  const activePassenger = useAppSelector(activePassengerSelector);
  const [inviteAccountManager, { isLoading }] = useInviteAccountManagerMutation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty, errors },
  } = useForm<InviteAccountManagerFormData>({
    resolver: zodResolver(inviteAccountManagerFormSchema),
  });

  const onSubmit = handleSubmit((data) => {
    const { email } = data;

    inviteAccountManager({ id: activePassenger.id, email })
      .unwrap()
      .then(() => {
        addToast("success", "Successfuly invited account manager");
        reset();
        handleClose();
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  });

  const handleClose = () => {
    if (!isLoading) onClose();
  };

  const renderHeader = () => (
    <div className="flex flex-row items-center">
      <Typography variant="title" className="flex-1">
        Invite Account Manager
      </Typography>
      <Icon name="close" isCustom className="flex cursor-pointer justify-end" onClick={handleClose} />
    </div>
  );

  const renderDescription = () => activePassenger.isPrimary ? "You're inviting someone to manage your account" : `You're inviting someone to manage ${activePassenger.firstName}'s account`;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className="w-[85vw] md:w-[550px] lg:w-[550px]"
    >
      {isLoading && <Loading />}
      {renderHeader()}
      {activePassenger && <Typography variant="paragraph" className="text-neutral-dark-gray mt-3">{renderDescription()}</Typography>}
      <form className="flex flex-col w-full mt-6" onSubmit={onSubmit}>
        <div className="flex flex-col">
          <Typography variant="paragraph">Email Address</Typography>
          <TextInput
            className="w-full mt-1"
            placeholder="Enter Email Address"
            autoComplete="email"
            type="email"
            maxLength={100}
            hasError={!!errors.email}
            {...register("email")}
          />
          <ErrorMessage errors={errors} name="email" />
        </div>
        <Button type="submit" size="lg" variant="primary" className="w-full mt-8" disabled={!isDirty}>Invite</Button>
      </form>
    </Modal>
  );
};
