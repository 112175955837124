import { Company } from "@/redux/slices/company/types";
import { InvoicesCompany, UninvoicedJobsCompany } from "@/redux/slices/payment/types";
import { pageState } from "@/redux/types";
import {
  GetCompaniesRawResponse,
  GetCompaniesResponse,
  GetAssociatedClientsRawResponse,
  GetAssociatedClientsResponse,
  GetCompanyOptionsRawResponse,
  GetCompanyOptionsResponse,
  RawCompany,
  GetUninvoicedJobsCompaniesRawResponse,
  GetUninvoicedJobsCompaniesResponse,
  GetInvoicesCompaniesRawResponse,
  GetInvoicesCompaniesResponse,
  GetCompanyPaymentMethodSecretRawResponse,
  GetCompanyPaymentMethodSecretResponse,
  GetCompanyPaymentMethodDetailsResponse,
  GetCompanyPaymentMethodDetailsRawResponse,
} from "./types";

export const transformCompanies = (res: GetCompaniesRawResponse) => {
  const {
    _embedded: { company: companies },
    page,
    page_count,
    page_size,
    total_items,
  } = res;

  const response: GetCompaniesResponse = {
    data: {
      companies: companies.map(
        (i) =>
          ({
            id: i.uuid,
            name: i.name,
            address: i.address,
            phone: i.phone,
            businessRegistrationType: i.business_registration_type,
            businessRegistrationId: i.business_registration_id,
            pricing: {
              includeClientExtrasInBaseCost: i.pricing.include_client_extras_in_base_cost,
              allowDriverExtras: i.pricing.allow_driver_extras,
              profileId: i.pricing.profile_uuid,
              commission: {
                overrideEnabled: i.pricing.commission.override_enabled,
                percentage: i.pricing.commission.percentage,
              },
              adjustments: i.pricing.adjustments,
            },
            invoice: {
              enabled: i.invoice.enabled,
              generateOnDate: i.invoice.generate_on_date,
              generateOnFrequency: i.invoice.generate_on_frequency,
              generateOnLevel: i.invoice.generate_on_level,
              paymentTerms: i.invoice.payment_terms,
            },
            notes: {
              admin: i.notes.admin,
              driverNotes: i.notes.driver_notes,
              reference: i.notes.reference,
            },
            keyContact: i.key_contact,
            billingContacts: i.billing_contacts,
            createdAt: i.created_at,
            metadata: {
              clientCount: i.metadata.member_count,
            },
          } as Company)
      ),
      companiesPage: {
        current: page,
        count: page_count,
        size: page_size,
        total: total_items,
      },
    },
  };

  return response;
};

export const transformAssociatedClients = (res: GetAssociatedClientsRawResponse) => {
  const {
    _embedded: { accounts: associatedClients },
    page,
    page_count,
    page_size,
    total_items,
  } = res;

  const response: GetAssociatedClientsResponse = {
    data: {
      associatedClients: associatedClients.map((i) => ({
        id: i.uuid,
        name: i.name,
        phone: i.phone,
        email: i.email,
      })),
      associatedClientsPage: {
        current: page,
        count: page_count,
        size: page_size,
        total: total_items,
      },
    },
  };

  return response;
};

export const transformCompany = (res: RawCompany) => {
  const response: Company = {
    id: res.uuid,
    name: res.name,
    address: res.address,
    phone: res.phone,
    businessRegistrationType: res.business_registration_type,
    businessRegistrationId: res.business_registration_id,
    pricing: {
      includeClientExtrasInBaseCost: res.pricing.include_client_extras_in_base_cost,
      allowDriverExtras: res.pricing.allow_driver_extras,
      profileId: res.pricing.profile_uuid,
      commission: {
        overrideEnabled: res.pricing.commission.override_enabled,
        percentage: res.pricing.commission.percentage,
      },
      adjustments: res.pricing.adjustments,
    },
    invoice: {
      enabled: res.invoice.enabled,
      generateOnDate: res.invoice.generate_on_date,
      generateOnFrequency: res.invoice.generate_on_frequency,
      generateOnLevel: res.invoice.generate_on_level,
      paymentTerms: res.invoice.payment_terms,
    },
    notes: {
      admin: res.notes.admin,
      driverNotes: res.notes.driver_notes,
      reference: res.notes.reference,
    },
    keyContact: res.key_contact,
    billingContacts: res.billing_contacts,
    createdAt: res.created_at,
    metadata: {
      clientCount: res.metadata.member_count,
    },
  };

  return response;
};

export const transformCompanyOptions = (res: GetCompanyOptionsRawResponse) => {
  const response: GetCompanyOptionsResponse = {
    data: {
      options: {
        businessRegistrationType: res.business_registration_type,
        invoiceGenerateLevel: res.invoice.generate_on_level,
        invoiceGenerateFrequency: res.invoice.generate_on_frequency,
        pricingProfile: res.pricing.profile_uuid,
      },
    },
  };

  return response;
};

export const transformUninvoicedJobsCompanies = (res: GetUninvoicedJobsCompaniesRawResponse) => {
  const {
    _embedded: { company: companies },
    page,
    page_count,
    page_size,
    total_items,
  } = res;

  const response: GetUninvoicedJobsCompaniesResponse = {
    data: {
      companies: companies.map(
        (i) =>
          ({
            id: i.uuid,
            name: i.name,
            invoice: {
              generateOnDate: i.invoice.generate_on_date,
              generateOnFrequency: i.invoice.generate_on_frequency,
              generateOnLevel: i.invoice.generate_on_level,
            },
            keyContact: i.key_contact,
            billingContacts: i.billing_contacts,
            clientJobs: [],
            clientJobsPage: pageState,
            jobs: [],
            jobsPage: pageState,
            metadata: {
              unpaidJobValue: i.metadata.unpaid_job_value,
              unpaidJobCount: i.metadata.unpaid_job_count,
            },
          } as UninvoicedJobsCompany)
      ),
      page: {
        current: page,
        count: page_count,
        size: page_size,
        total: total_items,
      },
    },
  };

  return response;
};

export const transformInvoicesCompanies = (res: GetInvoicesCompaniesRawResponse) => {
  const {
    _embedded: { company: companies },
    page,
    page_count,
    page_size,
    total_items,
  } = res;

  const response: GetInvoicesCompaniesResponse = {
    data: {
      companies: companies.map(
        (i) =>
          ({
            id: i.uuid,
            name: i.name,
            invoices: [],
            invoicesPage: pageState,
            metadata: {
              unpaidInvoiceCount: i.metadata.unpaid_invoice_count,
              overdueInvoiceCount: i.metadata.overdue_invoice_count,
              unpaidInvoiceValue: i.metadata.unpaid_invoice_value,
            },
          } as InvoicesCompany)
      ),
      companiesPage: {
        current: page,
        count: page_count,
        size: page_size,
        total: total_items,
      },
    },
  };

  return response;
};

export const transformCompanyPaymentMethodSecret = (res: GetCompanyPaymentMethodSecretRawResponse) => {
  const response: GetCompanyPaymentMethodSecretResponse = {
    data: {
      paymentMethodSecret: res.intent_secret,
    },
  };

  return response;
};

export const transformCompanyPaymentMethodDetails = (res: GetCompanyPaymentMethodDetailsRawResponse) => {
  const response: GetCompanyPaymentMethodDetailsResponse = {
    data: {
      id: res.uuid,
      type: res.type,
      method: res.method,
      paymentMethod: res.payment_method,
    },
  };

  return response;
};
