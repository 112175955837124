import { NavLink } from "react-router-dom";
import { Icon, Typography } from "@/components/atoms";
import { clsx } from "@/utils";

interface TabProps extends React.HTMLAttributes<HTMLAnchorElement> {
  name: string;
  to: string;
  current?: boolean;
  variant?: "standard" | "tertiary";
  isLocked?: boolean;
  icon?: React.ReactNode;
}

export const Tab = ({ name, variant = "standard", isLocked, className, icon, current, ...props }: TabProps) => {
  const getClassName = ({ isActive }: { isActive: boolean }) => {
    const active = isActive || current;
    const standardClass = {
      "border-b-2 px-4 py-2": variant === "standard",
      "border-primary text-primary": variant === "standard" && active,
      "border-transparent text-neutral-dark-gray hover:border-primary hover:text-primary": variant === "standard" && !active,
    };

    const tertiaryClass = {
      "rounded-md px-3 py-2": variant === "tertiary",
      "bg-primary-light text-primary": variant === "tertiary" && active,
      "text-primary-dark": variant === "tertiary" && !active,
    };

    return clsx(
      "group flex flex-row cursor-pointer items-center transition-colors",
      standardClass,
      tertiaryClass,
      { "pointer-events-none gap-x-2 !text-neutral-dark-gray !border-transparent": isLocked },
      className
    );
  };

  return (
    <NavLink key={name} className={getClassName} {...props} role="tab">
      {isLocked && <Icon name="Lock1" variant="Bold" size="sm" />}
      <Typography variant="action">{name}</Typography>
      {icon}
    </NavLink>
  );
};
