import { Outlet, useParams } from "react-router-dom";
import { useLayout, useOperator, useProfile } from "@/hooks";
import { useEffect, useState } from "react";
import { PassengerHeader, PassengerHeaderMobile, PassengerModals } from "@/components/organisms";
import { MyBookings } from "./MyBookings/MyBookings";
import { Favourites } from "./Favourites/Favourites";
import { AddBooking } from "./AddBooking/AddBooking";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { Restricted } from "../Restricted/Restricted";
import { Loading } from "@/components/atoms";
import { isEmpty } from "ramda";
import { activePassengerSelector, passengersSelector } from "@/redux/slices/passenger/selectors";
import { addPassenger, setActivePassenger } from "@/redux/slices/passenger/passengerSlice";
import { MyProfile } from "./MyProfile/MyProfile";
import { Company } from "./Company/Company";
import { Settings } from "./Settings/Settings";
import { AccountAccess } from "./AccountAccess/AccountAccess";
import { Notifications } from "./Notifications/Notifications";
import { Preferences } from "./Preferences/Preferences";
import { Receipts } from "./Receipts/Receipts";
import { PaymentInformation } from "./PaymentInformation/PaymentInformation";
import { PassengerModalsSteps } from "@/components/organisms/Passenger/types";
import { Admin } from "./Admin/Admin";
import { useLazyGetAccountProfileQuery } from "@/redux/apis/account/accountApi";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { addToast } from "@/utils";
import { useLazyGetPassengersQuery } from "@/redux/apis/passenger/passengerApi";

export const Passenger = () => {
  const { setFullScreen } = useLayout();
  const dispatch = useAppDispatch();
  const { passengerId } = useParams();

  const { roles } = useOperator();
  const activeProfile = useProfile();
  const isPassenger = roles.length === 1 && roles.includes("Passenger");

  const [getAccountProfile, { isFetching: isFetchingAccountProfile }] = useLazyGetAccountProfileQuery();
  const [getPassengers] = useLazyGetPassengersQuery();
  const activePassenger = useAppSelector(activePassengerSelector);
  const passengers = useAppSelector(passengersSelector);
  const isActivePassengerSet = !isEmpty(activePassenger);
  const isPassengersSet = !isEmpty(passengers);
  const isLoading = isFetchingAccountProfile || !isActivePassengerSet;

  const [isPageReady, setIsPageReady] = useState(false);
  const [step, setStep] = useState<PassengerModalsSteps>(undefined);
  const [isRestricted, setIsRestricted] = useState(false);

  const primaryPassenger = {
    id: activeProfile.id,
    firstName: activeProfile.firstName,
    lastName: activeProfile.lastName,
    email: activeProfile.email,
    avatar: activeProfile.avatar,
    isPrimary: true,
  };

  useEffect(() => {
    setFullScreen(true);
    setIsPageReady(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFullScreen]);

  useEffect(() => {
    if (isPageReady) {
      if (isPassenger) {
        if (passengerId) {
          getAccountProfile({ id: passengerId })
            .unwrap()
            .then((res) => {
              const { data: accountProfile } = res;
              const passenger = {
                id: accountProfile.accountId,
                firstName: accountProfile.firstName,
                lastName: accountProfile.lastName,
                email: accountProfile.email,
                avatar: accountProfile.avatar,
                isPrimary: accountProfile.accountId === primaryPassenger.id,
              };
              dispatch(setActivePassenger(passenger));
            })
            .catch(() => setIsRestricted(true));
        } else dispatch(setActivePassenger(primaryPassenger));
      } else {
        if (passengerId && passengerId !== activeProfile.id) {
          getAccountProfile({ id: passengerId })
            .unwrap()
            .then((res) => {
              const { data: accountProfile } = res;
              const passenger = {
                id: accountProfile.accountId,
                firstName: accountProfile.firstName,
                lastName: accountProfile.lastName,
                email: accountProfile.email,
                avatar: accountProfile.avatar,
                isPrimary: false,
              };
              dispatch(setActivePassenger(passenger));
            })
            .catch(() => setIsRestricted(true));
        } else setIsRestricted(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPageReady, passengerId])

  useEffect(() => {    
    if (isPageReady && isPassenger && !isPassengersSet) {
      getPassengers({ id: primaryPassenger.id })
        .unwrap()
        .then(() => dispatch(addPassenger(primaryPassenger)))
        .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPageReady]);

  if (isRestricted) return <Restricted />;

  return (
    <div className="relative flex flex-row min-h-screen overflow-hidden">
      {isLoading ? <Loading /> : (
        <div className="flex flex-1 flex-col bg-white">
          <header>
            <PassengerHeader setStep={setStep} className="hidden lg:block" />
            <PassengerHeaderMobile setStep={setStep} className="lg:hidden" />
          </header>
          <Outlet />
          <PassengerModals step={step} onClose={() => setStep(undefined)} />
        </div>
      )}
    </div>
  );
};

Passenger.MyBookings = MyBookings;
Passenger.Favourites = Favourites;
Passenger.AddBooking = AddBooking;
Passenger.MyProfile = MyProfile;
Passenger.Company = Company;
Passenger.Settings = Settings;
Passenger.AccountAccess = AccountAccess;
Passenger.Notifications = Notifications;
Passenger.Preferences = Preferences;
Passenger.Receipts = Receipts;
Passenger.PaymentInformation = PaymentInformation;
Passenger.Admin = Admin;