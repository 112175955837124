import { PageHeader } from "@/components/organisms";
import { PrivateNetworkPanel } from "@/components/organisms/";
import { PrivateNetworkFleetLeaders } from "./PrivateNetworkFleetLeaders";
import { PrivateNetworkHistory } from "./PrivateNetworkHistory";

export const PrivateNetwork = () => {
  return (
    <div className="mb-12 flex flex-1 flex-col">
      <PageHeader skipCrumbs={1} title="Private Network" />
      <PrivateNetworkPanel />
    </div>
  );
};

PrivateNetwork.History = PrivateNetworkHistory;
PrivateNetwork.FleetLeaders = PrivateNetworkFleetLeaders;
