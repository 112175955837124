import { Link } from "react-router-dom";
import { useAppAbility } from "@/casl";
import { DropdownMenu, Icon, Toggle, Typography } from "@/components/atoms";
import { NetworkItem } from "@/redux/slices/network/types";
import { clsx } from "@/utils";

interface TableActionsDropdownProps {
  item: NetworkItem;
  isLoadingToggle: boolean;
  setPendingDisconnectRelationship: (item: NetworkItem) => void;
  acceptInvitation: (id: string) => void;
  declineInvitation: (id: string) => void;
  toggleOffloadFeeExemption: (id: string, active: boolean) => void;
}

export const TableActionsDropdown = (props: TableActionsDropdownProps) => {
  const ability = useAppAbility();
  const { item, isLoadingToggle, setPendingDisconnectRelationship, acceptInvitation, declineInvitation, toggleOffloadFeeExemption } = props;

  return (
    <DropdownMenu button={<Icon name="options" isCustom size="lg" />} position="bottom-start" className="!flex max-w-[24px]">
      {item.status === "pending" ? (
        <>
          {item.ownsRelationship ? (
            <DropdownMenu.Item onClick={() => setPendingDisconnectRelationship(item)}>
              <Icon name="LocationDiscover" size="sm" className="mr-2.5" variant="Bold" />
              <Typography variant="paragraph">Disconnect Relationship</Typography>
            </DropdownMenu.Item>
          ) : (
            <>
              <DropdownMenu.Item onClick={() => acceptInvitation(item.id)}>
                <Icon name="TickCircle" variant="Bold" size="md" className="mr-2 text-success" />
                <Typography variant="paragraph">Accept Invitation</Typography>
              </DropdownMenu.Item>
              <DropdownMenu.Item onClick={() => declineInvitation(item.id)}>
                <Icon name="CloseCircle" variant="Bold" size="md" className="mr-2 text-danger" />
                <Typography variant="paragraph">Decline Invitation</Typography>
              </DropdownMenu.Item>
            </>
          )}
        </>
      ) : (
        <>
          <Link to={`${item.id}/history`}>
            <DropdownMenu.Item>
              <Icon name="Clock" size="sm" className="mr-2.5" />
              <Typography variant="paragraph">View History Logs</Typography>
            </DropdownMenu.Item>
          </Link>

          {item.status === "active" && (
            <Link to={`${item.id}/fleet-leaders/${item.operator.id}`}>
              <DropdownMenu.Item>
                <Icon name="UserSquare" size="sm" className="mr-2.5" />
                <Typography variant="paragraph">View Fleet Leaders</Typography>
              </DropdownMenu.Item>
            </Link>
          )}
          {item.status !== "disconnected" && item.status !== "rejected" && (
            <DropdownMenu.Item onClick={() => setPendingDisconnectRelationship(item)}>
              <Icon name="LocationDiscover" size="sm" className="mr-2.5" variant="Bold" />
              <Typography variant="paragraph">Disconnect Relationship</Typography>
            </DropdownMenu.Item>
          )}
          {ability.can("manage", "faProvider") && (
            <DropdownMenu.Item disabled>
              <Toggle
                className={clsx("mr-2.5", { "hover:cursor-not-allowed": isLoadingToggle })}
                size="xs"
                checked={!item.paysCommission}
                onChange={(checked) => toggleOffloadFeeExemption(item.id, !checked)}
                disabled={isLoadingToggle}
              />
              <Typography variant="paragraph">Offload Fee Exemption</Typography>
            </DropdownMenu.Item>
          )}
        </>
      )}
    </DropdownMenu>
  );
};
