import { Passenger } from "@/components/pages";

export const passengerRoutes = [
  {
    path: "my-bookings",
    element: <Passenger />,
    handle: { pageName: "My Bookings" },
    children: [
      {
        index: true,
        element: <Passenger.MyBookings />,
      },
    ],
  },
  {
    path: "favourites",
    element: <Passenger />,
    handle: { pageName: "Favourites" },
    children: [
      {
        index: true,
        element: <Passenger.Favourites />,
      },
    ],
  },
  {
    path: "add-booking",
    element: <Passenger />,
    handle: { pageName: "Add Booking" },
    children: [
      {
        index: true,
        element: <Passenger.AddBooking />,
      },
    ],
  },
  {
    path: "my-profile",
    element: <Passenger />,
    handle: { pageName: "My Profile" },
    children: [
      {
        index: true,
        element: <Passenger.MyProfile />,
      },
    ],
  },
  {
    path: "company",
    element: <Passenger />,
    handle: { pageName: "Company" },
    children: [
      {
        index: true,
        element: <Passenger.Company />,
      },
    ],
  },
  {
    path: "settings",
    element: <Passenger />,
    handle: { pageName: "Settings" },
    children: [
      {
        index: true,
        element: <Passenger.Settings />,
      },
    ],
  },
  {
    path: "account-access",
    element: <Passenger />,
    handle: { pageName: "Account Access" },
    children: [
      {
        index: true,
        element: <Passenger.AccountAccess />,
      },
    ],
  },
  {
    path: "notifications",
    element: <Passenger />,
    handle: { pageName: "Notifications" },
    children: [
      {
        index: true,
        element: <Passenger.Notifications />,
      },
    ],
  },
  {
    path: "preferences",
    element: <Passenger />,
    handle: { pageName: "Preferences" },
    children: [
      {
        index: true,
        element: <Passenger.Preferences />,
      },
    ],
  },
  {
    path: "receipts",
    element: <Passenger />,
    handle: { pageName: "Receipts" },
    children: [
      {
        index: true,
        element: <Passenger.Receipts />,
      },
    ],
  },
  {
    path: "payment-information",
    element: <Passenger />,
    handle: { pageName: "Payment Information" },
    children: [
      {
        index: true,
        element: <Passenger.PaymentInformation />,
      },
    ],
  },
  {
    path: "admin",
    element: <Passenger />,
    handle: { pageName: "Admin" },
    children: [
      {
        index: true,
        element: <Passenger.Admin />,
      },
    ],
  },
];
