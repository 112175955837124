import { format } from "date-fns";
import { useMemo, useState } from "react";
import { Button, Icon, Panel, Tooltip, Typography } from "@/components/atoms";
import { useProfile } from "@/hooks";
import { useGetVehicleFormOptionsQuery } from "@/redux/apis/vehicle/vehicleApi";
import { clsx, formatCurrency, getAssetUrl, getCountryFlagSrc } from "@/utils";
import { VehicleStatusBadge } from "../MyVehicles/common/VehicleStatusBadge";
import { getRegistrationStatus } from "../MyVehicles/helpers";
import { useEditVehicleContext } from "./context";
import { ChangeNumberPlateStateModal } from "./modals/ChangeNumberPlateStateModal";
import { FirstTimeVerificationModal } from "./modals/FirstTimeVerificationModal";
import { UpdateVerificationModal } from "./modals/UpdateVerificationModal";

export const Sidebar = () => {
  return (
    <div className="space-y-5">
      <VehicleDetails />
      <NetworkEligibility />
    </div>
  );
};

const VehicleDetails = () => {
  const { vehicle } = useEditVehicleContext();
  const [step, setStep] = useState<string | undefined>();

  const { model, registrationNumber } = vehicle;

  const renderStateFlag = () => {
    const flagSrc = getCountryFlagSrc(vehicle.country.countryCode);

    return (
      <div className="flex items-center gap-3">
        {flagSrc && <img src={flagSrc} className="h-[18px] w-[18px] rounded-full object-cover" />}
      </div>
    );
  };

  return (
    <Panel className="w-[350px] shrink-0">
      <Typography variant="action">Vehicle Details</Typography>
      <Typography className="text-neutral-dark-gray">
        Details automatically filled by the system based on the Number Plate you entered
      </Typography>

      <div className="mt-3 flex items-center gap-3 rounded-lg bg-neutral-surface-gray px-3 py-3">
        <div className="flex items-center justify-center rounded-full border border-solid border-neutral-mid-gray p-2">
          <img
            src={getAssetUrl(model.avatar, { height: 56, width: 56, background: "transparent", fit: "contain" })}
            className="h-[28px] w-[28px] rounded-full"
          />
        </div>
        <div className="flex flex-1 flex-col">
          <Typography variant="title" className="text-neutral-black">
            {model.make} {model.model}
          </Typography>
          <Typography variant="action" className="flex items-center gap-1 text-neutral-dark-gray">
            Number Plate: {registrationNumber} <VehicleStatusBadge vehicle={vehicle} />
          </Typography>
        </div>
      </div>
      <div className="mt-3 grid grid-cols-2 gap-3">
        <div className="col-span-1 flex items-center">
          <Typography className="flex items-center text-neutral-dark-gray">Year</Typography>
        </div>
        <div className="col-span-1 flex items-center justify-end">
          <Typography className="inline-block capitalize text-neutral-black">{vehicle.year}</Typography>
        </div>
        <div className="col-span-1 flex items-center">
          <Typography className="flex items-center text-neutral-dark-gray">Color</Typography>
        </div>
        <div className="col-span-1 flex items-center justify-end">
          <Typography className=" inline-flex items-center capitalize text-neutral-black">
            {vehicle.color}
            <div
              className="ml-2 h-3 w-3 rounded-full border border-black"
              style={{ backgroundColor: vehicle.color ? vehicle.color : "" }}
            />
          </Typography>
        </div>

        <div className="col-span-1 flex items-center">
          <Typography className="flex items-center text-neutral-dark-gray">Engine Type</Typography>
        </div>
        <div className="col-span-1 flex items-center justify-end">
          <Typography className="inline-block capitalize text-neutral-black">{vehicle.model.engine.toLowerCase()}</Typography>
        </div>

        <div className="col-span-1 flex items-center">
          <Typography className="flex items-center text-neutral-dark-gray">VIN number</Typography>
        </div>
        <div className="col-span-1 flex items-center justify-end">
          <Typography className="inline-block capitalize text-neutral-black">{vehicle.vin}</Typography>
        </div>
        <div className="col-span-1 flex items-center">
          <Typography className="flex items-center text-neutral-dark-gray">State</Typography>
        </div>
        <div className="col-span-1 flex items-center justify-end">
          <Typography className="inline-flex gap-1 capitalize text-neutral-black">
            {renderStateFlag()}
            {vehicle.state.abbreviation}
          </Typography>
        </div>
        <div className="col-span-2">
          <Button variant="tertiary" onClick={() => setStep("change-number-plate")} size="lg" className="mt-3 w-full text-primary-dark">
            Change Number Plate or State?
          </Button>
        </div>
      </div>
      <ChangeNumberPlateStateModal vehicle={vehicle} open={step === "change-number-plate"} onClose={() => setStep(undefined)} />
    </Panel>
  );
};

const NetworkEligibility = () => {
  const { vehicle } = useEditVehicleContext();
  const { dateFormat } = useProfile();

  const status = getRegistrationStatus(vehicle);

  const message = useMemo(() => {
    switch (status) {
      case "unverified":
        return "Vehicle currently can not do network jobs";
      case "expired":
        return "Vehicle reverification required: It is currently unable to perform network jobs.";
      default:
        return "Vehicle can accept network jobs";
    }
  }, [status]);

  const isExpired = status === "expired";
  const isElligible = (status === "expiring" || status === "verified") && !!vehicle.registration.lastUpdated;

  return (
    <Panel className="w-[350px] shrink-0">
      <Typography variant="action" className="flex items-center gap-2">
        {isElligible ? "Vehicle has been verified" : "Enable this vehicle for network jobs?"}
        {isElligible && <Icon name="Verify" className="text-info" variant="Bold" size="md" />}
      </Typography>

      <Typography className="text-neutral-dark-gray">
        {isElligible && vehicle.registration.lastUpdated
          ? `Verified last: ${format(new Date(vehicle.registration.lastUpdated), dateFormat)}`
          : " Verify to enable network jobs outside your operations and ensure system integrity"}
      </Typography>
      <div className="mt-3 grid grid-cols-3 gap-3 rounded bg-neutral-surface-gray p-2.5">
        <div className="col-span-2 flex items-center">
          <Typography className="flex items-center text-neutral-dark-gray">
            Network Eligibility
            <Tooltip
              interactive
              content={
                <div className="max-w-[295px] font-medium">
                  This helps the system match your vehicle accurately within the platform and with external networks, ensuring proper job
                  allocations. <br />
                  <br />
                  <a
                    href="https://rideminder.freshdesk.com/en/support/solutions/articles/51000423941-vehicle-eligibility"
                    rel="noreferrer"
                    target="_blank"
                    className="underline"
                  >
                    {" "}
                    Learn more about Network Eligibility
                  </a>
                </div>
              }
              placement="top"
            >
              <Icon name="InfoCircle" variant="Bold" className="ml-1 inline-block -rotate-180 text-neutral-dark-gray" size="sm" />
            </Tooltip>
          </Typography>
        </div>
        <div className="col-span-1 flex items-center justify-end">
          <Typography className="inline-flex items-center gap-1 capitalize text-neutral-black">
            {isElligible ? vehicle.model.class.toLowerCase() : "-"}
          </Typography>
        </div>
      </div>
      <div
        className={clsx(
          "mt-3 grid grid-cols-3 gap-3 rounded bg-neutral-surface-gray p-2.5",
          isExpired && "bg-danger-light ring-1 ring-danger"
        )}
      >
        <div className="col-span-2 flex items-center">
          <Typography className="flex items-center text-neutral-dark-gray">Registration Expiry</Typography>
        </div>
        <div className="col-span-1 flex items-center justify-end">
          <Typography className={clsx("inline-flex items-center gap-1 capitalize text-neutral-black", isExpired && "text-danger")}>
            {vehicle.registration.expiryDate ? format(new Date(vehicle.registration.expiryDate), dateFormat) : "-"}
            {isExpired && (
              <Tooltip content="Registration has expired" placement="right">
                <Icon name="InfoCircle" variant="Bold" className="inline-block -translate-y-0.5 -rotate-180 text-danger" size="sm" />
              </Tooltip>
            )}
          </Typography>
        </div>
      </div>
      <Typography className={clsx("mt-5 text-center", isElligible ? "text-success" : "text-danger")}>{message}</Typography>
      <ActionButton status={status} />
    </Panel>
  );
};

const ActionButton = ({ status }: { status: string }) => {
  const { vehicle } = useEditVehicleContext();
  const [step, setStep] = useState<string | undefined>();
  const { data } = useGetVehicleFormOptionsQuery();

  const fee = useMemo(() => {
    if (!data) return 0;

    return data.fees.verificationFee;
  }, [data]);

  const renderButton = () => {
    switch (status) {
      case "unverified":
        return (
          <Button onClick={() => setStep("verify")} variant="primary" className="mt-5 w-full" size="lg">
            Verify for {formatCurrency(fee, 2, "AUD")}
          </Button>
        );
      case "expiring":
      case "expired":
      case "verified":
        return (
          <Button onClick={() => setStep("update-verification")} variant="secondary" className="mt-5 w-full" size="lg">
            Update Verification
          </Button>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {renderButton()}
      <UpdateVerificationModal vehicle={vehicle} open={step === "update-verification"} onClose={() => setStep(undefined)} />
      <FirstTimeVerificationModal vehicle={vehicle} open={step === "verify"} onClose={() => setStep(undefined)} />
    </>
  );
};
