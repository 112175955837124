import { useForm } from "react-hook-form";
import NetworkInvite from "@/assets/images/network/network-invite.svg";
import { Button, Icon, Loading, TextInput, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useRecommendProviderMutation } from "@/redux/apis/recommend/recommendApi";
import { addToast, clsx } from "@/utils";

type FormData = {
  email: string;
};

interface InviteNetworkEmailFormProps extends React.HTMLAttributes<HTMLDivElement> {
  setStep: (step: "inviteEmail" | undefined) => void;
}

export const InviteNetworkEmailForm = ({ setStep, className, ...props }: InviteNetworkEmailFormProps) => {
  const [recommendProvider, { isLoading }] = useRecommendProviderMutation();
  const { register, handleSubmit } = useForm<FormData>();

  const onSubmit = (data: FormData) => {
    if (!data) return;

    recommendProvider(data.email)
      .unwrap()
      .then(() => {
        addToast("success", "Successfully Sent an Invitation Email");
        setStep(undefined);
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  };

  return (
    <div className={clsx("relative", className)} {...props}>
      {isLoading && <Loading />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col items-center">
          <button type="button" className="ml-auto rounded-md text-black" onClick={() => setStep(undefined)}>
            <span className="sr-only">Close</span>
            <Icon name="close" isCustom size="lg" aria-hidden="true" />
          </button>
          <img src={NetworkInvite} className="mt-3.5 h-[128px] w-[128px]" />
          <div className="flex flex-col items-center justify-center gap-4 text-center">
            <Typography variant="h1">Invite a Company to RideMinder</Typography>
            <Typography variant="paragraph">
              Send an email invite to a transport company who is not yet a part of
              <br />
              RideMinder Network. Once they are registered, you will then be able to
              <br />
              invite them into your private network
            </Typography>
          </div>
          <div className="mt-8 flex w-full flex-col">
            <TextInput placeholder="Enter Email" className="w-full" maxLength={254} type="email" required {...register("email")} />
          </div>
        </div>
        <div className="mb-2 mt-8 flex justify-end">
          <Button type="submit" variant="primary" onClick={() => null} size="md" className="w-full">
            Send an Invitation Email
          </Button>
        </div>
      </form>
    </div>
  );
};
