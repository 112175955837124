import { useParams } from "react-router-dom";
import { CompaniesViewPanel, PageHeader, PageHeaderV2 } from "@/components/organisms";
import { Restricted } from "..";

export const CompaniesView = () => {
  const { id } = useParams<{ id: string }>();

  if (!id) return <Restricted />;

  return (
    <div className="flex flex-1 flex-col mb-12">
      <PageHeaderV2 skipCrumbs={1} title="Companies" />
      <PageHeader.Actions />
      <CompaniesViewPanel companyId={id} />
    </div>
  );
};
