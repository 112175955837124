import { isEmpty } from "ramda";
import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { DropdownSelect, Typography } from "@/components/atoms";
import { FleetFilterFormData, OffloadFilterFormData } from "../types";

interface VehicleYearFilterProps {
  from: number;
  to: number;
}

export const VehicleYearFilter = ({ from, to }: VehicleYearFilterProps) => {
  const { control } = useFormContext<FleetFilterFormData | OffloadFilterFormData>();
  const [filterItems, setFilterItems] = useState<{ name: string; value: string }[]>([]);

  useEffect(() => {
    const fromYear = from && Number(from);
    const toYear = to && Number(to);

    if (fromYear && toYear) {
      const yearItems = Array.from({ length: toYear - fromYear + 1 }, (_, i) => {
        const year = (i + fromYear).toString();
        return { name: year, value: year };
      });

      setFilterItems(yearItems);
    }
  }, [from, to]);

  if (isEmpty(filterItems)) return null;

  return (
    <div className="relative rounded-lg bg-neutral-surface-gray p-5">
      <Typography variant="paragraph">Vehicle Year</Typography>
      <div className="mt-3 flex flex-row gap-4">
        <div className="flex grow flex-col gap-1">
          <Typography variant="paragraph">From</Typography>
          <Controller
            control={control}
            name="vehicleYearFrom"
            render={({ field }) => (
              <DropdownSelect
                options={filterItems}
                position="bottom"
                iconName="Calendar"
                placeholder="Year"
                value={field.value}
                onChange={(value) => field.onChange(value)}
                optionsClassName="h-48"
              />
            )}
          />
        </div>
        <div className="flex grow flex-col gap-1">
          <Typography variant="paragraph">To</Typography>
          <Controller
            control={control}
            name="vehicleYearTo"
            render={({ field }) => (
              <DropdownSelect
                options={filterItems}
                position="bottom"
                iconName="Calendar"
                placeholder="Year"
                value={field.value}
                onChange={(value) => field.onChange(value)}
                optionsClassName="h-48"
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};
