import { GetRawTrasferTypesResponse, GetTransferTypesResponse } from "./types";

export const transformTransferTypes = (res: GetRawTrasferTypesResponse) => {
  const {
    _embedded: { transfer_type: items },
    page,
    page_count,
    page_size,
    total_items,
    _links,
  } = res;

  const response: GetTransferTypesResponse = {
    data: {
      items: items.map((i) => ({
        id: i.uuid,
        name: i.name,
        description: i.description,
        isActive: i.is_active,
        isSpecialEvent: i.is_special_event,
        isAdminOnly: i.is_admin_only,
        isDispatchEnabled: i.is_dispatch_enabled,
        order: i.order_score,
        multiplier: i.multiplier,
        type: i.type,
        taxStatus: i.tax_status,
      })),
      page: {
        current: page,
        count: page_count,
        size: page_size,
        total: total_items,
      },
    },
    links: _links,
  };

  return response;
};
