import { useNavigate } from "react-router-dom";
import RMLogo from "@/assets/images/rm-logo-emblem.svg";
import { Typography } from "@/components/atoms";
import { useLayout, useOperator } from "@/hooks";
import { useAppSelector } from "@/redux/hooks";
import { operatorsSelector } from "@/redux/slices/operator/selectors";
import { clsx } from "@/utils";

interface SidebarLogoProps extends React.HTMLAttributes<HTMLDivElement> {
  ignoreSidebarOpen?: boolean;
};

export const SidebarLogo = ({ ignoreSidebarOpen, className, ...props }: SidebarLogoProps) => {
  const navigate = useNavigate();
  const {
    layout: { sidebarOpen: isOpen },
  } = useLayout();
  const sidebarOpen = ignoreSidebarOpen ? true : isOpen;
  const operator = useOperator();
  const operators = useAppSelector(operatorsSelector);
  const logoUrl = operator?.images?.emblem ? operator.images.emblem : RMLogo;

  const handleClick = () => navigate(`/operator/${operator.id}/operators`);

  return (
    <div className={clsx(`${sidebarOpen ? "px-3" : "justify-center px-2.5"}`, className)} {...props}>
      <figure
        {...(operators.length > 1 && { onClick: handleClick })}
        className={clsx("flex flex-shrink-0 items-center rounded-lg px-2.5 py-5", {
          "cursor-pointer hover:bg-primary-light": operators.length > 1,
          "place-content-center": !sidebarOpen,
        })}
      >
        <img className="flex h-8 max-h-[32px] max-w-[90px] object-contain" src={logoUrl} alt="RideMinder" />
        {sidebarOpen && (
          <Typography variant="h3" className="ml-3">
            {operator.name || "Rideminder"}
          </Typography>
        )}
      </figure>
    </div>
  );
};
