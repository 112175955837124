import React from "react";
import { clsx } from "@/utils";
import { Tab } from "./Tab";

export const Tabs = ({ children, className, ...props }: React.HTMLAttributes<HTMLElement>) => {
  return (
    <nav className={clsx("flex max-w-full overflow-auto border-b border-transparent", className)} aria-label="Tabs" {...props}>
      {children}
    </nav>
  );
};

Tabs.Tab = Tab;
