import { differenceInWeeks, format } from "date-fns";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Icon, IconButton, Tooltip, Typography } from "@/components/atoms";
import { useProfile } from "@/hooks";
import { VehicleRegistrationStatusBadge } from "../../EditVehicle/common/VehicleRegistrationStatusBadge";
import { getRegistrationStatus } from "../../MyVehicles/helpers";
import { VehicleDetailCard } from "../common/VehicleDetailCard";
import { useViewVehicleContext } from "../context";

export const RegistrationExpiryDetailCard = () => {
  const navigate = useNavigate();
  const { dateFormat } = useProfile();
  const { vehicle } = useViewVehicleContext();
  const { registration } = vehicle;

  const status = getRegistrationStatus(vehicle);

  const tooltip = useMemo(() => {
    const expiringInWeeks = registration.expiryDate ? differenceInWeeks(new Date(registration.expiryDate), new Date()) : undefined;

    switch (status) {
      case "expiring":
        return (
          <Typography className="leading-tight">
            Vehicle registration expiring in {expiringInWeeks} weeks. <br /> Kindly update the registration date
          </Typography>
        );
      case "expired":
        return (
          <Typography className="leading-tight">
            Vehicle registration expired.
            <br /> This vehicle is now blocked from
            <br /> accepting network jobs
          </Typography>
        );

      case "verified":
      default:
        return undefined;
    }
  }, [registration.expiryDate, status]);

  const ActionButton = () => {
    switch (status) {
      case "expiring":
        return (
          <Tooltip content="Renew Verification" placement="bottom">
            <IconButton onClick={() => navigate("./edit")} iconName="Refresh2" variant="primary" size="sm" className="relative z-10" />
          </Tooltip>
        );
      case "expired":
        return (
          <Tooltip content="Renew Verification" placement="bottom">
            <IconButton onClick={() => navigate("./edit")} iconName="Refresh2" variant="primary" size="sm" className="relative z-10" />
          </Tooltip>
        );
      default:
        return null;
    }
  };

  return (
    <VehicleDetailCard
      icon={<Icon name="Calendar" size="xl" className="text-neutral-black" />}
      description={registration.expiryDate ? `Expires at: ${format(new Date(registration.expiryDate), dateFormat)}` : "N/A"}
      title={
        <>
          Vehicle Registration
          <VehicleRegistrationStatusBadge vehicle={vehicle} />
        </>
      }
      tooltip={tooltip}
      status={status}
      action={<ActionButton />}
      actionClick={status === "expiring" || status === "expired" ? () => navigate("./edit") : undefined}
    />
  );
};
