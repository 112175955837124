import { useLocalStorageValue } from "@react-hookz/web";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ArrayParam, DecodedValueMap, NumberParam, StringParam, useQueryParams } from "use-query-params";
import { useOperator, usePagination } from "@/hooks";
import { GetVehiclesFilterParams } from "@/redux/apis/vehicle/types";
import { VehicleFilters } from "./types";

export type VehicleFilterKey = keyof Omit<VehicleFilters, "search">;
export type VehicleFilterValue = string | boolean | (string | null)[] | null | undefined;

const DEFAULT_FILTERS: Record<VehicleFilterKey, undefined> = {
  make: undefined,
  model: undefined,
  minYear: undefined,
  color: undefined,
  minCapacityPax: undefined,
  minCapacityBags: undefined,
  engineType: undefined,
  class: undefined,
  type: undefined,
};

const filterParams = {
  make: StringParam,
  model: StringParam,
  minYear: NumberParam,
  color: ArrayParam,
  minCapacityBags: NumberParam,
  minCapacityPax: NumberParam,
  engineType: ArrayParam,
  class: ArrayParam,
  type: ArrayParam,
};

interface IVehicleFiltersContext {
  params: GetVehiclesFilterParams;
  sortedFilters: [VehicleFilterKey, VehicleFilterValue][];
  filters: DecodedValueMap<typeof filterParams>;
  setFilter: (key: VehicleFilterKey, value?: VehicleFilterValue) => void;
  setFilters: (newFilters: Record<VehicleFilterKey, VehicleFilterValue>) => void;
  clearFilters: () => void;
  resetFilters: () => void;
}

const VehicleFiltersContext = createContext<IVehicleFiltersContext | null>(null);

export const VehicleFiltersProvider = ({ children }: React.PropsWithChildren) => {
  const { id: operatorId } = useOperator();
  const { clearPagination } = usePagination("my-vehicles", 100);
  const [filters, setFilters] = useQueryParams(filterParams);
  const { set: setPersistedFilters, value: persistedFilters } = useLocalStorageValue<VehicleFilters>(`${operatorId}:my-vehicles-filter`, {
    defaultValue: DEFAULT_FILTERS,
  });
  const [filterSorting, setFilterSorting] = useState<VehicleFilterKey[]>([]);

  useEffect(() => {
    if (!persistedFilters || !!filters) return;
    setFilters(persistedFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortedFilters = useMemo(() => {
    const mergedFilters = Object.keys(filters).reduce((acc, key) => {
      if (!filterSorting.includes(key as VehicleFilterKey) && filters[key as VehicleFilterKey] !== undefined) {
        acc.push(key as VehicleFilterKey);
      }
      return acc;
    }, filterSorting);

    return (mergedFilters || []).map((key) => [key, filters[key]] as const);
  }, [filterSorting, filters]);

  const params: GetVehiclesFilterParams = useMemo(
    () => ({
      make: filters.make ?? undefined,
      model: filters.model ?? undefined,
      color: filters.color ? (filters.color.filter(Boolean) as string[]) : undefined,
      engine_type: filters.engineType ? (filters.engineType.filter(Boolean) as string[]) : undefined,
      min_year: filters.minYear ?? undefined,
      max_year: filters.minYear ?? undefined,
      min_capacity_pax: filters.minCapacityPax ?? undefined,
      min_capacity_bags: filters.minCapacityBags ?? undefined,
      class: filters.class ? (filters.class.filter(Boolean) as string[]) : undefined,
      type: filters.type ? (filters.type.filter(Boolean) as string[]) : undefined,
    }),
    [filters]
  );

  const clearFilters = useCallback(() => {
    clearPagination();
    const clearedFilters = Object.entries(filters)
      .filter(([_, value]) => value !== undefined)
      .reduce((acc, [key]) => {
        acc[key as VehicleFilterKey] = null;
        return acc;
      }, {} as VehicleFilters);

    setFilters(clearedFilters);
    setPersistedFilters(clearedFilters);
  }, [clearPagination, filters, setFilters, setPersistedFilters]);

  const resetFilters = useCallback(() => {
    clearPagination();
    setFilterSorting([]);
    setFilters(DEFAULT_FILTERS);
    setPersistedFilters(DEFAULT_FILTERS);
  }, [clearPagination, setFilterSorting, setFilters, setPersistedFilters]);

  const setFilter = useCallback(
    (key: VehicleFilterKey, value?: VehicleFilterValue) => {
      if (value) {
        clearPagination();
      }

      setFilters((prev) => ({ ...prev, [key]: value }));
      setPersistedFilters((prev) => ({ ...prev, [key]: value }));
      setFilterSorting((prev = []) => {
        if (value === undefined) {
          return prev.filter((i) => i !== key);
        }
        return prev.includes(key) ? prev : [...prev, key];
      });
    },
    [setFilters, setPersistedFilters, setFilterSorting, clearPagination]
  );

  const setAllFilters = useCallback(
    (newFilters: Record<VehicleFilterKey, VehicleFilterValue>) => {
      clearPagination();
      setFilters(newFilters as VehicleFilters);
      setPersistedFilters(newFilters as VehicleFilters);
      setFilterSorting(Object.keys(newFilters) as VehicleFilterKey[]);
    },
    [setFilters, setPersistedFilters, setFilterSorting, clearPagination]
  );

  const value = {
    params,
    sortedFilters: sortedFilters as [VehicleFilterKey, VehicleFilterValue][],
    filters,
    setFilter,
    setFilters: setAllFilters,
    clearFilters,
    resetFilters,
  };

  return <VehicleFiltersContext.Provider value={value}>{children}</VehicleFiltersContext.Provider>;
};

export const useVehicleFilters = () => {
  const context = useContext(VehicleFiltersContext);
  if (!context) {
    throw new Error("useVehicleFilters must be used within a VehicleFiltersProvider");
  }

  return context;
};
