import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { getErrorData, getErrorMessages, isErrorResponse } from "@/helpers/reduxHelpers";
import { RawErrorResponse } from "@/redux/types";

export const getVerificationErrorType = (error: FetchBaseQueryError | SerializedError | undefined) => {
  if (!error || !isErrorResponse(error)) return undefined;

  const messages = getErrorMessages(error || {}).join(", ");
  if (messages.includes("Vehicle verification failed")) return "verification-failed";

  const errorData = getErrorData(error as RawErrorResponse);
  if (!errorData) return "verification-failed";

  const { error: msg, data } = errorData;

  switch (msg) {
    case "VEHICLE_EXISTS":
      return data?.is_archived ? `vehicle-archived-${data.vehicle_uuid}` : "vehicle-exists";
    case "NO_MATCH":
      return "no-match";
    default:
      return "verification-failed";
  }
};
