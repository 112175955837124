import { useMemo } from "react";
import { DataTable, DataTableSetSorting, DataTableSorting, Pagination, NoFilterResults, NoSearchResults } from "@/components/molecules";
import { usePagination } from "@/hooks";
import { useAppSelector } from "@/redux/hooks";
import { offloadDriversPageSelector } from "@/redux/slices/driver/selectors";
import { OffloadDriver } from "@/redux/slices/driver/types";
import { clsx } from "@/utils";
import { generateOffloadData, generateOffloadColumns } from "../helpers";

interface OffloadTableProps extends React.HTMLAttributes<HTMLDivElement> {
  items: OffloadDriver[];
  sorting: DataTableSorting;
  setSorting: DataTableSetSorting;
  onBanDriver: (driver: OffloadDriver) => void;
  isSearchActive: boolean;
}

export const OffloadTable = ({ items, sorting, setSorting, onBanDriver, isSearchActive, className, ...props }: OffloadTableProps) => {
  const data = useMemo(
    () => generateOffloadData(items, onBanDriver),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items]
  );
  const columns = useMemo(() => generateOffloadColumns(), []);

  return (
    <div className={clsx("relative flex", className)}>
      <DataTable
        className="[&_table]:table-auto overflow-x-auto w-full"
        columns={columns}
        data={data}
        sorting={sorting}
        setSorting={setSorting}
        emptyState={isSearchActive ? <NoSearchResults /> : <NoFilterResults title="No Driver Found" />}
        {...props}
      />
    </div>
  );
};

const OffloadTablePagination = () => {
  const { current, count, total, size } = useAppSelector(offloadDriversPageSelector);
  const { handlePageClick, handleSizeChange } = usePagination();

  return (
    <Pagination
      onPageClick={handlePageClick}
      onSizeChange={handleSizeChange}
      currentPage={current}
      pageSize={size}
      totalItems={total}
      pageCount={count}
    />
  );
};

OffloadTable.Pagination = OffloadTablePagination;