import { DataTableColumnDef } from "@/components/molecules";
import { PrivateNetworkHistoryTableData, PrivateNetworkTableData } from "./types";

export const generateNetworkColumns = () => {
  const columns: DataTableColumnDef<PrivateNetworkTableData>[] = [
    { accessorKey: "operator", header: "Operator", cell: (props) => props.row.original.operator, enableSorting: false },
    { accessorKey: "status", header: "Status", cell: (props) => props.row.original.status, enableSorting: false },
    {
      accessorKey: "linkedAt",
      header: "Linked at",
      cell: (props) => props.row.original.linkedAt,
      enableSorting: false,
    },
    {
      accessorKey: "offloadFeeExemption",
      header: "Offload Fee Exemption",
      cell: (props) => props.row.original.offloadFeeExemption,
      enableSorting: false,
    },
    {
      accessorKey: "keyContact",
      header: "Key Contact",
      cell: (props) => props.row.original.keyContact,
      enableSorting: false,
    },
    {
      accessorKey: "action",
      header: "Action",
      cell: (props) => props.row.original.action,
      size: 75,
      enableSorting: false,
    },
  ];

  return columns;
};

export const generateNetworkHistoryColumns = () => {
  const columns: DataTableColumnDef<PrivateNetworkHistoryTableData>[] = [
    {
      accessorKey: "dateOfRequest",
      header: "Date of Request",
      cell: (props) => props.row.original.dateOfRequest,
      enableSorting: false,
    },
    {
      accessorKey: "timeOfRequest",
      header: "Time of Request",
      cell: (props) => props.row.original.timeOfRequest,
      enableSorting: false,
    },
    { accessorKey: "operator", header: "Operator", cell: (props) => props.row.original.operator, enableSorting: false },
    { accessorKey: "sender", header: "Sender", cell: (props) => props.row.original.sender, enableSorting: false },
    { accessorKey: "field", header: "Field", cell: (props) => props.row.original.field, enableSorting: false },
    { accessorKey: "value", header: "Value", cell: (props) => props.row.original.value, enableSorting: false },
  ];

  return columns;
};
