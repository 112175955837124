import { getPhpHostUrl } from "@/helpers/apiHelpers";
import { useAppSelector } from "@/redux/hooks";
import { activePassengerSelector } from "@/redux/slices/passenger/selectors";

export const AddBooking = () => {
  const url = getPhpHostUrl();
  const activePassenger = useAppSelector(activePassengerSelector);

  return (
    <div className="flex w-full h-full">
      <iframe className="w-full h-full" title="Add Booking" src={`${url}/passenger/${activePassenger.id}/booking`} />
    </div>
  );
};
