import { usePagination, useSearch, useSorting } from "@/hooks";
import { GetClientsParams } from "@/redux/apis/client/types";

export const useGetCompaniesParams = () => {
  const [search, setSearch] = useSearch();
  const { page, pageSize } = usePagination();
  const { sortKey, direction } = useSorting();

  const params: GetClientsParams = {
    page,
    page_size: pageSize,
    sort: sortKey,
    direction,
    search,
  };

  return { params, page, pageSize, sort: sortKey, direction, search, setSearch };
};
