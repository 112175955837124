import { Controller, useForm } from "react-hook-form";
import { Button, Icon, Loading, Stars, TextArea, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useEditRatingMutation } from "@/redux/apis/rating/ratingApi";
import { RatingItem } from "@/redux/slices/rating/types";
import { RatingLink } from "./RatingLink";
import { useEffect, useRef, useState } from "react";

interface EditRatingFormProps {
  item?: RatingItem;
  onClose: () => void;
  onSuccess: () => void;
  onError: (messages: string[]) => void;
}

export const EditRatingForm = ({ item, onClose, onSuccess, onError, ...props }: EditRatingFormProps) => {
  const [editRating, { isLoading }] = useEditRatingMutation();
  const {
    control,
    register,
    handleSubmit,
    formState: { isDirty },
  } = useForm<{ rating: number; comment: string }>({
    defaultValues: item && { rating: item.rating, comment: "" },
  });
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState<number>();

  useEffect(() => {
    if (containerRef.current) setContainerWidth(containerRef.current.offsetWidth);
  }, [containerRef]);

  const onSubmit = handleSubmit((data) => {
    if (item) {
      editRating({
        id: item.id,
        rating: data.rating,
        comment: data.comment,
      })
        .unwrap()
        .then(onSuccess)
        .catch((e) => onError(getErrorMessages(e)));
    }
  });

  return (
    <div ref={containerRef}>
      <div className="my-3 flex items-center">
        <div>
          <Typography variant="title">Update Driver Rating</Typography>
        </div>
        <button type="button" className="ml-auto rounded-md text-black" onClick={onClose}>
          <span className="sr-only">Close</span>
          <Icon name="close" isCustom size="lg" aria-hidden="true" />
        </button>
      </div>
      <form id="edit-rating-form" className="relative flex flex-col min-h-[540px]" onSubmit={onSubmit} {...props}>
        {isLoading && <Loading />}
        <div className="mt-2 flex-1 space-y-8">
          {item ? (
            <div className="rounded-lg bg-neutral-surface-gray p-5">
              <div className="grid grid-flow-col justify-stretch gap-10">
                <div className="flex flex-col">
                  <Typography variant="paragraph" className="text-neutral-dark-gray">
                    Job ID
                  </Typography>
                  <RatingLink name={item.jobId} path={item.jobPath} hasIcon />
                </div>
                <div className="flex flex-col">
                  <Typography variant="paragraph" className="text-neutral-dark-gray">
                    Customer
                  </Typography>
                  <Typography variant="paragraph">{item.customerName}</Typography>
                </div>
                <div className="flex flex-col">
                  <Typography variant="paragraph" className="text-neutral-dark-gray">
                    Driver
                  </Typography>
                  <RatingLink name={item.driverName} path={item.driverPath} hasIcon />
                </div>
              </div>
              <div className="mt-5">
                <Typography variant="paragraph" className="text-neutral-dark-gray">
                  Comments
                </Typography>
                <Typography variant="paragraph">{item.comments}</Typography>
              </div>
            </div>
          ) : <div className="h-[155px] rounded-lg bg-neutral-surface-gray p-5" />}
          <div className="flex flex-col items-center">
            <Typography variant="paragraph" className="text-neutral-dark-gray">
              Click on the Star to update the ratings
            </Typography>
            {containerWidth && (
              <Controller
                name="rating"
                control={control}
                render={({ field }) => (<Stars value={field.value} size={containerWidth < 400 ? 50 : 80} color1="#F2F3F3" onChange={(newRating) => field.onChange(newRating)} />)}
              />
            )}
          </div>
          <div className="flex flex-col gap-2">
            <Typography variant="paragraph" className="text-neutral-dark-gray">
              Reason for Updating the Rating
            </Typography>
            <TextArea rows={2} maxLength={1024} hideCharCount required {...register("comment")} />
          </div>
          <div className="flex">
            <Button variant="primary" type="submit" form="edit-rating-form" size="lg" className="mb-3 w-full" disabled={!isDirty}>
              Update
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};
