import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppAbility } from "@/casl";
import { Button, IconButton, SearchInput, Skeleton } from "@/components/atoms";
import { CompaniesModals, PageHeaderV2 } from "@/components/organisms";
import { useGetCompaniesParams } from "@/components/organisms/Companies/hooks/useGetCompaniesParams";
import { CompaniesTable } from "@/components/organisms/Companies/tables/CompaniesTable";
import { usePagination } from "@/hooks";
import { useGetCompaniesQuery } from "@/redux/apis/company/companyApi";
import { useAppSelector } from "@/redux/hooks";
import { companiesSelector } from "@/redux/slices/company/selectors";
import { CompaniesAdd } from "./CompaniesAdd";
import { CompaniesEdit } from "./CompaniesEdit";
import { CompaniesView } from "./CompaniesView";

export const Companies = () => {
  const navigate = useNavigate();
  const ability = useAppAbility();
  const { clearPagination } = usePagination();

  const { params, search, setSearch } = useGetCompaniesParams();
  const { isFetching } = useGetCompaniesQuery(params, { refetchOnMountOrArgChange: true });
  const companies = useAppSelector(companiesSelector);
  const isCompaniesEmpty = companies.length < 1 && !search && !isFetching;

  const [isSearchActive, setIsSearchActive] = useState(false);

  const handleSearch = (value?: string) => {
    if (!value) setIsSearchActive(false);

    clearPagination();
    setSearch(value);
  };

  const renderHeaderActions = () => {
    if (isSearchActive) {
      return (
        <div className="flex items-center">
          <SearchInput
            isLoading={isFetching}
            search={search}
            onSearch={handleSearch}
            className="w-full max-w-sm sm:w-fit"
            placeholder="Search Company"
            size="md"
          />
        </div>
      );
    }

    return (
      <div className="flex flex-row items-center">
        <div className="hidden sm:flex sm:flex-row sm:gap-x-1">
          <Button variant="tertiary" size="sm" startIcon="SearchNormal1" onClick={() => setIsSearchActive(true)}>
            Search
          </Button>
          {ability.can("manage", "providerCompany") && !isCompaniesEmpty && (
            <Button onClick={() => navigate("add")} startIcon="Add" size="sm">
              New Company
            </Button>
          )}
        </div>
        {/* mobile view */}
        <div className="flex flex-row gap-x-1 sm:hidden">
          <IconButton variant="tertiary" size="sm" iconName="SearchNormal1" onClick={() => setIsSearchActive(true)} />
          {ability.can("manage", "providerCompany") && !isCompaniesEmpty && (
            <IconButton size="sm" iconName="Add" onClick={() => navigate("add")} />
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="mb-12 flex flex-1 flex-col">
      <PageHeaderV2 skipCrumbs={1} title="Companies">
        {renderHeaderActions()}
      </PageHeaderV2>
      {isFetching ? (
        <Skeleton />
      ) : (
        <div className="mt-2">
          <CompaniesTable items={companies} isEmpty={isCompaniesEmpty} className="bg-white" />
          <CompaniesTable.Pagination />
        </div>
      )}
      <CompaniesModals />
    </div>
  );
};

Companies.Add = CompaniesAdd;
Companies.Edit = CompaniesEdit;
Companies.View = CompaniesView;
