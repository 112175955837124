import { Page } from "@/redux/types";

export type DriverState = {
  fleet: FleetDriverState;
  offload: OffloadDriverState;
};

export type FleetDriverState = {
  drivers: FleetDriver[];
  page: Page;
  options: FleetOptions;
};

export type FleetDriver = {
  id: string;
  name: string;
  email: string;
  phone: string;
  tier: number;
  vehicleType: string;
  status: string;
  operator: string;
  avatar: string;
  state: string;
  isFleetLeader: boolean;
  profileCompletion: number;
  rating: number;
};

export type FleetOptions = {
  states: Array<{ name: string; value: string }>;
  tiers: Array<{ name: string; value: string }>;
  accountStatuses: Array<{ name: string; value: string }>;
  vehicleBrands: Array<{ name: string; value: string }>;
  vehicleTypes: Array<{ name: string; value: string }>;
  vehicleYears: { from: number; to: number };
  vehicleColors: Array<{ name: string; value: string }>;
};

export const fleetOptionsInitialState: FleetOptions = {
  states: [],
  tiers: [],
  accountStatuses: [],
  vehicleBrands: [],
  vehicleTypes: [],
  vehicleYears: { from: 1990, to: Number.MAX_SAFE_INTEGER },
  vehicleColors: [],
};

export type OffloadDriverState = {
  drivers: OffloadDriver[];
  page: Page;
  options: OffloadOptions;
};

export type OffloadDriver = {
  id: string;
  name: string;
  state: string;
  vehicleType: string;
  totalJobs: number;
  operatorName: string;
  operatorPhone: string;
  avatar: string | null;
  blockReason: string;
  rating: number;
};

export type OffloadOptions = {
  states: Array<{ name: string; value: string }>;
  vehicleBrands: Array<{ name: string; value: string }>;
  vehicleYears: { from: number; to: number };
  vehicleColors: Array<{ name: string; value: string }>;
};

export type DriverSearchItem = {
  id: string;
  name: string;
  avatar: string;
  email: string;
  phone: string;
  operator: {
    id: string;
    providerKey: string;
    name: string;
  };
  warnings: string[];
  errors: Array<{ key: string; value: string }>;
  recommendedCommissionRate?: number;
};

export const offloadOptionsInitialState = {
  states: [],
  vehicleBrands: [],
  vehicleYears: {},
  vehicleColors: [],
};
