import { DataTableColumnDef } from "@/components/molecules";
import { RatingsTableData } from "./types";

export const generateColumns = () => {
  const columns: DataTableColumnDef<RatingsTableData>[] = [
    { accessorKey: "jobId", header: "Job ID", cell: (props) => props.row.original.jobId, enableSorting: false },
    {
      accessorKey: "customer",
      header: "Passenger",
      cell: (props) => props.row.original.customer,
      enableSorting: false,
    },
    { accessorKey: "driver", header: "Driver", cell: (props) => props.row.original.driver, enableSorting: false },
    {
      accessorKey: "rating",
      header: "Rating",
      size: 200,
      cell: (props) => props.row.original.rating,
      enableSorting: false,
    },
    { accessorKey: "comments", header: "Comments", size: 400, enableSorting: false },
    {
      accessorKey: "action",
      header: "Action",
      cell: (props) => props.row.original.action,
      size: 75,
      enableSorting: false,
    },
  ];

  return columns;
};
