import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithoutProvider } from "@/redux/baseQuery";
import { createSearchParams } from "@/utils";
import { transformVehicleMake, transformVehicleModel } from "./helpers";
import { SearchVehicleModelResponse, SearchVehicleModelParams, SearchVehicleMakeResponse } from "./types";

export const vehicleGuestApi = createApi({
  reducerPath: "vehicleGuestApi",
  baseQuery: baseQueryWithoutProvider("vehicle"),
  endpoints: (builder) => ({
    searchVehicleModel: builder.query<SearchVehicleModelResponse, SearchVehicleModelParams>({
      query: ({ q, ...params }) => `/model?${createSearchParams({ search: q, ...params }).toString()}`,
      transformResponse: transformVehicleModel,
    }),
    searchVehicleMake: builder.query<SearchVehicleMakeResponse, { q: string; type: string }>({
      query: ({ q, ...params }) => `/make?${createSearchParams({ search: q, ...params }).toString()}`,
      transformResponse: transformVehicleMake,
    }),
  }),
});

export const { useLazySearchVehicleModelQuery, useSearchVehicleModelQuery, useSearchVehicleMakeQuery } = vehicleGuestApi;
