import { Disclosure, DisclosureButton, DisclosurePanel as Panel, Transition } from "@headlessui/react";
import { Icon, Typography } from "@/components/atoms";
import { clsx } from "@/utils";

interface DisclosurePanelProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
}

export const DisclosurePanel = ({ title, children, className }: DisclosurePanelProps) => {
  return (
    <Disclosure as="div" className={clsx("rounded-lg bg-neutral-surface-gray", className)}>
      {({ open }) => (
        <>
          <DisclosureButton className="flex w-full justify-between px-5 py-4 text-left outline-none">
            <Typography className="action">{title}</Typography>
            {open ? <Icon name="ArrowUp2" /> : <Icon name="ArrowDown2" />}
          </DisclosureButton>
          <Transition
            show={open}
            enter="transition transition-[max-height] duration-100 ease-in"
            enterFrom="transform max-h-0"
            enterTo="transform max-h-max"
            leave="transition transition-[max-height] duration-100 ease-out"
            leaveFrom="transform max-h-max"
            leaveTo="transform max-h-0"
          >
            <Panel className={clsx("px-5 pb-4", className)}>{children}</Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
};
