import { z } from "zod";
import { UninvoicedJobsFilterFormData } from "./types";

export const invoiceSetupFormSchema = z.object({
  beneficiaryBankName: z.string().nonempty("Field is required and can't be empty.").max(100, "Maximum of 100 characters."),
  beneficiaryBankAddress: z.string().nonempty("Field is required and can't be empty.").max(512, "Maximum of 512 characters."),
  swiftCode: z.string().nullable().optional(),
  accountName: z.string().nonempty("Field is required and can't be empty.").max(100, "Maximum of 100 characters."),
  bsb: z.string().nonempty("Field is required and can't be empty."),
  accountNumber: z.string().nonempty("Field is required and can't be empty.").max(9, "Maximum of 9 characters"),
  enablePaymentOfInvoicesViaCreditCard: z.boolean(),
  creditCardFeePassThrough: z.boolean(),
});

export const markAsPaidFormSchema = z.object({
  amountPaid: z.coerce.number().min(1).step(0.01, "Field must be a maximum of 2 decimal places."),
  dateReceived: z.date(),
});

export const uninvoicedJobsFilterFormSchema = z.object({
  generateOnFrequency: z.array(z.string()).optional(),
});

export const generateInvoiceFormSchema = z.object({
  jobs: z.array(
    z.object({
      id: z.number(),
      cost: z.number(),
    })
  ),
  emails: z.array(z.string()),
});

export const issueInvoiceFormSchema = z.object({
  emails: z.array(z.string()),
});

export const resendInvoiceFormSchema = z.object({
  emails: z.array(z.string()),
});

export const defaultUninvoicedJobsFilters: UninvoicedJobsFilterFormData = {
  generateOnFrequency: undefined,
};

export const addInvoiceNoteFormSchema = z.object({
  note: z.string().max(2048, "Maximum of 2048 characters."),
});
