import {
  Add,
  Airplane,
  Archive,
  ArchiveBook,
  ArrowDown,
  ArrowDown2,
  ArrowCircleDown2,
  ArrowLeft,
  ArrowLeft2,
  ArrowRight,
  ArrowRight2,
  ArrowUp,
  ArrowUp2,
  ArrowCircleUp2,
  Book,
  Buildings,
  Building,
  Calendar,
  Call,
  Camera,
  Car,
  Clock,
  CloseCircle,
  CommandSquare,
  Copy,
  Danger,
  DocumentText,
  DocumentUpload,
  DollarCircle,
  Edit2,
  Element,
  EmptyWallet,
  Export,
  ExportSquare,
  Eye,
  EyeSlash,
  FolderMinus,
  Gallery,
  Global,
  ImportCurve,
  InfoCircle,
  LikeTag,
  Link,
  Link2,
  LocationDiscover,
  Lock1,
  MessageNotif,
  MoneyRemove,
  MoneySend,
  Notification,
  People,
  Profile,
  Profile2User,
  Radio,
  ReceiptText,
  Refresh,
  Refresh2,
  Routing,
  SearchNormal1,
  Setting2,
  Setting3,
  Setting4,
  Sort,
  Star1,
  Stickynote,
  Text,
  TickCircle,
  Timer1,
  Trash,
  User,
  UserSquare,
  VolumeHigh,
  LogoutCurve,
  MinusCirlce as MinusCircle,
  AddCircle,
  Sms,
  ArrowSwapHorizontal,
  Send2,
  ShoppingCart,
  Card,
  Flash,
  UserTick,
  Flag,
  Crown,
  Location,
  Crown1,
  More,
  HambergerMenu,
  Verify,
  Receipt2,
  ArrangeVertical,
  Lifebuoy,
  Briefcase,
  Story,
  Google,
  SaveAdd,
} from "iconsax-react";
import type { IconProps as SaxProps } from "iconsax-react";
import { IconProps, iconSizes } from "./Icon";

export type IconVariant = SaxProps["variant"];

export interface IconSaxProps {
  name: string;
  size?: IconProps["size"];
  variant?: IconVariant;
  color?: string;
}

export const IconSax = ({ name, size = "lg", variant, color, ...props }: IconSaxProps) => {
  const svgProps = {
    size: typeof size === "number" ? size : iconSizes[size],
    variant,
    color,
    ...props,
  };

  switch (name) {
    case "ArrowLeft2":
      return <ArrowLeft2 {...svgProps} />;
    case "ArrowRight2":
      return <ArrowRight2 {...svgProps} />;
    case "ArchiveBook":
      return <ArchiveBook {...svgProps} />;
    case "ArrowUp":
      return <ArrowUp {...svgProps} />;
    case "ArrowUp2":
      return <ArrowUp2 {...svgProps} />;
    case "ArrowCircleUp2":
      return <ArrowCircleUp2 {...svgProps} />;
    case "ArrowDown":
      return <ArrowDown {...svgProps} />;
    case "ArrowDown2":
      return <ArrowDown2 {...svgProps} />;
    case "ArrowCircleDown2":
      return <ArrowCircleDown2 {...svgProps} />;
    case "Airplane":
      return <Airplane {...svgProps} />;
    case "Archive":
      return <Archive {...svgProps} />;
    case "ArrowRight":
      return <ArrowRight {...svgProps} />;
    case "ArrowLeft":
      return <ArrowLeft {...svgProps} />;
    case "Add":
      return <Add {...svgProps} />;
    case "Book":
      return <Book {...svgProps} />;
    case "Camera":
      return <Camera {...svgProps} />;
    case "Calendar":
      return <Calendar {...svgProps} />;
    case "CloseCircle":
      return <CloseCircle {...svgProps} />;
    case "Car":
      return <Car {...svgProps} />;
    case "Clock":
      return <Clock {...svgProps} />;
    case "Call":
      return <Call {...svgProps} />;
    case "Danger":
      return <Danger {...svgProps} />;
    case "Edit2":
      return <Edit2 {...svgProps} />;
    case "EmptyWallet":
      return <EmptyWallet {...svgProps} />;
    case "Element":
      return <Element {...svgProps} />;
    case "ExportSquare":
      return <ExportSquare {...svgProps} />;
    case "FolderMinus":
      return <FolderMinus {...svgProps} />;
    case "Gallery":
      return <Gallery {...svgProps} />;
    case "Global":
      return <Global {...svgProps} />;
    case "ImportCurve":
      return <ImportCurve {...svgProps} />;
    case "InfoCircle":
      return <InfoCircle {...svgProps} />;
    case "LocationDiscover":
      return <LocationDiscover {...svgProps} />;
    case "Lock1":
      return <Lock1 {...svgProps} />;
    case "Location":
      return <Location {...svgProps} />;
    case "Link":
      return <Link {...svgProps} />;
    case "LikeTag":
      return <LikeTag {...svgProps} />;
    case "MoneySend":
      return <MoneySend {...svgProps} />;
    case "Notification":
      return <Notification {...svgProps} />;
    case "Profile2User":
      return <Profile2User {...svgProps} />;
    case "Profile":
      return <Profile {...svgProps} />;
    case "Radio":
      return <Radio {...svgProps} />;
    case "Refresh2":
      return <Refresh2 {...svgProps} />;
    case "Refresh":
      return <Refresh {...svgProps} />;
    case "SearchNormal1":
      return <SearchNormal1 {...svgProps} />;
    case "Sort":
      return <Sort {...svgProps} />;
    case "Stickynote":
      return <Stickynote {...svgProps} />;
    case "Star1":
      return <Star1 {...svgProps} />;
    case "Setting2":
      return <Setting2 {...svgProps} />;
    case "TickCircle":
      return <TickCircle {...svgProps} />;
    case "Trash":
      return <Trash {...svgProps} />;
    case "Timer1":
      return <Timer1 {...svgProps} />;
    case "User":
      return <User {...svgProps} />;
    case "UserSquare":
      return <UserSquare {...svgProps} />;
    case "VolumeHigh":
      return <VolumeHigh {...svgProps} />;
    case "People":
      return <People {...svgProps} />;
    case "Buildings":
      return <Buildings {...svgProps} />;
    case "Building":
      return <Building {...svgProps} />;
    case "DocumentText":
      return <DocumentText {...svgProps} />;
    case "Text":
      return <Text {...svgProps} />;
    case "Setting4":
      return <Setting4 {...svgProps} />;
    case "MessageNotif":
      return <MessageNotif {...svgProps} />;
    case "ReceiptText":
      return <ReceiptText {...svgProps} />;
    case "Setting3":
      return <Setting3 {...svgProps} />;
    case "Export":
      return <Export {...svgProps} />;
    case "Eye":
      return <Eye {...svgProps} />;
    case "DollarCircle":
      return <DollarCircle {...svgProps} />;
    case "MoneyRemove":
      return <MoneyRemove {...svgProps} />;
    case "CommandSquare":
      return <CommandSquare {...svgProps} />;
    case "Link2":
      return <Link2 {...svgProps} />;
    case "Copy":
      return <Copy {...svgProps} />;
    case "EyeSlash":
      return <EyeSlash {...svgProps} />;
    case "Routing":
      return <Routing {...svgProps} />;
    case "LogoutCurve":
      return <LogoutCurve {...svgProps} />;
    case "MinusCircle":
      return <MinusCircle {...svgProps} />;
    case "AddCircle":
      return <AddCircle {...svgProps} />;
    case "Sms":
      return <Sms {...svgProps} />;
    case "ArrowSwapHorizontal":
      return <ArrowSwapHorizontal {...svgProps} />;
    case "Send2":
      return <Send2 {...svgProps} />;
    case "ShoppingCart":
      return <ShoppingCart {...svgProps} />;
    case "Card":
      return <Card {...svgProps} />;
    case "Flash":
      return <Flash {...svgProps} />;
    case "UserTick":
      return <UserTick {...svgProps} />;
    case "Flag":
      return <Flag {...svgProps} />;
    case "Crown":
      return <Crown {...svgProps} />;
    case "Crown1":
      return <Crown1 {...svgProps} />;
    case "More":
      return <More {...svgProps} />;
    case "HambergerMenu":
      return <HambergerMenu {...svgProps} />;
    case "DocumentUpload":
      return <DocumentUpload {...svgProps} />;
    case "Verify":
      return <Verify {...svgProps} />;
    case "Receipt2":
      return <Receipt2 {...svgProps} />;
    case "ArrangeVertical":
      return <ArrangeVertical {...svgProps} />;
    case "Lifebuoy":
      return <Lifebuoy {...svgProps} />;
    case "Briefcase":
      return <Briefcase {...svgProps} />;
    case "Story":
      return <Story {...svgProps} />;
    case "Google":
      return <Google {...svgProps} />;
    case "SaveAdd": 
      return <SaveAdd {...svgProps} />;
    default:
      console.error(`Icon ${name} not found`);
      return null;
  }
};
