import { useFormContext } from "react-hook-form";
import { AddOnTextInput, ErrorMessage, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { VehicleCategoryFormData } from "../../types";

export const CustomCommissionRateField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext<VehicleCategoryFormData>();
  const watchEnabledCustomCommissionRate = watch("enabledCustomCommissionRate");

  return (
    <div className={clsx("flex flex-col gap-y-1", className)} {...props}>
      <AddOnTextInput
        className="w-full"
        type="number"
        placeholder="0"
        endAddOn="%"
        step="any"
        disabled={!watchEnabledCustomCommissionRate}
        hasError={!!errors.customCommissionRate}
        {...register("customCommissionRate")}
      />
      <Typography variant="small" className="text-neutral-dark-gray">
        Enter Custom Driver Commission for this Vehicle Category
      </Typography>
      <ErrorMessage errors={errors} name="customCommissionRate" />
    </div>
  );
};
