import { Icon, Typography } from "@/components/atoms";
import { BookingStatus } from "@/redux/slices/booking/types";
import { capitalize, parseHtml } from "@/utils";
import { BookingStatusIcon } from "../common/BookingStatusIcon/BookingStatusIcon";
import { statusMapping } from "../fixtures";

export const getBookingHistoryIcon = (name: string) => {
  switch (true) {
    case name.includes("view"):
      return <Icon name="Eye" size="lg" className="shrink-0 text-primary" />;
    case name.includes("email"):
      return <Icon name="Sms" size="lg" className="shrink-0 text-primary" />;
    case name.includes("voice"):
      return <Icon name="Call" size="lg" className="shrink-0 text-primary" />;
    default:
      return <Icon name="ArchiveBook" size="lg" className="shrink-0 text-primary" />;
  }
};

export const isBookingHistoryEventSystemType = (name: string) => {
  return ["email", "sms", "call"].includes(name.toLowerCase());
};

export const getBookingHistoryEventText = (eventType: string, eventName: string, fieldName?: string) => {
  const formatName = (name?: string) => (name ? capitalize(name.replace("_", " ").replace("-", " ")) : "");

  switch (eventType) {
    case "view":
      return "Viewed Job";
  }

  switch (eventName) {
    case "email":
      return "Email Type";
    case "job.update":
      return "Job Updated";
    case "job.new":
      return "Job Created";
    case "driver.accepted.job":
      return "Driver Accepted Job";
    case "job.updated.admin-details":
      return "Admin Details Updated";
    case "job.passenger-dropped-off":
      return "Driver marked passenger dropped off";
    case "job.passenger-dropped-off-edited":
      return "Passenger dropped off time edited";
    case "driver.passenger-picked-up.job":
      return "Driver marked passenger picked up";
    case "driver.passenger-picked-up.job-edited":
      return "Passenger picked up time edited";
    case "job.close.awaiting-review.post":
      return "Job has been closed pending review of extras";
    case "job.close.post":
      return "Job has been closed";
    case "driver.declined.job":
      return "Driver has declined job";
    case "driver.en-route.job":
      return "Driver is on-way to pickup point";
    case "job.passenger-close-to-destination":
      return "Driver has marked themselves as approaching the destination";
    case "booking.cancel.post":
      return "Job has been closed";
    case "job.commenced":
      return "Job has commenced";
    case "job.offload.recall":
      return "Job offload recalled";
    case "job.reopen":
      return "Job has been reopened";
    case "job.offloaded":
      return "Job offloaded to network";
    case "job.payment.adjustment":
      return "Payment Adjustment";
    case "job.viewed":
      return "Viewed Job";
    case "job.paid":
      return "Job Paid";
    case "job.price.adjustment":
      return `Job Pricing Change: ${formatName(fieldName)}`;
    case "job.cancelled.field":
      return `Job Cancelled: ${formatName(fieldName)}`;
    case "job.close.field":
      return `Job Closed: ${formatName(fieldName)}`;
    case "job.admin.edit.field":
      return `Admin Edit: ${formatName(fieldName)}`;
    case "job.edit.field":
      return `Job Edited: ${formatName(fieldName)}`;
    case "job.status.change":
      return `Status Changed`;
    default:
      return formatName(fieldName || eventType);
  }
};

export const getBookingHistoryActionText = (name: string, from?: string, to?: string) => {
  const render = (value: string) => {
    const statusObj = statusMapping[value as BookingStatus];

    if (name === "job_status" && statusObj) {
      return (
        <span className="inline-flex items-center gap-1">
          <BookingStatusIcon
            status={{
              color: statusObj.color,
              icon: statusObj.icon,
            }}
          />
          {statusObj.legend}
        </span>
      );
    }
    return <span className="inline">{parseHtml(value, false)}</span>;
  };

  return (
    <div className="flex max-w-[270px] flex-col break-all">
      {to !== undefined && (
        <Typography variant="small" className="">
          <span className="inline-block min-w-[50px]">To:</span> {to ? render(to) : <span className="text-neutral-dark-gray">Empty</span>}
        </Typography>
      )}
      {from !== undefined && (
        <Typography variant="small" className="">
          <span className="inline-block min-w-[50px]">From:</span>{" "}
          {from ? render(from) : <span className="text-neutral-dark-gray">Empty</span>}
        </Typography>
      )}
    </div>
  );
};
