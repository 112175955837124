import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query";
import { getApiHostUrl, getBaseUrl, getDefaultHeaders } from "../helpers/apiHelpers";
import { AuthenticationResponse } from "./apis/auth/types";
import { primaryAccountIdSelector } from "./slices/account/selectors";
import { logOut, setAuth } from "./slices/auth/authSlice";
import { defaultCountrySelector, operatorIdSelector } from "./slices/operator/selectors";
import { RootState } from "./store";

// Form data related requests skips header setting
// https://github.com/reduxjs/redux-toolkit/issues/2287#issuecomment-1111213377
const FORMDATA_ENDPOINTS = [
  "uploadAsset",
  "addAccreditation",
  "editAccreditation",
  "updateAvatar",
  "addBookingOfferDocument",
  "addJobAttachment",
  "addAvatar",
  "addVehicleDocument",
];

export const baseQuery = fetchBaseQuery({
  baseUrl: getApiHostUrl(),
  prepareHeaders: (headers, { getState, endpoint }) => {
    const token = (getState() as RootState).auth.accessToken;
    if (token) headers.set("Authorization", `Bearer ${token}`);

    if (!FORMDATA_ENDPOINTS.includes(endpoint)) {
      headers = getDefaultHeaders(headers);
    }
    return headers;
  },
});

export const baseQueryWithAuth: typeof baseQuery = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);

  if (result?.error && (result?.error?.status === 401 || result?.error?.status === 403)) {
    console.info("[Reauth]- Getting a new token");
    const refreshResult = await baseQuery(
      {
        url: "/oauth",
        method: "POST",
        body: {
          grant_type: "refresh_token",
          scope: "web-app",
          client_id: "web-app",
          refresh_token: (api.getState() as RootState).auth.refreshToken,
        },
      },
      api,
      extraOptions
    );

    if (refreshResult?.data) {
      console.info("[Reauth]- retrieved token", refreshResult.data);
      const newAuth = refreshResult.data as AuthenticationResponse;
      api.dispatch(setAuth(newAuth));
      console.info("[Reauth]- continue previous api call", args);
      const newResult = await baseQuery(args, api, extraOptions);
      return newResult;
    } else {
      console.warn("[Reauth]- failed to retrieve token", refreshResult);
      api.dispatch(logOut());
      window.location.href = getBaseUrl() + "login";
    }
  }

  return result;
};

export const baseQueryWithProviderCountry: (endpoint?: string) => typeof baseQuery =
  (endpoint = "config") =>
  async (args, api, extraOptions) => {
    const country = defaultCountrySelector(api.getState());
    const operatorId = operatorIdSelector(api.getState());
    const urlEnd = typeof args === "string" ? args : args.url;
    const adjustedUrl = `/v3/provider/${operatorId}${endpoint ? `/${endpoint}` : ""}/${country}${urlEnd}`;
    const adjustedArgs = typeof args === "string" ? adjustedUrl : { ...args, url: adjustedUrl };
    return baseQueryWithAuth(adjustedArgs, api, extraOptions);
  };

export const baseQueryWithProvider: (endpoint?: string) => typeof baseQuery =
  (endpoint = "") =>
  async (args, api, extraOptions) => {
    const operatorId = operatorIdSelector(api.getState());
    const urlEnd = typeof args === "string" ? args : args.url;
    const adjustedUrl = `/v3/provider/${operatorId}/${endpoint}${urlEnd}`;
    const adjustedArgs = typeof args === "string" ? adjustedUrl : { ...args, url: adjustedUrl };
    return baseQueryWithAuth(adjustedArgs, api, extraOptions);
  };

export const baseQueryWithProviderEndpointFirst: (endpoint?: string) => typeof baseQuery =
  (endpoint = "") =>
  async (args, api, extraOptions) => {
    const operatorId = operatorIdSelector(api.getState());
    const urlEnd = typeof args === "string" ? args : args.url;
    const adjustedUrl = `/v3/${endpoint}/${operatorId}${urlEnd}`;
    const adjustedArgs = typeof args === "string" ? adjustedUrl : { ...args, url: adjustedUrl };
    return baseQueryWithAuth(adjustedArgs, api, extraOptions);
  };

export const baseQueryWithoutProvider: (endpoint?: string) => typeof baseQuery =
  (endpoint = "") =>
  async (args, api, extraOptions) => {
    const urlEnd = typeof args === "string" ? args : args.url;
    const adjustedUrl = `/v3/${endpoint}${urlEnd}`;
    const adjustedArgs = typeof args === "string" ? adjustedUrl : { ...args, url: adjustedUrl };
    return baseQuery(adjustedArgs, api, extraOptions);
  };

export const baseQueryWithAccount: (endpoint?: string) => typeof baseQuery =
  (endpoint = "") =>
  async (args, api, extraOptions) => {
    const accountId = primaryAccountIdSelector(api.getState());
    const urlEnd = typeof args === "string" ? args : args.url;
    const adjustedUrl = `/v3/account-management/${accountId}/${endpoint}${urlEnd}`;
    const adjustedArgs = typeof args === "string" ? adjustedUrl : { ...args, url: adjustedUrl };
    return baseQueryWithAuth(adjustedArgs, api, extraOptions);
  };
