import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithProvider } from "@/redux/baseQuery";
import { BanDriverResponse, BanDriverParams, UnbanDriverResponse, UnbanDriverParams } from "./types";

export const blockApi = createApi({
  reducerPath: "blockApi",
  baseQuery: baseQueryWithProvider("block"),
  endpoints: (builder) => ({
    banDriver: builder.mutation<BanDriverResponse, BanDriverParams>({
      query: ({ id, comment }) => ({
        url: "",
        method: "POST",
        body: {
          uuid: id,
          category: "PROVIDER_TO_ACCOUNT",
          comment,
        },
      }),
    }),
    unbanDriver: builder.mutation<UnbanDriverResponse, UnbanDriverParams>({
      query: ({ id }) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const { useBanDriverMutation, useUnbanDriverMutation } = blockApi;
