import { Avatar, Button, IconButton, Typography } from "@/components/atoms";
import { useLayout, useOperator, useProfile } from "@/hooks";
import { useAppSelector } from "@/redux/hooks";
import { activePassengerSelector } from "@/redux/slices/passenger/selectors";
import { getAssetUrl } from "@/utils";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { GeneralLogo } from "../General/GeneralLogo";
import { PassengerHeaderSidePanel } from "./PassengerHeaderSidePanel";
import { PassengerModalsSteps } from "./types";

interface PassengerHeaderMobileProps extends React.HTMLAttributes<HTMLDivElement> {
  setStep: (step: PassengerModalsSteps) => void;
}

export const PassengerHeaderMobile = ({ setStep, className, ...props }: PassengerHeaderMobileProps) => {
  const { setFullScreen } = useLayout();
  const navigate = useNavigate();  
  const { roles } = useOperator();
  const activeProfile = useProfile();
  const isPassenger = roles.length === 1 && roles.includes("Passenger");

  const activePassenger = useAppSelector(activePassengerSelector);
  const [openSidePanel, setOpenSidePanel] = useState(false);

  const navigateAdmin = () => {
    navigate("/bookings");
    setFullScreen(false);
  };

  return (
    <div className={className} {...props}>
      {!activePassenger.isPrimary && (
        <div className="flex relative items-center justify-center top-0 py-2 px-2 border-neutral-mid-gray bg-primary-light">
          <div className="flex flex-row w-full max-w-[1040px]">
            <div className="flex flex-1 flex-row items-center gap-3">
              <Avatar size="xs" src={getAssetUrl(activeProfile.avatar || "")} alt={`${activeProfile.firstName} ${activeProfile.lastName}`}/>
              <Typography variant="action">{`${activeProfile.firstName} ${activeProfile.lastName[0]}. (You)`}</Typography>
            </div>
            <div className="flex flex-row items-center">
              <Button size="sm" variant="secondary" onClick={() => isPassenger ? navigate("/my-bookings") : navigateAdmin()}>Stop Managing</Button>
            </div>
          </div>
        </div>
      )}
      <div className="flex relative items-center justify-center top-0 py-3 px-2 border-b-[1px] border-neutral-mid-gray bg-white">
        <div className="flex flex-row w-full max-w-[1040px]">
          <GeneralLogo />
          <div className="flex flex-row items-center">
            <IconButton iconName="HambergerMenu" variant="tertiary" iconSize="md" onClick={() => setOpenSidePanel(true)} />
          </div>
        </div>
      </div>
      <PassengerHeaderSidePanel open={openSidePanel} onClose={() => setOpenSidePanel(false)} setStep={setStep} />
    </div>
  );
};