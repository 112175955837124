import { Button, Loading, Modal, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useArchiveVehicleMutation, vehicleApi } from "@/redux/apis/vehicle/vehicleApi";
import { useAppDispatch } from "@/redux/hooks";
import { Vehicle } from "@/redux/slices/vehicle/types";
import { addToast, clsx } from "@/utils";

interface ArchiveVehicleModalProps extends React.HTMLAttributes<HTMLDivElement> {
  pendingArchive?: Vehicle;
  setPendingArchive: (vehicle?: Vehicle) => void;
}

export const ArchiveVehicleModal = ({ pendingArchive, setPendingArchive, className, ...props }: ArchiveVehicleModalProps) => {
  const dispatch = useAppDispatch();
  const [archiveVehicle, { isLoading }] = useArchiveVehicleMutation();

  const onRemoveClick = () => {
    if (!pendingArchive) return;

    archiveVehicle(pendingArchive.uuid)
      .unwrap()
      .then(() => {
        addToast("success", `Successfully archived ${pendingArchive.registrationNumber}`);
        setPendingArchive(undefined);
        dispatch(vehicleApi.util.invalidateTags(["Vehicle"]));
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  };

  return (
    <Modal open={!!pendingArchive} onClose={() => setPendingArchive(undefined)} className="max-w-[514px] !p-5">
      <div className={clsx("relative", className)} {...props}>
        {isLoading && <Loading />}
        <div className="max-w-lg sm:flex sm:items-start ">
          <div className="mt-3 text-center sm:mt-0 sm:text-left">
            <Typography variant="title">Archive Vehicle</Typography>
            <div className="mt-3 ">
              <Typography variant="paragraph">
                Are you sure you want to move <span className="text-info">{pendingArchive?.registrationNumber}</span> to archived vehicles?
                This will remove the vehicle from the network and remove all user shared access.
              </Typography>
            </div>
          </div>
        </div>
        <div className="mt-6 flex justify-end gap-4">
          <Button variant="secondary" onClick={() => setPendingArchive(undefined)} className="px-8">
            Cancel
          </Button>
          <Button variant="primary" onClick={onRemoveClick} className="border-danger !bg-danger px-8 hover:!bg-danger-dark">
            Archive Vehicle
          </Button>
        </div>
      </div>
    </Modal>
  );
};
