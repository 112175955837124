import { useMemo } from "react";
import { DataTable } from "@/components/molecules";
import { VehicleCategory } from "@/redux/slices/pricing/types";
import { generateColumns, generateTableData } from "./helpers";
import { clsx } from "@/utils";

interface VehicleCategoriesTableProps extends React.HTMLAttributes<HTMLDivElement> {
  items: VehicleCategory[];
}

export const VehicleCategoriesTable = ({ items, className, ...props }: VehicleCategoriesTableProps) => {
  const data = useMemo(() => generateTableData(items), [items]);
  const columns = useMemo(() => generateColumns(data), [data]);

  return <DataTable className={clsx("[&_table]:table-auto overflow-x-auto w-full", className)} columns={columns} data={data} {...props} />;
};
