import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithAuth } from "@/redux/baseQuery";
import { transformAccounts, transformAccountProfile } from "./helpers";
import { GetAccountsResponse, GetAccountProfileResponse, GetAccountProfileParams } from "./types";

export const accountApi = createApi({
  reducerPath: "accountApi",
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    getAccounts: builder.query<GetAccountsResponse, void>({
      query: () => "/v3/account-management",
      transformResponse: transformAccounts,
      providesTags: ["Account"],
    }),
    getAccountProfile: builder.query<GetAccountProfileResponse, GetAccountProfileParams>({
      query: ({ id }) => `/v3/account-management/${id}/profile`,
      transformResponse: transformAccountProfile,
    }),
  }),
  tagTypes: ["Account"],
});

export const { useLazyGetAccountsQuery, useLazyGetAccountProfileQuery } = accountApi;
