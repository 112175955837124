import { Icon, Typography } from "@/components/atoms";

interface FleetLeaderInfoProps {
  onClose: () => void;
}

export const FleetLeaderInfo = (fleetLeaderInfoProps: FleetLeaderInfoProps) => {
  const { onClose } = fleetLeaderInfoProps;

  return (
    <>
      <div className="sm:flex sm:items-start">
        <div className="my-3 w-full text-center sm:mt-0 sm:text-left">
          <div className="flex flex-row items-center">
            <Typography variant="title" className="flex-1">
              What&apos;s a Fleet Leader
            </Typography>
            <Icon name="close" isCustom className="flex cursor-pointer justify-end" onClick={onClose} />
          </div>
        </div>
      </div>
      <div className="flex w-[514px] max-w-full flex-col">
        <Typography variant="paragraph" className="text-neutral-dark-gray">
          Fleet leaders are defined by operators and have an elevated set of permissions. By being a fleet leader they are able to:
          <br />
          1. Reassign jobs to other drivers (via the driver app)
          <br />
          2. Be assigned jobs through the private network
          <br />
          3. Are visible to operators when searching in the Driver Manager
          <br />
          <br />A fleet leader can be identified as having a green circle around their profile picture.
        </Typography>
      </div>
    </>
  );
};
