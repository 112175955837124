import { useEffect } from "react";
import { useNavigate, useOutlet } from "react-router-dom";
import { Tabs } from "@/components/molecules";
import { PageHeader } from "@/components/organisms";
import { Fleet } from "./Fleet";
import { Offload } from "./Offload";

const pageTabs = [
  { name: "My Drivers", to: "fleet" },
  { name: "Offload Drivers", to: "offload" },
];

export const Drivers = () => {
  const outlet = useOutlet();
  const navigate = useNavigate();

  useEffect(() => {
    if (!outlet) navigate("../");
  }, [outlet, navigate]);

  return (
    <div className="mb-12 flex flex-1 flex-col">
      <PageHeader skipCrumbs={1} title="Drivers" />

      <Tabs className="mb-5 border-b !border-neutral-dark-gray">
        {pageTabs.map((t, i) => (
          <Tabs.Tab key={i} to={t.to} name={t.name} />
        ))}
      </Tabs>
      {outlet}
    </div>
  );
};

Drivers.Fleet = Fleet;
Drivers.Offload = Offload;
