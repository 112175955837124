import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Icon, IconButton, SidePanel, Tooltip, Typography } from "@/components/atoms";
import { usePage } from "@/hooks";
import { useAppSelector } from "@/redux/hooks";
import { PassengerModalsSteps } from "./types";
import { passengersSelector } from "@/redux/slices/passenger/selectors";
import { PassengerDisclosure } from "./PassengerDisclosure";
import { useAppAbility } from "@/casl";

interface PassengerHeaderSidePanelProps {
  open: boolean;
  onClose: () => void;
  setStep: (step: PassengerModalsSteps) => void;
}

export const PassengerHeaderSidePanel = ({ open, onClose, setStep }: PassengerHeaderSidePanelProps) => {
  const ability = useAppAbility();
  const location = useLocation();
  const navigate = useNavigate();  
  const { pathname } = usePage();
  const prevPage = pathname.substring(0, pathname.lastIndexOf('/'));

  const passengers = useAppSelector(passengersSelector);

  useEffect(() => {
    if (open) onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleNavigate = (path: string) => {
    navigate(path);
    onClose();
  };

  const handleSetStep = (step: PassengerModalsSteps) => {
    setStep(step);
    onClose();
  };

  return (
    <SidePanel open={open} onClose={onClose} width={300} focused>
      <div className="flex h-[72px] items-center justify-between pl-4 pr-6">
        <IconButton iconName="ArrowRight" onClick={onClose} variant="tertiary" iconSize="lg" className="hover:bg-transparent" />
      </div>
      <nav className="flex h-full flex-col">
        <div className="no-scrollbar flex-1 overflow-auto">
          <div className="flex flex-1 flex-col">
            <div className="flex flex-col px-6 py-10">
              <Typography variant="action" className="text-neutral-dark-gray">
                Menu
              </Typography>
              <div className="flex items-center border-b border-neutral-mid-gray cursor-pointer py-4 mt-5" onClick={() => handleNavigate(`${prevPage}/my-bookings`)}>
                <Typography variant="title" className="!text-title">My Bookings</Typography>
              </div>
              <div className="flex items-center border-b border-neutral-mid-gray cursor-pointer py-4" onClick={() => handleNavigate(`${prevPage}/favourites`)}>
                <Typography variant="title" className="!text-title">Favourites</Typography>
              </div>
              <PassengerDisclosure onClose={onClose} />
              <div className="flex flex-col items-center mt-4 gap-2">
                {passengers.length > 1 && <Button size="lg" variant="secondary" className="w-full" onClick={() => handleSetStep("accounts")}>Accounts</Button>}
                <Button size="lg" variant="primary" className="w-full" onClick={() => handleNavigate(`${prevPage}/add-booking`)}>Add Booking</Button>
                {ability.can("manage", "providerClient") && <Button size="lg" variant="secondary" startIcon="Setting2" className="w-full" onClick={() => handleNavigate(`${prevPage}/admin`)}>Admin</Button>}
              </div>
              <Button size="lg" variant="tertiary" className="mt-6" onClick={() => handleSetStep("invite-account-manager")} endIcon={(
                <Tooltip content="This feature lets your registered executive assistant manage your account with full access. You can revoke access anytime." placement="bottom" maxWidth={394}>
                  <Icon name="InfoCircle" variant="Bold" size="md" className="rotate-180 text-neutral-dark-gray" />
                </Tooltip>
              )}>Invite Account Manager</Button>
            </div>
          </div>
        </div>
      </nav>
    </SidePanel>
  );
};
