import { useMemo, useState } from "react";
import { DataTable, DataTableSetSorting, DataTableSorting, Pagination, NoFilterResults, NoSearchResults } from "@/components/molecules";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useOperator, usePagination } from "@/hooks";
import { useToggleFleetLeaderMutation } from "@/redux/apis/driver/driverApi";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { toggleIsFleetLeader } from "@/redux/slices/driver/driverSlice";
import { fleetDriversPageSelector } from "@/redux/slices/driver/selectors";
import { FleetDriver } from "@/redux/slices/driver/types";
import { addToast, clsx } from "@/utils";
import { generateFleetData, generateFleetColumns } from "../helpers";

interface FleetTableProps extends React.HTMLAttributes<HTMLDivElement> {
  items: FleetDriver[];
  sorting: DataTableSorting;
  setSorting: DataTableSetSorting;
  isSearchActive: boolean;
}

export const FleetTable = ({ items, sorting, setSorting, isSearchActive, className, ...props }: FleetTableProps) => {
  const dispatch = useAppDispatch();
  const operator = useOperator();
  const [toggle, { isLoading: isLoadingToggleFleetLeader }] = useToggleFleetLeaderMutation();
  const [loadingFleetDriver, setLoadingFleetDriver] = useState("");

  const toggleFleetLeader = (id: string, isFleetLeader: boolean) => {
    setLoadingFleetDriver(id);
    toggle({ id, isFleetLeader })
      .unwrap()
      .then(() => {
        dispatch(toggleIsFleetLeader({ id, isFleetLeader }));
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  };

  const data = useMemo(
    () => generateFleetData(items, loadingFleetDriver, isLoadingToggleFleetLeader, toggleFleetLeader, operator?.name),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items]
  );

  const columns = useMemo(() => generateFleetColumns(), []);

  return (
    <div className={clsx("relative flex", className)}>
      <DataTable
        className="[&_table]:table-auto overflow-x-auto w-full"
        columns={columns}
        data={data}
        sorting={sorting}
        setSorting={setSorting}
        emptyState={isSearchActive ? <NoSearchResults /> : <NoFilterResults title="No Driver Found" />}
        {...props}
      />
    </div>
  );
};

const FleetTablePagination = () => {
  const { current, count, total, size } = useAppSelector(fleetDriversPageSelector);
  const { handlePageClick, handleSizeChange } = usePagination();

  return (
    <Pagination
      onPageClick={handlePageClick}
      onSizeChange={handleSizeChange}
      currentPage={current}
      pageSize={size}
      totalItems={total}
      pageCount={count}
    />
  );
};

FleetTable.Pagination = FleetTablePagination;