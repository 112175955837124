import { RadioGroup } from "@headlessui/react";
import { useFormContext } from "react-hook-form";
import { ErrorMessage, Icon, Tooltip, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { AddYourVehicleFormData } from "../types";

export const VehicleColorField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<AddYourVehicleFormData>();
  const colors = [
    { name: "Black", bgColor: "bg-black" },
    { name: "White", bgColor: "bg-neutral-gray" },
    { name: "Silver", bgColor: "bg-[#C0C0C0]" },
    { name: "Grey", bgColor: "bg-[#808080]" },
    { name: "Beige", bgColor: "bg-[#F5F5DC]" },
    { name: "Red", bgColor: "bg-[#FF0000]" },
    { name: "Blue", bgColor: "bg-[#0000FF]" },
    { name: "Green", bgColor: "bg-[#008000]" },
    { name: "Yellow", bgColor: "bg-[#FFD700]" },
  ];

  const vehicleColor = watch("vehicleColor");

  return (
    <div className={clsx("flex flex-col gap-y-1", className)} {...props}>
      <Typography variant="paragraph">Vehicle Color</Typography>
      <RadioGroup value={vehicleColor} onChange={(value) => setValue("vehicleColor", value, { shouldDirty: true })}>
        <div className="mt-2 grid grid-cols-5 gap-2.5 md:grid-cols-7">
          {colors.map((color) => (
            <Tooltip key={color.name} content={color.name} placement="top">
              <RadioGroup.Option
                value={color.name}
                className={({ checked }) =>
                  clsx(
                    "relative flex h-14 w-14 cursor-pointer items-center justify-center rounded-full p-1.5 focus:outline-none focus:ring-0",
                    { "border border-black": checked }
                  )
                }
              >
                <RadioGroup.Label as="span" className="sr-only">
                  {color.name}
                </RadioGroup.Label>
                <span aria-hidden="true" className={clsx(color.bgColor, "h-11 w-11 flex-shrink-0 rounded-full")} />
                <Icon isCustom name="check" size="sm" className={clsx("absolute", { hidden: vehicleColor !== color.name })} />
              </RadioGroup.Option>
            </Tooltip>
          ))}
        </div>
      </RadioGroup>
      <ErrorMessage errors={errors} name="vehicleColor" />
    </div>
  );
};
