import { Icon, Tooltip, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { statusStyles } from "../../MyVehicles/fixtures";
import { VehicleRegistrationStatus } from "../../MyVehicles/types";

interface VehicleDetailCardProps {
  icon: JSX.Element;
  title: string | JSX.Element;
  description: string;
  action?: JSX.Element;
  actionClick?: () => void;
  tooltip?: JSX.Element;
  status?: VehicleRegistrationStatus;
}

export const VehicleDetailCard = ({ icon, title, description, action, actionClick, tooltip, status }: VehicleDetailCardProps) => {
  const renderContent = () => {
    return (
      <>
        <div className="shink-0 flex items-center">{icon}</div>
        <div className="flex flex-1 flex-col">
          <Typography variant="action" className="flex items-center gap-2 capitalize text-neutral-black">
            {title}
            {!status && tooltip && (
              <Tooltip content={tooltip} placement="top" interactive>
                <Icon name="InfoCircle" variant="Bold" size="sm" className="rotate-180 text-neutral-dark-gray" />
              </Tooltip>
            )}
          </Typography>
          <Typography variant="paragraph" className="flex items-center gap-2 leading-tight text-neutral-dark-gray">
            {description}
          </Typography>
        </div>
        <div className="flex shrink-0 items-center empty:hidden">{action}</div>
      </>
    );
  };

  if (status && tooltip) {
    return (
      <Tooltip content={tooltip} placement="top">
        <div
          className={clsx(
            "flex h-[85px] items-center gap-4 rounded-lg border border-neutral-surface-gray bg-neutral-surface-gray p-5",
            actionClick && "cursor-pointer hover:bg-neutral-gray",
            status && statusStyles[status]
          )}
        >
          {renderContent()}
        </div>
      </Tooltip>
    );
  }

  return (
    <div
      onClick={actionClick}
      className={clsx(
        "flex h-[85px] items-center gap-4 rounded-lg border border-neutral-surface-gray bg-neutral-surface-gray  p-5",
        actionClick && "cursor-pointer hover:bg-neutral-gray",
        status && statusStyles[status]
      )}
    >
      {renderContent()}
    </div>
  );
};
