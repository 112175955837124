import { useMemo } from "react";
import { DataTable, DataTableSetSorting, DataTableSorting, NoFilterResults, NoSearchResults } from "@/components/molecules";
import { OffloadDriver } from "@/redux/slices/driver/types";
import { clsx } from "@/utils";
import { generateBannedOffloadData, generateBannedOffloadColumns } from "../helpers";

interface BannedOffloadTableProps extends React.HTMLAttributes<HTMLDivElement> {
  items: OffloadDriver[];
  sorting: DataTableSorting;
  setSorting: DataTableSetSorting;
  onViewReason: (driver: OffloadDriver) => void;
  isSearchActive: boolean;
}

export const BannedOffloadTable = ({
  items,
  sorting,
  setSorting,
  onViewReason,
  isSearchActive,
  className,
  ...props
}: BannedOffloadTableProps) => {
  const data = useMemo(
    () => generateBannedOffloadData(items, onViewReason),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items]
  );
  const columns = useMemo(() => generateBannedOffloadColumns(), []);

  return (
    <div className={clsx("relative flex", className)}>
      <DataTable
        className="[&_table]:table-auto overflow-x-auto w-full"
        columns={columns}
        data={data}
        sorting={sorting}
        setSorting={setSorting}
        emptyState={isSearchActive ? <NoSearchResults /> : <NoFilterResults title="No Driver Found" />}
        {...props}
      />
    </div>
  );
};