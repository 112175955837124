import { ErrorMessage, Icon, IconButton, Typography } from "@/components/atoms";
import { clsx, getAssetUrl } from "@/utils";
import { useOperator } from "@/hooks";
import { SignUpDriverFormData } from "../types";
import { useFormContext } from "react-hook-form";

interface VehicleFieldProps extends React.HTMLAttributes<HTMLDivElement> {
  setOpenModal: (value: boolean) => void;
}

export const VehicleField = ({ setOpenModal, className, ...props }: VehicleFieldProps) => {
  const { name } = useOperator();
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<SignUpDriverFormData>();
  const vehicle = watch("vehicle");

  const renderVehicle = () => {
    if (vehicle) {
      const { makeAndModel, year, color } = vehicle;
      const name = `${makeAndModel.make} ${makeAndModel.model}`;
      return (
        <div className="flex flex-row bg-neutral-surface-gray border border-dashed border-neutral-mid-gray rounded-lg gap-x-4 p-5 mt-3">
          <div className="flex items-start justify-center">
            <div className="flex items-center justify-center bg-neutral-gray rounded-lg p-3.5 w-[60px] h-[60px]">
              <img src={getAssetUrl(makeAndModel.avatar)} alt={makeAndModel.model} />
            </div>
          </div>
          <div className="flex flex-1 flex-col">
            <Typography variant="title">{`${name} - ${year} (${color})`}</Typography>
            <Typography variant="paragraph" className="capitalize">{`Vehicle Category: ${makeAndModel.serviceLevel.replace("_", " ").toLowerCase()} Vehicle`}</Typography>
            <Typography variant="paragraph" className="capitalize">{`Type: ${makeAndModel.type.replace("_", " ").toLowerCase()}`}</Typography>
          </div>
          <div className="flex flex-col md:flex-row items-center justify-center gap-2">
            <IconButton variant="tertiary" iconName="Edit2" className="!text-info hover:bg-info-light" onClick={() => setOpenModal(true)} />
            <IconButton 
              variant="tertiary" 
              iconName="Trash" 
              className="!text-danger hover:bg-danger-light" 
              onClick={() => setValue("vehicle", undefined, { shouldDirty: true })} 
            />
          </div>
        </div>
      );
    }

    return (
      <div className="flex flex-row bg-neutral-surface-gray cursor-pointer border border-dashed border-neutral-mid-gray rounded-lg gap-x-4 p-5 mt-3" onClick={() => setOpenModal(true)}>
        <Icon name="Add" className="text-primary" />
        <Typography variant="action">Add your vehicle</Typography>
      </div>
    );
  };

  return (
    <div className={clsx("flex flex-col gap-y-1", className)} {...props}>
      <Typography variant="paragraph">Do you own a vehicle?</Typography>
      <Typography variant="paragraph" className="text-neutral-dark-gray">{`Add the vehicle you want to use. If not added, you'll use a vehicle from ${name}.`}</Typography>
      {renderVehicle()}
      {errors && <ErrorMessage errors={errors} name="vehicle" />}
    </div>
  );
};
