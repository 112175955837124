import { Alert } from "@/components/atoms";
import { useOperator } from "@/hooks";

export const VehicleVerificationErrorAlert = ({ errorType }: { errorType: string }) => {
  const { name: operatorName } = useOperator();

  switch (errorType) {
    // case "vehicle-exists-duplicate":
    //   return <Alert className="mt-4" type="warning" message={`Oops! Looks like this vehicle is already registered by [Name/Opt]`} />;

    case "vehicle-exists":
      return <Alert className="mt-4" type="warning" message={`Oops! Looks like this vehicle is already in ${operatorName}`} />;

    case "no-match":
      return <Alert className="mt-4" type="danger" message="Number plate not recognized, please try again." />;

    case "verification-failed":
      return <Alert className="mt-4" type="danger" message="Verification failed. Please verify the license plate and try again" />;

    default:
      return null;
  }
};
