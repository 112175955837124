import { VehicleDocument } from "@/redux/slices/vehicle/types";

export const getErrorMessage = (document: VehicleDocument) => {
  switch (document.approvalStatus) {
    case "UNDER_REVIEW":
      return "This file is pending a manual review";
    case "REJECTED":
      return "File couldn’t be verified automatically. Please manually enter the date and confirm that you are responsible for the entered";
    case "REJECTED_FINAL":
      return `This file has been rejected due to reason: ${document.approvalStatusReason}`;
    case "ERROR_RETRY_LATER":
      return "There was an error processing this file. Please try again later";
    default:
      return null;
  }
};

export const getIsManualExpiryDate = (document: VehicleDocument) => {
  switch (true) {
    case document.approvalStatus === "REJECTED":
    case document.approvalStatus === "APPROVED" && document.approvalStatusReason === "MISSING_DATA":
      return true;

    case document.approvalStatus === "REJECTED_FINAL":
    case document.approvalStatus === "ERROR_RETRY_LATER":
    default:
      return false;
  }
};
