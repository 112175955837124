import { isEmpty } from "ramda";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Panel, Typography, Modal, Skeleton } from "@/components/atoms";
import { Pagination, EmptyState } from "@/components/molecules";
import { AddTransferTypeForm, EditTransferTypeForm, PageHeader, TransferTypesTableEditor } from "@/components/organisms";
import { usePagination } from "@/hooks/usePagination";
import { useLazyGetTransferTypesQuery } from "@/redux/apis/config/pricing/transferTypes";
import { useAppSelector } from "@/redux/hooks";
import { transferTypesPageSelector, transferTypesSelector } from "@/redux/slices/pricing/selectors";
import { TransferTypeItem } from "@/redux/slices/pricing/types";
import { addNotification } from "@/utils";
import { addToast } from "@/utils/addToast";

export const TransferTypesEdit = () => {
  const items = useAppSelector(transferTypesSelector);
  const { current, count, total, size } = useAppSelector(transferTypesPageSelector);
  const navigate = useNavigate();
  const [step, setStep] = useState<"add" | "edit" | undefined>(undefined);
  const [editItem, setEditItem] = useState<TransferTypeItem | undefined>(undefined);
  const [getTransferTypes, { isFetching }] = useLazyGetTransferTypesQuery();
  const { page, pageSize, handlePageClick, handleSizeChange } = usePagination();

  useEffect(() => {
    getTransferTypes({ page, pageSize })
      .unwrap()
      .catch((e) => {
        const message = e.data?.detail;
        addToast("danger", message ? message : "Something went wrong.");
      });
  }, [page, pageSize, getTransferTypes]);

  useEffect(() => {
    setStep(editItem ? "edit" : undefined);
  }, [editItem]);

  if (isFetching) return <Skeleton />;

  return (
    <>
      <PageHeader.Actions />
      <Panel>
        <div className="flex flex-col gap-3 sm:flex-row sm:items-center">
          <div className="flex-1">
            <Typography variant="h3" className="leading-8">
              Transfer Type (Multiplier)
            </Typography>
            <Typography className="text-neutral-dark-gray">Configure the Transfer Type Multiplier for each trip.</Typography>
          </div>
          <div className="flex items-center gap-4">
            <Button onClick={() => setStep("add")} variant="tertiary" startIcon="Add">
              Add New
            </Button>
            <Button onClick={() => navigate("../")}>Done</Button>
          </div>
        </div>
        {isEmpty(items) ? (
          <EmptyState
            title="Transfer Type"
            description="It looks like you haven't added any transfer types yet. Begin by adding your first transfer type to get started"
          >
            <Button onClick={() => setStep("add")} startIcon="Add" variant="tertiary">
              Add New
            </Button>
          </EmptyState>
        ) : (
          <TransferTypesTableEditor className="mt-4" items={items} onEditItem={(item: TransferTypeItem) => setEditItem(item)} />
        )}
        <Modal className="max-h-[80vh] w-[515px]" open={step === "add"} onClose={() => setStep(undefined)}>
          <AddTransferTypeForm
            onClose={() => setStep(undefined)}
            onSuccess={() => {
              addToast("success", "Successfully added new transfer type.");
              setStep(undefined);
            }}
          />
        </Modal>
        {editItem && (
          <Modal className="max-h-[80vh] w-[515px]" open={step === "edit"} onClose={() => setEditItem(undefined)}>
            <EditTransferTypeForm
              item={editItem}
              onClose={() => setEditItem(undefined)}
              onSuccess={() => {
                addNotification("info", `Updated ${editItem?.name}`, `Successfully updated transfer type.`);
                setEditItem(undefined);
              }}
            />
          </Modal>
        )}
      </Panel>
      <Pagination
        onPageClick={handlePageClick}
        onSizeChange={handleSizeChange}
        currentPage={current}
        pageSize={size}
        totalItems={total}
        pageCount={count}
      />
    </>
  );
};
