import { useNavigate } from "react-router-dom";
import { Avatar, Divider, Icon, Typography } from "@/components/atoms";
import { usePage } from "@/hooks";
import { useAppDispatch, useAppResetApi, useAppSelector } from "@/redux/hooks";
import { logOut } from "@/redux/slices/auth/authSlice";
import { activePassengerSelector } from "@/redux/slices/passenger/selectors";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/react";
import { renderPassengerDisplayName } from "./helpers";
import { getAssetUrl } from "@/utils";

interface PassengerDisclosureProps {
  onClose: () => void;
}

export const PassengerDisclosure = ({ onClose }: PassengerDisclosureProps) => {
  const navigate = useNavigate();  
  const { pathname } = usePage();
  const prevPage = pathname.substring(0, pathname.lastIndexOf('/'));

  const resetApi = useAppResetApi();
  const dispatch = useAppDispatch();

  const activePassenger = useAppSelector(activePassengerSelector);

  const handleLogout = () => {
    dispatch(logOut());
    resetApi();
  };

  const handleNavigate = (path: string) => {
    navigate(path);
    onClose();
  };

  return (
    <>
      <Disclosure as="div">
        {({ open }) => (
          <>
            <DisclosureButton className="flex items-center w-full cursor-pointer py-4">
              <div className="flex flex-1 flex-row items-center gap-3 overflow-hidden">
                <Avatar
                  size="xs" 
                  src={getAssetUrl(activePassenger.avatar || "")} 
                  alt={`${activePassenger.firstName} ${activePassenger.lastName}`}
                />
                <Typography variant="title" className="!text-title !truncate">{renderPassengerDisplayName(activePassenger, "Account")}</Typography>
              </div>
              {open ? <Icon name="ArrowUp2" variant="Bold" /> : <Icon name="ArrowDown2" variant="Bold" />}
            </DisclosureButton>
            <DisclosurePanel className="mb-4">
              <div className="pl-9 pr-2 py-2 cursor-pointer" onClick={() => handleNavigate(`${prevPage}/my-profile`)}>
                <Typography variant="action">{renderPassengerDisplayName(activePassenger, "Profile")}</Typography>
              </div>
              <div className="pl-9 pr-2 py-2 cursor-pointer" onClick={() => handleNavigate(`${prevPage}/company`)}>
                <Typography variant="action">Company</Typography>
              </div>
              <div className="pl-9 pr-2 py-2 cursor-pointer" onClick={() => handleNavigate(`${prevPage}/settings`)}>
                <Typography variant="action">Settings</Typography>
              </div>
              <div className="pl-9 pr-2 py-2 cursor-pointer" onClick={() => handleNavigate(`${prevPage}/account-access`)}>
                <Typography variant="action">Account Access</Typography>
              </div>
              <div className="pl-9 pr-2 py-2 cursor-pointer" onClick={() => handleNavigate(`${prevPage}/notifications`)}>
                <Typography variant="action">Notifications</Typography>
              </div>
              <div className="pl-9 pr-2 py-2 cursor-pointer" onClick={() => handleNavigate(`${prevPage}/preferences`)}>
                <Typography variant="action">Preferences</Typography>
              </div>
              <div className="pl-9 pr-2 py-2 cursor-pointer" onClick={() => handleNavigate(`${prevPage}/receipts`)}>
                <Typography variant="action">Receipts</Typography>
              </div>
              <div className="pl-9 pr-2 py-2 cursor-pointer" onClick={() => handleNavigate(`${prevPage}/payment-information`)}>
                <Typography variant="action">Payment Information</Typography>
              </div>
              {activePassenger.isPrimary && (
                <div className="pl-9 pr-2 py-2 cursor-pointer" onClick={handleLogout}>
                  <Typography variant="action">Logout</Typography>
                </div>
              )}
            </DisclosurePanel>
          </>
        )}
      </Disclosure>
      <Divider />
    </>
  );
};
