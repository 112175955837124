import { isEmpty } from "ramda";
import { useEffect, useState } from "react";
import { Button, Panel, Toggle, Typography, Modal, Skeleton, SearchInput } from "@/components/atoms";
import { EmptyState } from "@/components/molecules";
import { BanDriverForm, BannedOffloadTable, OffloadFilterPanel, OffloadTable, UnbanDriverForm } from "@/components/organisms/Drivers";
import { usePagination } from "@/hooks/usePagination";
import { useGetOffloadDriversQuery } from "@/redux/apis/driver/driverApi";
import { useAppSelector } from "@/redux/hooks";
import { offloadDriversSelector } from "@/redux/slices/driver/selectors";
import { OffloadDriver } from "@/redux/slices/driver/types";
import { useGetOffloadDriversParams } from "./hooks/useGetOffloadDriversParams";

export const Offload = () => {
  const drivers = useAppSelector(offloadDriversSelector);
  const { clearPagination } = usePagination();
  const { params, sorting, setSorting, search, setSearch, filter, setFilter, showBlocked, setShowBlocked } = useGetOffloadDriversParams();
  const [openFilter, setOpenFilter] = useState(false);
  const { isFetching: isFetchingDrivers, refetch } = useGetOffloadDriversQuery(params, {
    refetchOnMountOrArgChange: true,
  });
  const [driverToBan, setDriverToBan] = useState<OffloadDriver | undefined>(undefined);
  const [driverToUnban, setDriverToUnban] = useState<OffloadDriver | undefined>(undefined);
  const [step, setStep] = useState<"ban" | "unban" | undefined>(undefined);

  useEffect(() => {
    driverToBan ? setStep("ban") : setStep(undefined);
  }, [driverToBan]);

  useEffect(() => {
    driverToUnban ? setStep("unban") : setStep(undefined);
  }, [driverToUnban]);

  const handleSearch = (value?: string) => {
    clearPagination();
    setSearch(value);
  };

  const toggleShowBannedDrivers = (checked: boolean) => {
    clearPagination();
    setShowBlocked(checked || undefined);
  };

  const renderModals = () => {
    return (
      <>
        <Modal open={step === "ban"} onClose={() => setDriverToBan(undefined)}>
          <BanDriverForm driver={driverToBan} onClose={() => setDriverToBan(undefined)} refetchDrivers={() => refetch()} />
        </Modal>
        <Modal open={step === "unban"} onClose={() => setDriverToUnban(undefined)}>
          <UnbanDriverForm driver={driverToUnban} onClose={() => setDriverToUnban(undefined)} refetchDrivers={() => refetch()} />
        </Modal>
      </>
    );
  };

  const isSearchActive = Boolean(search?.length);
  const numActiveFilters = Object.keys(filter).length;

  return (
    <div className="mb-12 flex flex-1 flex-col">
      <Panel>
        <Typography variant="h3" className="leading-8">
          Offload Drivers
        </Typography>
        <Typography variant="paragraph" className="mt-1 text-neutral-dark-gray">
          List of drivers that you have offloaded to in the past
        </Typography>
        {drivers.length < 1 && !isSearchActive && !showBlocked && isEmpty(filter) && !isFetchingDrivers ? (
          <EmptyState
            title="Offload Drivers"
            description="There are currently no offload drivers available. Network drivers you've assigned tasks to will appear here once they've completed the job"
          />
        ) : (
          <>
            <div className="flex flex-col gap-3 mt-4 sm:flex-row sm:items-center">
              <div className="flex flex-1 items-center gap-3">
                <SearchInput
                  isLoading={isSearchActive && isFetchingDrivers}
                  search={search}
                  onSearch={handleSearch}
                  placeholder="Search Driver"
                  size="md"
                />
                <Button variant="secondary" startIcon="Sort" onClick={() => setOpenFilter(true)}>
                  Filters
                </Button>
                {numActiveFilters > 0 && (
                  <Button
                    variant="tertiary"
                    startIcon="close"
                    className="!px-3 text-primary-dark"
                    isCustomIcon
                    onClick={() => setFilter({})}
                  >
                    Clear Filters
                  </Button>
                )}
              </div>
              <div className="flex items-center gap-[18px]">
                <Toggle checked={Boolean(showBlocked)} onChange={toggleShowBannedDrivers} />
                <Typography variant="paragraph">Show Banned Drivers</Typography>
              </div>
            </div>
            {isFetchingDrivers ? (
              <Skeleton />
            ) : showBlocked ? (
              <BannedOffloadTable
                items={drivers}
                sorting={sorting}
                setSorting={setSorting}
                onViewReason={setDriverToUnban}
                isSearchActive={isSearchActive}
                className="mt-4"
              />
            ) : (
              <OffloadTable
                items={drivers}
                sorting={sorting}
                setSorting={setSorting}
                onBanDriver={setDriverToBan}
                isSearchActive={isSearchActive}
                className="mt-4"
              />
            )}
          </>
        )}
      </Panel>
      {!isFetchingDrivers && <OffloadTable.Pagination />}
      <OffloadFilterPanel open={openFilter} onClose={() => setOpenFilter(false)} filter={filter} setFilter={setFilter} />
      {renderModals()}
    </div>
  );
};