import { useEffect, useState } from "react";
import { Button, Icon, Panel, Skeleton, Typography } from "@/components/atoms";
import { EmptyState, Pagination } from "@/components/molecules";
import { usePagination } from "@/hooks";
import { useLazyGetNetworkQuery } from "@/redux/apis/network/networkApi";
import { useAppSelector } from "@/redux/hooks";
import { networkPageSelector, networkSelector } from "@/redux/slices/network/selectors";
import { NetworkItem } from "@/redux/slices/network/types";
import { addToast } from "@/utils";
import { PrivateNetworkModals } from "./PrivateNetworkModals";
import { PrivateNetworkTable } from "./tables/PrivateNetworkTable";
import { PrivateNetworkStep } from "./types";

export const PrivateNetworkPanel = () => {
  const items = useAppSelector(networkSelector);
  const { current, count, total, size } = useAppSelector(networkPageSelector);
  const { page, pageSize, handlePageClick, handleSizeChange } = usePagination();
  const [getNetwork, { isFetching }] = useLazyGetNetworkQuery();
  const [pendingDisconnectRelationship, setPendingDisconnectRelationship] = useState<NetworkItem>();
  const [step, setStep] = useState<PrivateNetworkStep>(undefined);

  useEffect(() => {
    getNetwork({ page, pageSize })
      .unwrap()
      .catch((e) => {
        const message = e.data?.detail;
        addToast("danger", message ? message : "Something went wrong.");
      });
  }, [page, pageSize, getNetwork]);

  if (isFetching) return <Skeleton />;

  return (
    <section className="contents">
      <Panel className="flex flex-col">
        <header className="flex flex-col gap-3 sm:flex-row sm:items-center">
          <div className="flex-1">
            <div className="flex flex-row items-center gap-2">
              <Typography variant="h3" className="leading-8">
                Manage Private Network
              </Typography>
              <Icon
                name="InfoCircle"
                variant="Bold"
                className="flex-shrink-0 rotate-180 cursor-pointer text-neutral-dark-gray"
                onClick={() => setStep("help")}
              />
            </div>
            <Typography className="text-neutral-dark-gray">Here is the list of all your private network partners</Typography>
          </div>
          <div className="flex items-center gap-4">
            {items.length > 0 && (
              <Button onClick={() => setStep("inviteCode")} startIcon="Add" className="w-full sm:w-fit">
                Invite New
              </Button>
            )}
          </div>
        </header>

        {items.length < 1 ? (
          <EmptyState
            title="Manage Private Network"
            description="It looks like you haven't added any private network yet. Begin by inviting your first private network to get started"
          >
            <Button onClick={() => setStep("inviteCode")} startIcon="Add">
              Invite New
            </Button>
          </EmptyState>
        ) : (
          <PrivateNetworkTable className="mt-4" items={items} setPendingDisconnectRelationship={setPendingDisconnectRelationship} />
        )}
      </Panel>
      <Pagination
        totalItems={total}
        pageCount={count}
        currentPage={current}
        onPageClick={handlePageClick}
        pageSize={size}
        onSizeChange={handleSizeChange}
      />
      <PrivateNetworkModals
        pendingDisconnectRelationship={pendingDisconnectRelationship}
        setPendingDisconnectRelationship={setPendingDisconnectRelationship}
        step={step}
        setStep={setStep}
      />
    </section>
  );
};