import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithAuth } from "@/redux/baseQuery";
import { GetPassengersResponse, GetPassengersParams, InviteAccountManagerRawResponse, InviteAccountManagerParams } from "./types";
import { transformPassengers } from "./helpers";

export const passengerApi = createApi({
  reducerPath: "passengerApi",
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    getPassengers: builder.query<GetPassengersResponse, GetPassengersParams>({
      query: ({ id }) => `/v3/account-management/${id}/access?type=YOU_MANAGE`,
      transformResponse: transformPassengers,
      providesTags: ["Passengers"],
    }),
    inviteAccountManager: builder.mutation<InviteAccountManagerRawResponse, InviteAccountManagerParams>({
      query: ({ id, ...body }) => ({
        url: `/v3/account-management/${id}/access`,
        method: "POST",
        body,
      }),
    }),
  }),
  tagTypes: ["Passengers"],
});

export const { useLazyGetPassengersQuery, useInviteAccountManagerMutation } = passengerApi;
