import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithProvider } from "@/redux/baseQuery";
import { Company } from "@/redux/slices/company/types";
import { createSearchParams } from "@/utils";
import {
  transformCompanies,
  transformCompany,
  transformAssociatedClients,
  transformCompanyOptions,
  transformUninvoicedJobsCompanies,
  transformInvoicesCompanies,
  transformCompanyPaymentMethodSecret,
  transformCompanyPaymentMethodDetails,
} from "./helpers";
import {
  AddAssociatedClientParams,
  AddCompanyParams,
  GetCompaniesParams,
  GetCompaniesResponse,
  GetAssociatedClientsParams,
  GetAssociatedClientsResponse,
  RemoveAssociatedClientParams,
  GetCompanyOptionsResponse,
  UpdateCompanyParams,
  GetUninvoicedJobsCompaniesResponse,
  GetUninvoicedJobsCompaniesParams,
  GetInvoicesCompaniesResponse,
  GetInvoicesCompaniesParams,
  GetCompanyPaymentMethodSecretResponse,
  GetCompanyPaymentMethodSecretParams,
  GetCompanyPaymentMethodDetailsResponse,
  GetCompanyPaymentMethodDetailsParams,
} from "./types";

export const companyApi = createApi({
  reducerPath: "companyApi",
  baseQuery: baseQueryWithProvider("company"),
  endpoints: (builder) => ({
    getCompanies: builder.query<GetCompaniesResponse, GetCompaniesParams>({
      query: (params) => `?${createSearchParams(params).toString()}`,
      providesTags: ["Companies"],
      transformResponse: transformCompanies,
    }),
    getCompany: builder.query<Company, string>({
      query: (id) => ({
        url: `/${id}`,
      }),
      providesTags: ["Companies"],
      transformResponse: transformCompany,
    }),
    addCompany: builder.mutation<void, AddCompanyParams>({
      query: (body) => ({
        url: "",
        method: "POST",
        body,
      }),
    }),
    updateCompany: builder.mutation<void, UpdateCompanyParams>({
      query: ({ uuid, ...body }) => ({
        url: `/${uuid}`,
        method: "PUT",
        body,
      }),
    }),
    removeCompany: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Companies"],
    }),
    getAssociatedClients: builder.query<GetAssociatedClientsResponse, GetAssociatedClientsParams>({
      query: ({ companyId, ...params }) => ({
        url: `/${companyId}/account?${createSearchParams(params).toString()}`,
        method: "GET",
      }),
      providesTags: ["AssociatedClients"],
      transformResponse: transformAssociatedClients,
    }),
    addAssociatedClient: builder.mutation<void, AddAssociatedClientParams>({
      query: ({ companyId, associatedClientId }) => ({
        url: `/${companyId}/account`,
        body: { account_uuid: associatedClientId },
        method: "POST",
      }),
    }),
    removeAssociatedClient: builder.mutation<void, RemoveAssociatedClientParams>({
      query: ({ companyId, associatedClientId }) => ({
        url: `/${companyId}/account/${associatedClientId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["AssociatedClients"],
    }),
    getCompanyOptions: builder.query<GetCompanyOptionsResponse, void>({
      query: () => "/options",
      transformResponse: transformCompanyOptions,
    }),
    getUninvoicedJobsCompanies: builder.query<GetUninvoicedJobsCompaniesResponse, GetUninvoicedJobsCompaniesParams>({
      query: (params) => `?invoice_filter=has_unpaid_jobs&include_invoice_metadata=1&${createSearchParams(params).toString()}`,
      transformResponse: transformUninvoicedJobsCompanies,
      providesTags: ["UninvoicedJobsCompanies"],
    }),
    getInvoicesCompanies: builder.query<GetInvoicesCompaniesResponse, GetInvoicesCompaniesParams>({
      query: (params) => `?invoice_filter=has_invoices&include_invoice_metadata=1&${createSearchParams(params).toString()}`,
      transformResponse: transformInvoicesCompanies,
      providesTags: ["InvoicesCompanies"],
    }),
    getCompanyPaymentMethodSecret: builder.mutation<GetCompanyPaymentMethodSecretResponse, GetCompanyPaymentMethodSecretParams>({
      query: ({ id }) => ({
        url: `/${id}/payment-method`,
        method: "POST",
      }),
      transformResponse: transformCompanyPaymentMethodSecret,
    }),
    getCompanyPaymentMethodDetails: builder.query<GetCompanyPaymentMethodDetailsResponse, GetCompanyPaymentMethodDetailsParams>({
      query: ({ id, setupId }) => `/${id}/payment-method/${setupId}?type=manual_payment`,
      transformResponse: transformCompanyPaymentMethodDetails,
    }),
  }),
  tagTypes: ["Companies", "AssociatedClients", "InvoicesCompanies", "UninvoicedJobsCompanies"],
});

export const {
  useLazyGetCompanyQuery,
  useGetCompaniesQuery,
  useLazyGetCompaniesQuery,
  useRemoveCompanyMutation,
  useAddCompanyMutation,
  useGetAssociatedClientsQuery,
  useRemoveAssociatedClientMutation,
  useUpdateCompanyMutation,
  useAddAssociatedClientMutation,
  useGetCompanyOptionsQuery,
  useGetUninvoicedJobsCompaniesQuery,
  useLazyGetUninvoicedJobsCompaniesQuery,
  useGetInvoicesCompaniesQuery,
  useLazyGetInvoicesCompaniesQuery,
  useGetCompanyPaymentMethodSecretMutation,
  useLazyGetCompanyPaymentMethodDetailsQuery,
} = companyApi;
