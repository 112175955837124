import { useFormContext } from "react-hook-form";
import { ErrorMessage, TextInput, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { AddYourVehicleFormData } from "../types";

export const VehicleYearField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<AddYourVehicleFormData>();

  return (
    <div className={clsx("flex flex-col gap-y-1", className)} {...props}>
      <Typography variant="paragraph">Vehicle Year</Typography>
      <TextInput
        type="number"
        placeholder="2020"
        step="any"
        hasError={!!errors.vehicleYear}
        {...register("vehicleYear")}
      />
      <ErrorMessage errors={errors} name="vehicleYear" />
    </div>
  );
};
