import { CompaniesAddPanel, PageHeader, PageHeaderV2 } from "@/components/organisms";

export const CompaniesAdd = () => {
  return (
    <div className="flex flex-1 flex-col mb-12">
      <PageHeaderV2 skipCrumbs={1} title="Companies" />
      <PageHeader.Actions />
      <CompaniesAddPanel />
    </div>
  );
};
