import { useState } from "react";
import { BooleanParam, useQueryParam } from "use-query-params";
import { usePagination, useSearch, useSorting } from "@/hooks";
import { GetOffloadDriversParams } from "@/redux/apis/driver/types";

export const useGetOffloadDriversParams = () => {
  const { page, pageSize } = usePagination();
  const { sorting, setSorting, sortKey, direction } = useSorting();
  //TODO: strictly typed filters and implement with useQueryParam
  const [filter, setFilter] = useState({});
  const [search, setSearch] = useSearch();
  const [showBlocked, setShowBlocked] = useQueryParam("blocked", BooleanParam);

  const params: GetOffloadDriversParams = {
    page,
    page_size: pageSize,
    sort: sortKey,
    direction,
    search,
    is_blocked: typeof showBlocked === "boolean" ? Number(showBlocked) : undefined,
    ...filter,
  };

  return {
    params,
    sorting,
    setSorting,
    sortKey,
    direction,
    filter,
    setFilter,
    search,
    setSearch,
    showBlocked: showBlocked ?? undefined,
    setShowBlocked,
  };
};
