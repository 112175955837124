import { isEmpty } from "ramda";
import { Controller, useFormContext } from "react-hook-form";
import { CheckboxInput, Typography } from "@/components/atoms";
import { OffloadFilterFormData } from "../types";

interface VehicleBrandFilterProps {
  options: Array<{ name: string; value: string }>;
}

export const VehicleBrandFilter = ({ options }: VehicleBrandFilterProps) => {
  const { control, setValue, watch } = useFormContext<OffloadFilterFormData>();
  const watchVehicleBrand = watch("vehicleBrand");

  const renderSelectAll = () => {
    const watchVehicleBrandLength = watchVehicleBrand.length;
    return (
      <CheckboxInput
        id="vehicleBrandSelectAll"
        label="Select all"
        onChange={({ target }) =>
          target.checked
            ? setValue(
                "vehicleBrand",
                options.map((i) => i.value)
              )
            : setValue("vehicleBrand", [])
        }
        checked={watchVehicleBrandLength > 0 && watchVehicleBrandLength === options.length}
      />
    );
  };

  if (isEmpty(options)) return null;

  return (
    <div className="rounded-lg bg-neutral-surface-gray p-5">
      <Typography variant="paragraph">Vehicle Brand / Make</Typography>
      <Controller
        control={control}
        name="vehicleBrand"
        render={({ field }) => (
          <div className="mt-[18px] flex flex-col gap-6">
            {renderSelectAll()}
            {options.map((i) => (
              <CheckboxInput
                key={i.value}
                label={i.name}
                value={i.value}
                checked={field.value.includes(i.value)}
                onChange={({ target }) => {
                  if (target.checked) {
                    field.onChange([...field.value, target.value]);
                  } else {
                    field.onChange(field.value.filter((value) => value !== target.value));
                  }
                }}
                variant="check"
              />
            ))}
          </div>
        )}
      />
    </div>
  );
};
