import { Passenger } from "@/redux/slices/passenger/types";
import { GetPassengersRawResponse, GetPassengersResponse } from "./types";

export const transformPassengers = (res: GetPassengersRawResponse) => {
  const {
    _embedded: { access: passengers },
  } = res;

  const response: GetPassengersResponse = {
    data: {
      passengers: passengers.map(
        (i) =>
          ({
            id: i.account.uuid,
            firstName: i.account.first_name,
            lastName: i.account.last_name,
            email: i.account.email,
            avatar: i.account.avatar,rimary: false,
            isPrimary: false,
          } as Passenger)
      ),
    },
  };

  return response;
};