import { ArchivedVehiclesPanel } from "@/components/organisms";
import { ArchivedVehiclesActionMenu } from "@/components/organisms/Vehicles/ArchivedVehicles/ArchivedVehiclesActionMenu";
import { ArchivedVehiclesActions } from "@/components/organisms/Vehicles/ArchivedVehicles/ArchivedVehiclesActions";
import { ViewVehicle } from "./subpages/ViewVehicle";

export const ArchivedVehicles = () => {
  return (
    <div className="mb-12 flex flex-1 flex-col py-2">
      <ArchivedVehiclesPanel />
    </div>
  );
};

ArchivedVehicles.ActionMenu = ArchivedVehiclesActionMenu;
ArchivedVehicles.Actions = ArchivedVehiclesActions;
ArchivedVehicles.ViewVehicle = ViewVehicle;
