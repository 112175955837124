import { Icon, Tooltip, Typography } from "@/components/atoms";
import { Vehicle } from "@/redux/slices/vehicle/types";
import { clsx } from "@/utils";
import { getRegistrationStatus } from "../helpers";
import { VehicleRegistrationStatus } from "../types";

interface VehicleStatusBadgeProps {
  vehicle: Vehicle;
  status?: VehicleRegistrationStatus;
  size?: "sm" | "md" | "lg";
  className?: string;
  children?: React.ReactNode;
}

export const VehicleStatusBadge = ({ status: givenStatus, vehicle, size = "md", className, children }: VehicleStatusBadgeProps) => {
  const status = getRegistrationStatus(vehicle);
  switch (givenStatus || status) {
    case "expired":
      return (
        <Typography variant="small" className={clsx("inline-flex items-center text-danger", className)}>
          <Tooltip
            content={
              <Typography className="leading-tight">
                Vehicle registration expired.
                <br /> This vehicle is now blocked from
                <br /> accepting network jobs
              </Typography>
            }
            placement="top"
          >
            <Icon name="InfoCircle" variant="Bold" size={size} className="mr-1 text-danger" />
          </Tooltip>
          {children}
        </Typography>
      );
    case "expiring":
      return (
        <Typography variant="small" className={clsx("inline-flex items-center text-warning", className)}>
          <Tooltip content="Vehicle registration is expiring in 6 weeks" placement="top">
            <Icon name="Verify" variant="Bold" size={size} className="mr-1 text-warning" />
          </Tooltip>
          {children}
        </Typography>
      );
    case "verified":
      return (
        <Typography variant="small" className={clsx("inline-flex items-center text-info", className)}>
          <Tooltip content="Vehicle is verified. Learn about Vehicle Verification" placement="top">
            <Icon name="Verify" variant="Bold" size={size} className="mr-1 text-info" />
          </Tooltip>
          {children}
        </Typography>
      );
    case "unverified":
      return (
        <Typography variant="small" className={clsx("inline-flex items-center text-neutral-dark-gray", className)}>
          <Tooltip content="Vehicle needs to be updated" placement="top">
            <Icon name="Verify" variant="Bold" size={size} className="mr-1 text-neutral-dark-gray" />
          </Tooltip>
          {children}
        </Typography>
      );

    // case "missing-details":
    //   return (
    //     <Typography variant="small" className={clsx("inline-flex items-center text-neutral-dark-gray", className)}>
    //       <Tooltip content="Vehicle needs to be updated" placement="top">
    //         <Icon name="Verify" variant="Bold" size={size} className="mr-1 text-neutral-dark-gray" />
    //       </Tooltip>
    //       {children}
    //     </Typography>
    //   );

    default:
      return null;
  }
};
