import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithProvider } from "@/redux/baseQuery";
import { transformNetwork, transformNetworkHistory, transformNetworkItem } from "./helpers";
import {
  GetNetworkResponse,
  GetNetworkParams,
  ToggleOffloadFeeExemptionResponse,
  ToggleOffloadFeeExemptionParams,
  RawNetwork,
  GetNetworkHistoryResponse,
  GetNetworkHistoryParams,
  GetNetworkItemResponse,
} from "./types";

export const networkApi = createApi({
  reducerPath: "networkApi",
  baseQuery: baseQueryWithProvider("network"),
  endpoints: (builder) => ({
    getNetwork: builder.query<GetNetworkResponse, GetNetworkParams>({
      query: ({ page, pageSize }) => {
        let queryString = "?";
        if (page) queryString = `${queryString}&page=${page}`;
        if (pageSize) queryString = `${queryString}&page_size=${pageSize}`;
        return queryString;
      },
      providesTags: ["Network"],
      transformResponse: transformNetwork,
    }),
    toggleOffloadFeeExemption: builder.mutation<ToggleOffloadFeeExemptionResponse, ToggleOffloadFeeExemptionParams>({
      query: ({ id, paysCommission }) => ({
        url: `/${id}/action`,
        method: "POST",
        body: { pays_commission: paysCommission },
      }),
    }),
    approveInvitation: builder.mutation<RawNetwork, string>({
      query: (id) => ({
        url: `/${id}`,
        method: "PATCH",
        body: { status: "approve" },
      }),
    }),
    rejectInvitation: builder.mutation<RawNetwork, string>({
      query: (id) => ({
        url: `/${id}`,
        method: "PATCH",
        body: { status: "reject" },
      }),
    }),
    inviteNetwork: builder.mutation<RawNetwork, string>({
      query: (providerCode) => ({
        url: "",
        method: "POST",
        body: { provider_code: providerCode },
      }),
      invalidatesTags: ["Network"],
    }),
    removeNetwork: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
    }),
    getNetworkHistory: builder.query<GetNetworkHistoryResponse, GetNetworkHistoryParams>({
      query: ({ id, page, pageSize }) => {
        let queryString = `/${id}/history?`;
        if (page) queryString = `${queryString}&page=${page}`;
        if (pageSize) queryString = `${queryString}&page_size=${pageSize}`;
        return queryString;
      },
      transformResponse: transformNetworkHistory,
    }),
    getNetworkItem: builder.query<GetNetworkItemResponse, string>({
      query: (id) => `/${id}`,
      transformResponse: transformNetworkItem,
    }),
  }),
  tagTypes: ["Network"],
});

export const {
  useLazyGetNetworkQuery,
  useToggleOffloadFeeExemptionMutation,
  useInviteNetworkMutation,
  useRemoveNetworkMutation,
  useApproveInvitationMutation,
  useRejectInvitationMutation,
  useLazyGetNetworkHistoryQuery,
  useLazyGetNetworkItemQuery,
} = networkApi;
