import { useState } from "react";
import { useOperator, usePagination, useSearch, useSorting } from "@/hooks";
import { GetFleetDriversParams } from "@/redux/apis/driver/types";

export const useGetFleetDriversParams = () => {
  const { id: operatorId } = useOperator();
  const { page, pageSize } = usePagination();
  const { sorting, setSorting, sortKey, direction } = useSorting();
  //TODO: strictly typed filters and implement with useQueryParam
  const [filter, setFilter] = useState({});
  const [search, setSearch] = useSearch();

  const params: GetFleetDriversParams = {
    page,
    page_size: pageSize,
    sort: sortKey,
    direction,
    search,
    provider_uuid: operatorId,
    ...filter,
  };

  return {
    params,
    sorting,
    setSorting,
    sortKey,
    direction,
    filter,
    setFilter,
    search,
    setSearch,
    operatorId,
  };
};
