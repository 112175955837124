import { useEffect, useState } from "react";
import { Panel, Typography, Skeleton, Modal } from "@/components/atoms";
import { Pagination, EmptyState } from "@/components/molecules";
import { PageHeader } from "@/components/organisms";
import { EditRatingForm, RatingHistory, RatingsTable } from "@/components/organisms/RatingManager";
import { usePagination } from "@/hooks/usePagination";
import { useLazyGetRatingsQuery } from "@/redux/apis/rating/ratingApi";
import { useAppSelector } from "@/redux/hooks";
import { ratingsPageSelector, ratingsSelector } from "@/redux/slices/rating/selectors";
import { RatingItem } from "@/redux/slices/rating/types";
import { addToast } from "@/utils";

export const RatingManager = () => {
  const [getRatings, { isFetching }] = useLazyGetRatingsQuery();
  const items = useAppSelector(ratingsSelector);
  const { current, count, total, size } = useAppSelector(ratingsPageSelector);
  const { page, pageSize, handlePageClick, handleSizeChange } = usePagination();
  const [step, setStep] = useState<"edit" | "history" | undefined>(undefined);
  const [editItem, setEditItem] = useState<RatingItem>();
  const [historyItem, setHistoryItem] = useState<RatingItem>();

  useEffect(() => {
    getRatings({ page, pageSize })
      .unwrap()
      .catch((e) => {
        const message = e.data?.detail;
        addToast("danger", message ? message : "Something went wrong.");
      });
  }, [page, pageSize, getRatings]);

  useEffect(() => {
    setStep(editItem ? "edit" : undefined);
  }, [editItem]);

  useEffect(() => {
    setStep(historyItem ? "history" : undefined);
  }, [historyItem]);

  const renderModals = () => {
    return (
      <>
        <Modal open={step === "edit"} onClose={() => setEditItem(undefined)} className="w-full max-w-[550px]">
          <EditRatingForm
            item={editItem}
            onClose={() => setEditItem(undefined)}
            onError={(messages) => {
              messages.forEach((m) => addToast("danger", m));
            }}
            onSuccess={() => {
              addToast("success", "Successfully Updated Driver Rating");
              setEditItem(undefined);
            }}
          />
        </Modal>
        <Modal open={step === "history"} onClose={() => setHistoryItem(undefined)} className="w-full max-w-[550px]">
          <RatingHistory item={historyItem} onClose={() => setHistoryItem(undefined)} />
        </Modal>
      </>
    );
  };

  return (
    <div className="mb-12 flex flex-1 flex-col">
      <PageHeader skipCrumbs={1} title="Rating Manager" />

      <>
        {isFetching ? (
          <Skeleton />
        ) : (
          <>
            <Panel>
              <div className="flex">
                <div className="flex-1">
                  <Typography variant="h3" className="leading-8">
                    Driver Ratings
                  </Typography>
                  <Typography className="text-neutral-dark-gray">Manage the Ratings for all the Drivers</Typography>
                </div>
              </div>
              {items.length < 1 ? (
                <EmptyState title="Driver Ratings" description="There are currently no driver ratings available" />
              ) : (
                <RatingsTable className="mt-4" items={items} setEditItem={setEditItem} setHistoryItem={setHistoryItem} />
              )}
            </Panel>
            <Pagination
              totalItems={total}
              pageCount={count}
              currentPage={current}
              onPageClick={handlePageClick}
              pageSize={size}
              onSizeChange={handleSizeChange}
            />
            {renderModals()}
          </>
        )}
      </>
    </div>
  );
};
