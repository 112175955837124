import { Navigate, RouteObject } from "react-router-dom";
import { Config } from "@/components/pages";
import { RequireAccess } from "./RequireAccess/RequireAccess";

const adminRoutes: RouteObject = {
  path: "admin",
  handle: { pageName: "Admin Settings" },
  children: [
    {
      index: true,
      element: <Config.Admin />,
    },
    {
      path: ":pageSlug?",
      element: <RequireAccess action="manage" subject="providerConfigProfileAdvanced" />,
      children: [
        {
          path: "billing-settings",
          handle: { pageName: "Billing Settings" },
          children: [
            {
              index: true,
              element: <Config.Admin.BillingSettings />,
            },
            {
              path: "edit",
              element: <Config.Admin.BillingSettings.Edit />,
            },
          ],
        },
        {
          path: "access-control",
          handle: { pageName: "Access Control" },
          children: [
            {
              index: true,
              element: <Config.Admin.AccessControl />,
            },
            {
              path: "edit",
              element: <Config.Admin.AccessControl.Edit />,
            },
          ],
        },
        {
          path: "dispatch-settings",
          handle: { pageName: "Dispatch Settings" },
          children: [
            {
              index: true,
              element: <Config.Admin.DispatchSettings />,
            },
            {
              path: ":mode?/:type?",
              element: <Config.Admin.DispatchSettings />,
            },
          ],
        },
        {
          path: "waiting-times",
          handle: { pageName: "Waiting Times" },
          children: [
            {
              index: true,
              element: <Config.Admin.WaitingTimes />,
            },
            {
              path: "edit",
              element: <Config.Admin.WaitingTimes.Edit />,
            },
          ],
        },
      ],
    },
    {
      path: "business-details",
      handle: { pageName: "Business Details" },
      children: [
        {
          index: true,
          element: <Config.Admin.BusinessDetails />,
        },
        {
          path: "edit",
          element: <Config.Admin.BusinessDetails.Edit />,
        },
      ],
    },
    {
      path: "website-details",
      handle: { pageName: "Website Details" },
      children: [
        {
          index: true,
          element: <Config.Admin.WebsiteDetails />,
        },
        {
          path: "edit",
          element: <Config.Admin.WebsiteDetails.Edit />,
        },
      ],
    },
    {
      path: "email-notifications",
      handle: { pageName: "Email Notifications" },
      children: [
        {
          index: true,
          element: <Config.Admin.EmailNotifications />,
        },
        {
          path: "edit",
          element: <Config.Admin.EmailNotifications.Edit />,
        },
      ],
    },
    {
      path: "sms-voice-notifications",
      handle: { pageName: "SMS / Voice Notifications" },
      children: [
        {
          index: true,
          element: <Config.Admin.SMSVoiceNotifications />,
        },
        {
          path: "edit",
          element: <Config.Admin.SMSVoiceNotifications.Edit />,
        },
      ],
    },
    {
      path: "display-options",
      handle: { pageName: "Display Options" },
      children: [
        {
          index: true,
          element: <Config.Admin.DisplayOptions />,
        },
        {
          path: "edit",
          element: <Config.Admin.DisplayOptions.Edit />,
        },
      ],
    },
  ],
};

const pricingRoutes: RouteObject = {
  path: "pricing",
  handle: { pageName: "Pricing Settings" },
  children: [
    {
      index: true,
      element: <Config.Pricing />,
    },
    {
      path: ":pageSlug?",
      element: <RequireAccess action="manage" subject="providerConfigPricing" />,
      children: [
        {
          path: "trip-cost",
          element: <Config.Pricing.TripCost />,
          children: [
            {
              index: true,
              element: <Navigate to="dynamic-pricing" />,
            },
            {
              path: "universal-pricing",
              handle: { pageName: "Universal Pricing" },
              children: [
                {
                  index: true,
                  element: <Config.Pricing.TripCost.UniversalPricing />,
                },
                {
                  path: ":mode",
                  element: <Config.Pricing.TripCost.UniversalPricing />,
                },
              ],
            },
            {
              path: "dynamic-pricing",
              handle: { pageName: "Trip Cost" },
              children: [
                {
                  index: true,
                  element: <Config.Pricing.TripCost.DynamicPricing />,
                },
                {
                  path: "state-based",
                  handle: { pageName: "State Based Dynamic Pricing" },
                  children: [
                    {
                      index: true,
                      element: <Config.Pricing.TripCost.DynamicPricing.PricingState />,
                    },
                    {
                      path: ":slug",
                      handle: {
                        pageKey: "stateName",
                      },
                      children: [
                        {
                          index: true,
                          element: <Config.Pricing.TripCost.DynamicPricing.PricingState.StateBased />,
                        },
                        {
                          path: ":mode",
                          element: <Config.Pricing.TripCost.DynamicPricing.PricingState.StateBased />,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              path: "fixed-pricing",
              handle: { pageName: "Fixed Pricing (Trip Cost)" },
              element: <RequireAccess action="manage" subject="providerConfigPricingFixed" />,
              children: [
                {
                  index: true,
                  element: <Config.Pricing.TripCost.FixedPricing />,
                },
                {
                  path: ":id",
                  handle: {
                    pageKey: "profileName",
                    pageName: "Pricing Profile",
                  },
                  children: [
                    {
                      index: true,
                      element: <Config.Pricing.TripCost.FixedPricing.PricingProfile />,
                    },
                    {
                      path: "nationwide",
                      handle: { pageName: "Nationwide Fixed Pricing" },
                      children: [
                        {
                          index: true,
                          element: <Config.Pricing.TripCost.FixedPricing.PricingProfile.Nationwide />,
                        },
                        {
                          path: ":mode",
                          element: <Config.Pricing.TripCost.FixedPricing.PricingProfile.Nationwide />,
                        },
                      ],
                    },
                    {
                      path: "state-based",
                      handle: { pageName: "State Based Fixed Pricing" },
                      children: [
                        {
                          index: true,
                          element: <Config.Pricing.TripCost.FixedPricing.PricingProfile.PricingState />,
                        },
                        {
                          path: ":slug",
                          handle: {
                            pageKey: "stateName",
                          },
                          children: [
                            {
                              index: true,
                              element: <Config.Pricing.TripCost.FixedPricing.PricingProfile.PricingState.StateBased />,
                            },
                            {
                              path: ":mode",
                              element: <Config.Pricing.TripCost.FixedPricing.PricingProfile.PricingState.StateBased />,
                            },
                          ],
                        },
                      ],
                    },
                    {
                      path: "location-based",
                      handle: { pageName: "Location Based Fixed Pricing" },
                      children: [
                        {
                          index: true,
                          element: <Config.Pricing.TripCost.FixedPricing.PricingProfile.PricingLocation />,
                        },
                        {
                          path: "add-location",
                          handle: { pageName: "Add Location" },
                          children: [
                            {
                              index: true,
                              element: <Config.Pricing.TripCost.FixedPricing.PricingProfile.PricingLocation.AddLocation />,
                            },
                          ],
                        },
                        {
                          path: ":locationId",
                          handle: {
                            pageKey: "locationName",
                          },
                          children: [
                            {
                              index: true,
                              element: <Config.Pricing.TripCost.FixedPricing.PricingProfile.PricingLocation.LocationBased />,
                            },
                            {
                              path: ":mode?/:type?",
                              element: <Config.Pricing.TripCost.FixedPricing.PricingProfile.PricingLocation.LocationBased />,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: "fixed-cost-extras",
          handle: { pageName: "Fixed Cost Extras" },
          children: [
            {
              index: true,
              element: <Config.Pricing.FixedCostExtras />,
            },
            {
              path: "edit",
              children: [{ index: true, element: <Config.Pricing.FixedCostExtras.Edit /> }],
            },
          ],
        },

        {
          path: "time-and-day",
          handle: { pageName: "Time & Day (Multiplier)" },
          children: [
            {
              index: true,
              element: <Config.Pricing.TimeAndDay />,
            },
            {
              path: "edit",
              children: [{ index: true, element: <Config.Pricing.TimeAndDay.Edit /> }],
            },
          ],
        },
        {
          path: "transfer-types",
          handle: { pageName: "Transfer Type (Multiplier)" },
          children: [
            {
              index: true,
              element: <Config.Pricing.TransferTypes />,
            },
            {
              path: "edit",
              children: [{ index: true, element: <Config.Pricing.TransferTypes.Edit /> }],
            },
          ],
        },
        {
          path: "cancellation-fees",
          handle: { pageName: "Cancellation Fees" },
          children: [
            {
              index: true,
              element: <Config.Pricing.CancellationFees />,
            },
            {
              path: ":mode?/:type?",
              handle: { pageName: "Cancellation Fees" },
              element: <Config.Pricing.CancellationFees />,
            },
          ],
        },
        {
          path: "unscheduled-stops",
          handle: { pageName: "Unscheduled Stops" },
          children: [
            {
              index: true,
              element: <Config.Pricing.UnscheduledStops />,
            },
            {
              path: "edit",
              element: <Config.Pricing.UnscheduledStops.Edit />,
            },
          ],
        },
        {
          path: "hourly-daily-hire",
          handle: { pageName: "Hourly - Daily Hire" },
          children: [
            {
              path: "*",
              element: <Config.Pricing.HourlyDailyHire />,
              children: [
                {
                  index: true,
                  element: <Navigate to="hourly" replace />,
                },
                {
                  path: "hourly",
                  children: [
                    {
                      index: true,
                      element: <Config.Pricing.HourlyDailyHire.Hourly />,
                    },
                    {
                      path: ":mode?/:type?",
                      element: <Config.Pricing.HourlyDailyHire.Hourly />,
                    },
                  ],
                },
                {
                  path: "daily",
                  children: [
                    {
                      index: true,
                      element: <Config.Pricing.HourlyDailyHire.Daily />,
                    },
                    {
                      path: ":mode?/:type?",
                      element: <Config.Pricing.HourlyDailyHire.Daily />,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: "vehicle-categories",
      handle: { pageName: "Vehicle Categories (Multiplier)" },
      element: <RequireAccess action="manage" subject="providerConfigVehicle" />,
      children: [
        {
          index: true,
          element: <Config.Pricing.VehicleCategories />,
        },
        {
          path: "edit",
          children: [{ index: true, element: <Config.Pricing.VehicleCategories.Edit /> }],
        },
      ],
    },
  ],
};

const documentRoutes: RouteObject = {
  path: "documents",
  handle: { pageName: "Documents Links" },
  children: [
    {
      index: true,
      element: <Config.Documents />,
    },
    {
      path: "terms-and-conditions",
      handle: { pageName: "Terms and Conditions" },
      children: [
        {
          index: true,
          element: <Config.Documents.TermsAndConditions />,
        },
        {
          path: ":id",
          element: <Config.Documents.TermsAndConditions.History />,
        },
      ],
    },
  ],
};

const brandingAndAcquisitionRoutes: RouteObject = {
  path: "branding-and-acquisition",
  handle: { pageName: "Branding & Acquisition Tools" },
  children: [
    {
      index: true,
      element: <Config.BrandingAndAcquisition />,
    },
    {
      path: "client-tools",
      handle: { pageName: "Client Tools" },
      children: [
        {
          index: true,
          element: <Config.BrandingAndAcquisition.ClientTools />,
        },
        {
          path: "qr-templates",
          handle: { pageName: "Contact Printer Supplier" },
          children: [
            {
              index: true,
              element: <Config.BrandingAndAcquisition.ClientTools.SendToPrinter />,
            },
          ],
        },
      ],
    },
    {
      path: "driver-tools",
      handle: { pageName: "Driver Tools" },
      children: [
        {
          index: true,
          element: <Config.BrandingAndAcquisition.DriverTools />,
        },
      ],
    },
  ],
};

const userAccountsRoutes: RouteObject = {
  path: "user-accounts",
  element: <RequireAccess action="manage" subject="providerAccountDirectory" />,
  handle: { pageName: "User Accounts" },
  children: [
    {
      index: true,
      element: <Config.UserAccounts />,
    },
  ],
};

export const configRoutes: RouteObject = {
  path: "config",
  element: <RequireAccess action="view" subject="providerConfig" />,
  handle: { pageName: "Settings" },
  children: [
    {
      path: "*",
      element: <Config />,
      children: [
        {
          index: true,
          element: <Navigate to="admin" replace />,
        },
        adminRoutes,
        pricingRoutes,
        brandingAndAcquisitionRoutes,
        documentRoutes,
        userAccountsRoutes,
      ],
    },
  ],
};