import { Link } from "react-router-dom";
import { DropdownMenu, Icon, Tooltip, Typography } from "@/components/atoms";
import { DataTableColumnDef } from "@/components/molecules";
import { Company, AssociatedClient } from "@/redux/slices/company/types";
import { getManageAccountPath } from "../Clients/helpers";
import { AssociatedClientsTableData, CompaniesTableData } from "./types";

export const generateCompanyColumns = () => {
  const columns: DataTableColumnDef<CompaniesTableData>[] = [
    {
      accessorKey: "companyName",
      header: "Company Name",
      id: "company_name",
      cell: (props) => props.row.original.companyName,
    },
    {
      accessorKey: "clients",
      header: "Clients",
      id: "member_count",
      cell: (props) => props.row.original.clients,
    },
    {
      accessorKey: "phone",
      header: "Phone",
      cell: (props) => props.row.original.phone,
      enableSorting: false,
    },
    {
      accessorKey: "address",
      header: "Address",
      cell: (props) => props.row.original.address,
      enableSorting: false,
      size: 300,
    },
    {
      accessorKey: "keyContactName",
      header: "Key Contact Name",
      cell: (props) => props.row.original.keyContactName,
      enableSorting: false,
    },
    {
      accessorKey: "action",
      header: "Action",
      cell: (props) => props.row.original.action,
      enableSorting: false,
      size: 75,
    },
  ];

  return columns;
};

export const generateCompanyData = (
  items: Company[],
  canManage: boolean,
  onViewClick: (company: Company) => void,
  onRemoveClick: (company: Company) => void
) => {
  const data = items.map(
    (i) =>
      ({
        companyName: (
          <div className="flex">
            <Tooltip content="Go to Company Account" placement="bottom">
              <span>
                <Link to={i.id} className="hover:text-info">
                  <Typography variant="paragraph">{i.name}</Typography>
                </Link>
              </span>
            </Tooltip>
          </div>
        ),
        clients: `${i.metadata.clientCount}`,
        phone: i.phone || "--",
        address: (
          <Typography className="max-w-[100%] truncate">
            <Tooltip content={i.address} placement="bottom">
              <span>{i.address}</span>
            </Tooltip>
          </Typography>
        ),
        keyContactName: i.keyContact.name,
        action: renderCompanyActions(i, canManage, onViewClick, onRemoveClick),
      } as CompaniesTableData)
  );

  return data;
};

const renderCompanyActions = (
  company: Company,
  canManage: boolean,
  onViewClick: (company: Company) => void,
  onRemoveClick: (company: Company) => void
) => {
  return (
    <DropdownMenu button={<Icon name="options" isCustom size="lg" />} position="bottom-start" className="!flex max-w-[24px]">
      <DropdownMenu.Item onClick={() => onViewClick(company)}>
        <Icon name="ArrowRight" className="mr-2.5 text-primary-dark" size="sm" />
        <Typography className="text-primary-dark">View Company</Typography>
      </DropdownMenu.Item>
      {canManage && (
        <DropdownMenu.Item onClick={() => onRemoveClick(company)}>
          <Icon name="Trash" className="mr-2.5 text-danger" size="sm" />
          <Typography className="text-primary-dark">Remove Company</Typography>
        </DropdownMenu.Item>
      )}
    </DropdownMenu>
  );
};

export const generateAssociatedClientsColumns = () => {
  const columns: DataTableColumnDef<AssociatedClientsTableData>[] = [
    { accessorKey: "name", header: "Name", cell: (props) => props.row.original.name, id: "name" },
    {
      accessorKey: "phone",
      header: "Phone",
      cell: (props) => props.row.original.phone,
      enableSorting: false,
    },
    {
      accessorKey: "email",
      header: "Email",
      cell: (props) => props.row.original.email,
      enableSorting: false,
    },
    {
      accessorKey: "actions",
      header: "Actions",
      cell: (props) => props.row.original.actions,
      size: 75,
      enableSorting: false,
    },
  ];

  return columns;
};

export const generateAssociatedClientsData = (
  items: AssociatedClient[],
  disassociateClient: (associatedClient: AssociatedClient) => void
) => {
  const data = items.map(
    (i) =>
      ({
        name: i.name || "-",
        phone: i.phone || "-",
        email: i.email || "-",
        actions: renderAssociatedClientsActions(i, disassociateClient),
      } as AssociatedClientsTableData)
  );

  return data;
};

const renderAssociatedClientsActions = (
  associatedClient: AssociatedClient,
  disassociateClient: (associatedClient: AssociatedClient) => void
) => {
  return (
    <DropdownMenu button={<Icon name="options" isCustom size="lg" />} position="bottom-start" className="!flex max-w-[24px]">
      <Link to={getManageAccountPath(associatedClient.id)} target="_blank">
        <DropdownMenu.Item>
          <Icon name="User" size="sm" className="mr-2.5" />
          <Typography variant="paragraph">Manage Account</Typography>
        </DropdownMenu.Item>
      </Link>
      <DropdownMenu.Item onClick={() => disassociateClient(associatedClient)}>
        <Icon name="close" isCustom size="sm" className="mr-2.5 text-danger" />
        <Typography variant="paragraph">Disassociate Clients</Typography>
      </DropdownMenu.Item>
    </DropdownMenu>
  );
};
