import { useParams } from "react-router-dom";
import { CompaniesEditPanel, PageHeader, PageHeaderV2 } from "@/components/organisms";
import { Restricted } from "..";

export const CompaniesEdit = () => {
  const { id } = useParams<{ id: string }>();

  if (!id) return <Restricted />;

  return (
    <div className="mb-12 flex flex-1 flex-col">
      <PageHeaderV2 skipCrumbs={1} title="Companies" />
      <PageHeader.Actions />
      <CompaniesEditPanel companyId={id} />
    </div>
  );
};
