import { invalidateOn } from "@/helpers/reduxHelpers";
import { pricingApi } from "../pricingApi";
import { transformTransferTypes } from "./helpers";
import {
  AddTransferTypeParams,
  AddTransferTypeResponse,
  DeleteTransferTypeResponse,
  EditTransferTypeParams,
  EditTransferTypeResponse,
  GetTransferTypeParams,
  GetTransferTypesResponse,
  RawTransferTypeItem,
  ReorderTransferTypesResponse,
  ToggleTransferTypeResponse,
} from "./types";

export const transferTypesApi = pricingApi.enhanceEndpoints({ addTagTypes: ["TransferTypes"] }).injectEndpoints({
  endpoints: (builder) => ({
    getTransferTypes: builder.query<GetTransferTypesResponse, GetTransferTypeParams>({
      query: ({ page, pageSize }) => {
        let queryString = "/transfer-type?";
        if (page) queryString = `${queryString}&page=${page}`;
        if (pageSize) queryString = `${queryString}&page_size=${pageSize}`;
        return queryString;
      },
      providesTags: ["TransferTypes"],
      transformResponse: transformTransferTypes,
    }),
    addTransferType: builder.mutation<AddTransferTypeResponse, AddTransferTypeParams>({
      query: (body) => ({
        url: "/transfer-type",
        method: "POST",
        body,
      }),
      invalidatesTags: invalidateOn({ success: ["TransferTypes"] }),
    }),
    editTransferType: builder.mutation<EditTransferTypeResponse, EditTransferTypeParams>({
      query: ({ uuid, ...put }) => ({
        url: `/transfer-type/${uuid}`,
        method: "PUT",
        body: put,
      }),
      invalidatesTags: invalidateOn({ success: ["TransferTypes"] }),
    }),
    deleteTransferType: builder.mutation<DeleteTransferTypeResponse, string>({
      query: (uuid) => ({
        url: `/transfer-type/${uuid}`,
        method: "DELETE",
      }),
      invalidatesTags: ["TransferTypes"],
    }),
    toggleTransferType: builder.mutation<ToggleTransferTypeResponse, Pick<RawTransferTypeItem, "uuid"> & { is_active: boolean }>({
      query: ({ uuid, ...body }) => ({
        url: `/transfer-type/${uuid}`,
        method: "PATCH",
        body,
      }),
    }),
    reorderTransferType: builder.mutation<ReorderTransferTypesResponse, Record<string, number>>({
      query: (body) => ({
        url: "/transfer-type/reorder",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useLazyGetTransferTypesQuery,
  useAddTransferTypeMutation,
  useToggleTransferTypeMutation,
  useDeleteTransferTypeMutation,
  useEditTransferTypeMutation,
  useReorderTransferTypeMutation,
} = transferTypesApi;
