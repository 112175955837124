import { useDebouncedEffect } from "@react-hookz/web";
import { useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Autocomplete, DropdownSelectOption, ErrorMessage, Typography } from "@/components/atoms";
import { useLazySearchVehicleModelQuery } from "@/redux/apis/vehicleGuest/vehicleGuestApi";
import { clsx } from "@/utils";
import { AddYourVehicleFormData } from "../types";

export const MakeAndModelField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<AddYourVehicleFormData>();
  const [searchVehicleModel, { data, isFetching }] = useLazySearchVehicleModelQuery();
  const vehicleModels = useMemo(() => data?.models ?? [], [data]);
  const vehicleMakeAndModel = watch("vehicleMakeAndModel");
  const [query, setQuery] = useState("");

  useDebouncedEffect(
    () => {
      if (vehicleMakeAndModel) {
        if (query.length > 2 && query !== `${vehicleMakeAndModel.make} ${vehicleMakeAndModel.model}`) searchVehicleModel({ q: query });
      } else {
        if (query.length > 2) searchVehicleModel({ q: query });
      }
    },
    [query, searchVehicleModel],
    500
  );

  const options: DropdownSelectOption[] = vehicleModels.map((model) => ({
    value: model.id,
    name: `${model.make} ${model.model}`,
  }));

  const handleOnChange = (option: DropdownSelectOption | null) => {
    if (option?.value) {
      const value = vehicleModels.find((model) => model.id === option.value);
      if (value) setValue("vehicleMakeAndModel", value, { shouldDirty: true });
    } else {
      // @ts-expect-error description: Set to undefined to reset the field without making it optional in the validation schema
      setValue("vehicleMakeAndModel", undefined, { shouldDirty: true });
    }
  };

  return (
    <div className={clsx("flex flex-col gap-y-1", className)} {...props}>
      <Typography variant="paragraph">Make & Model</Typography>
      <Autocomplete
        placeholder="Enter Make & Model"
        options={options}
        isLoading={isFetching}
        value={options.find((i) => i.value === vehicleMakeAndModel?.id) ?? null}
        onValueChange={handleOnChange}
        onQueryChange={setQuery}
        hasError={!!errors.vehicleMakeAndModel}
      />
      <Typography variant="small" className="capitalize text-neutral-dark-gray">
        {vehicleMakeAndModel && `Vehicle Category: ${vehicleMakeAndModel.serviceLevel.replace("_", " ").toLowerCase()}`}
      </Typography>
      <ErrorMessage errors={errors} name="vehicleMakeAndModel" />
    </div>
  );
};
