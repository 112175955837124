import { Account, AccountProfile } from "@/redux/slices/account/types";
import { GetAccountProfileRawResponse, GetAccountProfileResponse, GetAccountsRawResponse, GetAccountsResponse } from "./types";

export const transformAccounts = (res: GetAccountsRawResponse) => {
  const rawAccounts = res._embedded.accounts;

  const accounts = rawAccounts.map((a) => {
    const {
      account_uuid,
      primary,
      _embedded: { profile },
    } = a;

    return {
      id: account_uuid,
      primary,
      profile: {
        accountId: profile.account_uuid,
        title: profile.title,
        firstName: profile.first_name,
        lastName: profile.last_name,
        email: profile.email,
        notificationEmail: profile.notification_email,
        mobile: profile.mobile,
        avatar: profile.avatar,
        dateFormat: profile.date_format,
        timeFormat: profile.time_format,
        timezone: profile.timezone,
        emailVerified: profile.metadata.email_verified,
        mobileVerified: profile.metadata.mobile_verified,
        passwordChangeRequired: profile.metadata.password_change_required,
      },
    } as Account;
  });

  const response: GetAccountsResponse = {
    data: {
      accounts,
    },
    links: res._links,
  };

  return response;
};

export const transformAccountProfile = (res: GetAccountProfileRawResponse) => {
  const response: GetAccountProfileResponse = {
    data: {
      accountId: res.account_uuid,
      title: res.title,
      firstName: res.first_name,
      lastName: res.last_name,
      email: res.email,
      notificationEmail: res.notification_email,
      mobile: res.mobile,
      avatar: res.avatar,
      dateFormat: res.date_format,
      timeFormat: res.time_format,
      timezone: res.timezone,
      emailVerified: res.metadata.email_verified,
      mobileVerified: res.metadata.mobile_verified,
      passwordChangeRequired: res.metadata.password_change_required,
    } as AccountProfile,
  };

  return response;
};
