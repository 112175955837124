import { addMinutes } from "date-fns";
import { format, formatInTimeZone } from "date-fns-tz";
import { ComponentProps, useMemo, useState } from "react";
import { IconButton, Typography, SidePanel, Loading, Icon, Skeleton, LinkButton, Tooltip } from "@/components/atoms";
import {
  BookingMapState,
  BookingMarkerState,
  BookingTripMap,
  extractMarkers,
  extractPolylines,
  extractTripPoints, // showBookingETA,
  useBookingETA,
  useBookingTripPaths,
} from "@/components/organisms";
// import commencedJob from "@/components/organisms/BookingTripMap/mocks/commenced-2.json";
// import completedJob from "@/components/organisms/BookingTripMap/mocks/complete-awaiting-review-2.json";
// import driverOnWayJob from "@/components/organisms/BookingTripMap/mocks/driver-on-way-2.json";
import { getTripLogGuestViewUrl } from "@/helpers/bookingHelpers";
import { useProfile } from "@/hooks";
import { useGetBookingTripLogsQuery } from "@/redux/apis/booking/bookingApi";
// import { useGetBookingTripLogsQuery } from "@/redux/apis/booking/bookingApi";
// import { transformGuestBookingTripLog } from "@/redux/apis/guest/helpers";
// import { RawGuestBookingTripLog } from "@/redux/apis/guest/types";
import { BookingTripLog } from "@/redux/slices/booking/types";
import { clsx } from "@/utils";
import { BookingStatusIcon } from "../../common/BookingStatusIcon/BookingStatusIcon";
import { allStatuses } from "../../fixtures";
import { useSelectedBooking } from "../../hooks/useSelectedBooking";

interface TripActivityProps {
  open: boolean;
  onClose: () => void;
}

export const TripActivity = ({ open, onClose }: TripActivityProps) => {
  const { id: bookingId, trackingHash, status } = useSelectedBooking();
  const { data: bookingTrip } = useGetBookingTripLogsQuery(bookingId, { skip: !open });
  // const bookingTrip = transformGuestBookingTripLog(commencedJob as unknown as RawGuestBookingTripLog);
  const [markerState, setMarkerState] = useState<BookingMarkerState>({});

  const shareUrl = getTripLogGuestViewUrl(bookingId, trackingHash);
  const showShareUrl = status !== "complete_awaiting_review" && status !== "closed";

  return (
    <SidePanel open={open} onClose={onClose} width={800} focused>
      <section className="flex items-center justify-between p-5">
        <div className="flex items-center gap-2">
          <Icon name="Routing" size="lg" />
          <Typography variant="title">Trip Driver Activity (J{bookingId})</Typography>
        </div>
        <div className="ml-3 flex h-7 items-center">
          <IconButton iconName="ArrowRight" onClick={onClose} className="-mr-2" variant="custom" />
        </div>
      </section>
      <div className="flex h-full w-full flex-col overflow-y-auto p-5">
        <div className="flex-1 space-y-6 pb-24">
          {bookingTrip ? <TripBasicDetails bookingTrip={bookingTrip} /> : <Loading />}
          {bookingTrip ? (
            <div className="h-[645px] overflow-hidden rounded-lg border border-neutral-mid-gray">
              <TripMapWrapper bookingTrip={bookingTrip} markerState={markerState} setMarkerState={setMarkerState} />
            </div>
          ) : (
            <Loading />
          )}
          {bookingTrip ? (
            <TripBookingDetails bookingTrip={bookingTrip} markerState={markerState} setMarkerState={setMarkerState} />
          ) : (
            <Skeleton />
          )}
          {bookingTrip ? (
            <TripMarkerDetails bookingTrip={bookingTrip} markerState={markerState} setMarkerState={setMarkerState} />
          ) : (
            <Skeleton />
          )}
        </div>
        {showShareUrl && (
          <div className="border-gray fixed bottom-0 left-0 w-full border-t bg-white p-5">
            <LinkButton to={shareUrl} target="_blank" variant="custom" size="lg" startIcon="Link2" className="w-full text-primary-dark">
              Share link to Client
            </LinkButton>
          </div>
        )}
      </div>
    </SidePanel>
  );
};

export const TripBasicDetails = ({ bookingTrip }: { bookingTrip: BookingTripLog }) => {
  const {
    id: bookingId,
    dates: { pickup, timezone },
    status,
  } = useSelectedBooking();
  const { dateFormat } = useProfile();
  const { ETA } = useBookingETA({ tripLog: bookingTrip });
  const statusIcon = allStatuses.find((s) => s.id === status);

  const { driverCurrentLocationData } = bookingTrip;
  const showETA = !!driverCurrentLocationData && ETA;

  return (
    <ol className="flex gap-3 rounded-lg bg-neutral-surface-gray p-4 sm:gap-10">
      <li className="flex items-center">
        <Icon name="ArchiveBook" size="md" />
        <Typography className="ml-2">J{bookingId}</Typography>
      </li>
      <li className="flex items-center">
        <Icon name="Calendar" size="md" />
        <Typography className="ml-2">{formatInTimeZone(new Date(pickup), timezone, dateFormat)} </Typography>
      </li>
      <li className="flex items-center">
        <Icon name="Clock" size="md" />
        <Typography className="ml-2 whitespace-nowrap">{formatInTimeZone(new Date(pickup), timezone, "hh:mm a")}</Typography>
        <Tooltip content={format(new Date(pickup), "hh:mm a zzzz")} placement="top">
          <Icon name="InfoCircle" variant="Bold" size="md" className="ml-2 -translate-x-1 -rotate-180 text-neutral-dark-gray" />
        </Tooltip>
      </li>
      {showETA && (
        <li className="flex items-center">
          <Icon name="Timer1" size="md" />
          <Typography className="ml-2">
            ETA {formatInTimeZone(addMinutes(new Date(driverCurrentLocationData.dateTime), Number(ETA)), timezone, "hh:mm a")}
          </Typography>
        </li>
      )}
      <li className="flex items-center">
        {statusIcon && <BookingStatusIcon status={{ icon: statusIcon.icon, color: statusIcon.color }} />}
        <Typography className="ml-2">{statusIcon?.legend}</Typography>
      </li>
    </ol>
  );
};
interface BookingTripProps extends ComponentProps<"div"> {
  bookingTrip: BookingTripLog;
  markerState: BookingMarkerState;
  setMarkerState: (markerState: BookingMarkerState) => void;
  readOnly?: boolean;
}
export const TripBookingDetails = ({ bookingTrip, markerState, setMarkerState, readOnly, className }: BookingTripProps) => {
  const {
    bookingStops: { pickupLocationData, dropoffLocationData },
  } = bookingTrip;

  const toggleMarker = (marker: keyof BookingMarkerState) => {
    if (readOnly) return;
    setMarkerState({ [marker]: !markerState[marker] });
  };

  return (
    <div className={clsx("space-y-2.5", className)}>
      <Typography variant="title">Booking Details</Typography>
      <div className="grid grid-cols-2 gap-4">
        <button
          onClick={() => toggleMarker("pickup")}
          className={clsx("flex items-center gap-2.5 rounded-lg bg-neutral-surface-gray p-5 hover:ring-1 hover:ring-primary-dark", {
            "is-active bg-primary-light text-primary-dark": markerState.pickup,
            "cursor-auto hover:ring-0": readOnly,
          })}
        >
          <Icon name="User" variant="Bold" className="shrink-0 text-primary" />
          <div className="flex flex-col truncate text-left">
            <Typography className="truncate leading-normal">{pickupLocationData?.address}</Typography>
            <Typography className="text-neutral-dark-gray">Pickup Location</Typography>
          </div>
        </button>
        <button
          onClick={() => toggleMarker("dropoff")}
          className={clsx("flex items-center gap-2.5 rounded-lg bg-neutral-surface-gray p-5  hover:ring-1 hover:ring-primary-dark", {
            "is-active bg-primary-light text-primary-dark": markerState.dropoff,
            "cursor-auto hover:ring-0": readOnly,
          })}
        >
          <Icon name="Flag" variant="Bold" className="shrink-0 text-primary" />
          <div className="flex flex-col truncate text-left">
            <Typography className="truncate">{dropoffLocationData?.address}</Typography>
            <Typography className="text-neutral-dark-gray">Destination</Typography>
          </div>
        </button>
      </div>
    </div>
  );
};

export const TripMarkerDetails = ({ bookingTrip, markerState, setMarkerState, readOnly, className }: BookingTripProps) => {
  const { driver } = useSelectedBooking();

  const {
    bookingStops: { waypointsLocationData },
    tripStops: { enRouteToPickupLocationData, passengerPickedUpLocationData, passengerDroppedOffLocationData },
    driverCurrentLocationData,
  } = bookingTrip;

  const toggleMarker = (marker: keyof BookingMarkerState) => {
    if (readOnly) return;
    setMarkerState({ [marker]: !markerState[marker] });
  };

  const hasTripDetails =
    enRouteToPickupLocationData ||
    passengerPickedUpLocationData ||
    passengerDroppedOffLocationData ||
    waypointsLocationData.length > 0 ||
    driverCurrentLocationData;

  if (!hasTripDetails) return null;

  return (
    <div className={clsx("space-y-2.5", className)}>
      <Typography variant="title">Trip Details</Typography>
      <div className="grid grid-cols-3 gap-4">
        {enRouteToPickupLocationData && (
          <button
            onClick={() => toggleMarker("en-route-to-pickup")}
            className={clsx("group inline-flex items-center gap-2.5 rounded-lg bg-neutral-surface-gray p-5", {
              "is-active bg-primary-light text-primary-dark": markerState["en-route-to-pickup"],
              "cursor-auto": readOnly,
            })}
          >
            <Icon name="Car" variant="Bold" className="shrink-0 text-success" />
            <div className="flex flex-col truncate text-left">
              <Typography className="truncate leading-normal">
                {format(new Date(enRouteToPickupLocationData.dateTime), "hh:mm:ss a")}
              </Typography>
              <Typography className="text-neutral-dark-gray group-[.is-active]:text-primary-dark">Driver Route to Pickup</Typography>
            </div>
          </button>
        )}
        {passengerPickedUpLocationData && (
          <button
            onClick={() => toggleMarker("client-picked-up")}
            className={clsx("group inline-flex items-center gap-2.5 rounded-lg bg-neutral-surface-gray p-5", {
              "is-active bg-primary-light text-primary-dark": markerState["client-picked-up"],
              "cursor-auto": readOnly,
            })}
          >
            <Icon name="UserTick" variant="Bold" className="shrink-0 text-info" />
            <div className="flex flex-col truncate text-left">
              <Typography className="truncate leading-normal">
                {format(new Date(passengerPickedUpLocationData.dateTime), "hh:mm:ss a")}
              </Typography>
              <Typography className="text-neutral-dark-gray  group-[.is-active]:text-primary-dark">Client Picked Up</Typography>
            </div>
          </button>
        )}
        {passengerDroppedOffLocationData && (
          <button
            onClick={() => toggleMarker("client-dropped-off")}
            className={clsx("group inline-flex items-center gap-2.5 rounded-lg bg-neutral-surface-gray p-5", {
              "is-active bg-primary-light text-primary-dark": markerState["client-dropped-off"],
              "cursor-auto": readOnly,
            })}
          >
            <Icon name="Flag" variant="Bold" className="shrink-0 text-info" />
            <div className="flex flex-col truncate text-left">
              <Typography className="truncate leading-normal">
                {format(new Date(passengerDroppedOffLocationData.dateTime), "hh:mm:ss a")}
              </Typography>
              <Typography className="text-neutral-dark-gray group-[.is-active]:text-primary-dark">Client Dropped Off</Typography>
            </div>
          </button>
        )}
        {waypointsLocationData?.map((waypoint, index) => {
          return (
            <button
              key={index}
              onClick={() => toggleMarker(`${waypoint}-${index}`)}
              className={clsx("group inline-flex items-center gap-2.5 rounded-lg bg-neutral-surface-gray p-5", {
                "is-active bg-primary-light text-primary-dark": markerState[`${waypoint}-${index}`],
                "cursor-auto": readOnly,
              })}
            >
              <Icon name="Location" variant="Bold" className="shrink-0 text-primary" />
              <div className="flex flex-col truncate text-left">
                <Typography className="truncate leading-normal group-[.is-active]:text-primary-dark">{`Scheduled Stop ${
                  index + 1
                }`}</Typography>
              </div>
            </button>
          );
        })}
        {driver && driverCurrentLocationData && (
          <button
            onClick={() => toggleMarker("driver")}
            className={clsx("group inline-flex items-center gap-2.5 rounded-lg bg-neutral-surface-gray p-5", {
              "is-active bg-primary-light text-primary-dark": markerState["driver"],
              "cursor-auto": readOnly,
            })}
          >
            <Icon name="Car" variant="Bold" className="shrink-0 text-black" />
            <div className="flex flex-col truncate text-left">
              <Typography className="truncate leading-normal">{driver.name}</Typography>
              <Typography className="text-neutral-dark-gray group-[.is-active]:text-primary-dark">Driver Current Location</Typography>
            </div>
          </button>
        )}
      </div>
    </div>
  );
};
export const TripMapWrapper = ({
  bookingTrip,
  markerState,
  setMarkerState,
  readOnly = false,
  height = 645,
}: BookingTripProps & { height?: number }) => {
  const { preTrip, driverOnWay, commenced } = useBookingTripPaths(bookingTrip);

  const mapState: BookingMapState = useMemo(() => {
    const points = !readOnly ? extractTripPoints(bookingTrip.locationData, bookingTrip.tripStops) : [];
    const polylines = extractPolylines(bookingTrip);
    const markers = extractMarkers(bookingTrip);

    return {
      tripPoints: points,
      markers,
      polylines: {
        onRoute: polylines.onRoute,
        onTrip: polylines.onTrip,
        preTrip,
        driverOnWay,
        commenced,
      },
    };
  }, [readOnly, bookingTrip, preTrip, driverOnWay, commenced]);

  return (
    <div
      className="overflow-hidden rounded-lg border border-neutral-mid-gray"
      style={{
        height: `${height}px`,
      }}
    >
      <BookingTripMap
        mode="admin"
        tripLog={bookingTrip}
        markerState={markerState}
        mapState={mapState}
        onToggle={setMarkerState}
        readOnly={readOnly}
      />
    </div>
  );
};
