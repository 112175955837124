import { isEmpty } from "ramda";
import { Controller, useFormContext } from "react-hook-form";
import { CheckboxInput, Divider, Typography } from "@/components/atoms";
import { useAppSelector } from "@/redux/hooks";
import { fleetOptionsSelector } from "@/redux/slices/driver/selectors";
import { FleetFilterFormData } from "../types";

export const VehicleTypeBrandFilter = () => {
  const { vehicleTypes, vehicleBrands } = useAppSelector(fleetOptionsSelector);
  const { control, setValue, watch } = useFormContext<FleetFilterFormData>();
  const watchVehicleType = watch("vehicleType");
  const watchVehicleBrand = watch("vehicleBrand");

  const renderSelectAll = () => {
    const watchVehicleTypeBrandLength = watchVehicleType.length + watchVehicleBrand.length;
    const filterItemsLength = vehicleBrands.length + vehicleTypes.length;

    return (
      <CheckboxInput
        id="vehicleTypeBrandSelectAll"
        label="Select all"
        onChange={({ target }) => {
          if (target.checked) {
            setValue(
              "vehicleType",
              vehicleTypes.map((i) => i.value)
            );
            setValue(
              "vehicleBrand",
              vehicleBrands.map((i) => i.value)
            );
          } else {
            setValue("vehicleType", []);
            setValue("vehicleBrand", []);
          }
        }}
        checked={watchVehicleTypeBrandLength > 0 && watchVehicleTypeBrandLength === filterItemsLength}
      />
    );
  };

  if (isEmpty(vehicleTypes) && isEmpty(vehicleBrands)) return null;

  return (
    <div className="rounded-lg bg-neutral-surface-gray p-5">
      <Typography variant="paragraph">Vehicle Type / Brand</Typography>
      <div className="mt-[18px]">
        {renderSelectAll()}
        <Controller
          control={control}
          name="vehicleType"
          render={({ field }) => (
            <div className="mt-6 flex flex-col gap-6">
              {vehicleTypes.map((i) => (
                <CheckboxInput
                  key={i.value}
                  label={i.name}
                  value={i.value}
                  checked={field.value.includes(i.value)}
                  onChange={({ target }) => {
                    if (target.checked) {
                      field.onChange([...field.value, target.value]);
                    } else {
                      field.onChange(field.value.filter((value) => value !== target.value));
                    }
                  }}
                  variant="check"
                />
              ))}
            </div>
          )}
        />
        <Divider className="my-[25px]" />
        <Controller
          control={control}
          name="vehicleBrand"
          render={({ field }) => (
            <div className="flex flex-col gap-6">
              {vehicleBrands.map((i) => (
                <CheckboxInput
                  key={i.value}
                  label={i.name}
                  value={i.value}
                  checked={field.value.includes(i.value)}
                  onChange={({ target }) => {
                    if (target.checked) {
                      field.onChange([...field.value, target.value]);
                    } else {
                      field.onChange(field.value.filter((value) => value !== target.value));
                    }
                  }}
                  variant="check"
                />
              ))}
            </div>
          )}
        />
      </div>
    </div>
  );
};
