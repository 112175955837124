import { useWindowSize } from "@react-hookz/web";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, Drawer, IconButton, LinkButton, SearchInput } from "@/components/atoms";
import { usePagination, useSearch } from "@/hooks";
import { VehicleFilters } from "../features/Filters/VehicleFilters";

export const MyVehiclesActionMenu = () => {
  const { pathname } = useLocation();
  const [search, setSearch] = useSearch();
  const { clearPagination } = usePagination();
  const [isSearchActive, setIsSearchActive] = useState(false);

  const handleSearch = (value?: string) => {
    if (!value) setIsSearchActive(false);

    clearPagination();
    setSearch(value);
  };

  if (!isSearchActive && !search) {
    return (
      <div className="flex flex-1 items-center justify-end gap-2">
        <Button variant="tertiary" size="sm" startIcon="SearchNormal1" onClick={() => setIsSearchActive(true)}>
          Search
        </Button>
        <FilterButton />
        <LinkButton to={`${pathname}/add`} variant="primary" size="sm" startIcon="Add">
          Add Vehicle
        </LinkButton>
      </div>
    );
  }

  return (
    <div className="flex flex-1 items-center justify-end gap-2">
      <SearchInput
        search={search}
        onSearch={handleSearch}
        autoFocus
        placeholder="Search Number Plate, Make and Model"
        className="w-full max-w-sm p-0 sm:w-fit"
        size="sm"
      />
    </div>
  );
};

const FilterButton = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const { width } = useWindowSize();

  return (
    <>
      {width >= 768 ? (
        <VehicleFilters.Button />
      ) : (
        <Drawer title="Options" open={openMenu} onOpenChange={setOpenMenu}>
          <IconButton
            variant="secondary"
            iconName="More"
            className="h-[34px] border-none !bg-transparent xl:hidden [&>svg]:fill-black"
            size="sm"
            iconSize="sm"
          />
          <div className="flex -translate-x-2 flex-col items-start gap-3">
            <VehicleFilters.DrawerButton onClose={() => setOpenMenu(false)} />
          </div>
        </Drawer>
      )}
    </>
  );
};
