import { saveAs } from "file-saver";
import { isEmpty } from "ramda";
import { useState } from "react";
import { Button, DropdownMenu, Icon, Panel, Spinner, Typography, Modal, Skeleton, SearchInput } from "@/components/atoms";
import { EmptyState } from "@/components/molecules";
import { FleetTable, FleetFilterPanel, FleetLeaderInfo } from "@/components/organisms/Drivers";
import { getPhpHostUrl } from "@/helpers/apiHelpers";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { usePagination } from "@/hooks/usePagination";
import { useGetFleetDriversQuery } from "@/redux/apis/driver/driverApi";
import { useLazyExportDriversQuery } from "@/redux/apis/reporting/reportingApi";
import { useAppSelector } from "@/redux/hooks";
import { fleetDriversSelector } from "@/redux/slices/driver/selectors";
import { addToast } from "@/utils";
import { useGetFleetDriversParams } from "./hooks/useGetFleetDriversParams";

const phpHostUrl = getPhpHostUrl();

export const Fleet = () => {
  const drivers = useAppSelector(fleetDriversSelector);
  const { clearPagination } = usePagination();
  const { params, filter, setFilter, search, setSearch, sorting, setSorting, operatorId } = useGetFleetDriversParams();
  const [openFilter, setOpenFilter] = useState(false);
  const [openFleetLeaderInfo, setOpenFleetLeaderInfo] = useState(false);
  const { isFetching: isFetchingDrivers } = useGetFleetDriversQuery(params, { refetchOnMountOrArgChange: true });
  const [exportDrivers, { isFetching: isFetchingExport }] = useLazyExportDriversQuery();

  const handleSearch = (value?: string) => {
    clearPagination();
    setSearch(value);
  };

  const handleExportDrivers = (type: string) => {
    exportDrivers({ report_type: type })
      .unwrap()
      .then((res) => {
        saveAs(res.data.path, "drivers");
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  };

  const isSearchActive = Boolean(search?.length);
  const numActiveFilters = Object.keys(filter).length;

  const basePath = `${phpHostUrl}/o/${operatorId}`;
  const addDriverPath = `${basePath}/member/driver/create`;

  const renderExportDrivers = () => (
    <DropdownMenu
      button={
        isFetchingExport ? (
          <DropdownMenu.Button as={Button} variant="secondary" className="inline-flex w-full" disabled>
            <div className="flex items-center justify-center">
              <Spinner variant="light" />
              Exporting...
            </div>
          </DropdownMenu.Button>
        ) : (
          <DropdownMenu.Button as={Button} variant="secondary" endIcon="arrow-down" isCustomIcon className="inline-flex w-full">
            Export
          </DropdownMenu.Button>
        )
      }
      isCustomButton
      disabled={isFetchingExport}
      position="bottom-start"
    >
      <DropdownMenu.Item onClick={() => handleExportDrivers("full")} className="w-[280px]">
        <Icon name="ExportSquare" className="mr-2.5 text-primary-dark" size="sm" />
        <Typography className="text-primary-dark">My Drivers - Export (Full)</Typography>
      </DropdownMenu.Item>
      <DropdownMenu.Item onClick={() => handleExportDrivers("active")} className="w-[280px]">
        <Icon name="ExportSquare" className="mr-2.5 text-primary-dark" size="sm" />
        <Typography className="text-primary-dark">My Drivers - Export (Approved)</Typography>
      </DropdownMenu.Item>
    </DropdownMenu>
  );

  return (
    <div className="mb-12 flex flex-1 flex-col">
      <Panel>
        <Typography variant="h3" className="leading-8">
          Manage Drivers
        </Typography>
        <Typography variant="paragraph" className="mt-1 text-neutral-dark-gray">
          List of drivers that are registered to you under your company
        </Typography>
        {drivers.length < 1 && !isSearchActive && isEmpty(filter) && !isFetchingDrivers ? (
          //todo: Refactor needed when "Invite Driver" feature is implemented
          <EmptyState
            title="Manage Drivers"
            description="It looks like you haven't added any drivers yet. Begin by adding your first driver to get started"
          >
            <a href={addDriverPath}>
              <Button startIcon="Add">Add Driver</Button>
            </a>
          </EmptyState>
        ) : (
          <>
            <div className="flex flex-col gap-x-3 gap-y-2 mt-3 lg:flex-row">
              <div className="flex-1 items-center">
                <SearchInput
                  isLoading={isSearchActive && isFetchingDrivers}
                  search={search}
                  onSearch={handleSearch}
                  placeholder="Search Driver"
                  size="md"
                  className="w-full lg:w-fit"
                />
              </div>
              <div className="flex flex-col gap-x-3 gap-y-2 lg:flex-row">
                {numActiveFilters > 0 && (
                  <Button
                    variant="tertiary"
                    startIcon="close"
                    className="text-primary-dark"
                    isCustomIcon
                    onClick={() => setFilter({})}
                  >
                    Clear Filters
                  </Button>
                )}
                <Button variant="secondary" startIcon="Sort" onClick={() => setOpenFilter(true)}>
                  Filters {numActiveFilters > 0 && `(${numActiveFilters})`}
                </Button>
                {renderExportDrivers()}
                <a href={addDriverPath}>
                  <Button startIcon="Add" className="w-full lg:w-fit">Add Driver</Button>
                </a>
              </div>
            </div>
            {isFetchingDrivers ? (
              <Skeleton />
            ) : (
              <FleetTable items={drivers} sorting={sorting} setSorting={setSorting} isSearchActive={isSearchActive} className="mt-4" />
            )}
          </>
        )}
      </Panel>
      {!isFetchingDrivers && <FleetTable.Pagination />}
      <FleetFilterPanel
        open={openFilter}
        onClose={() => setOpenFilter(false)}
        filter={filter}
        setFilter={setFilter}
        onClickFleetLeaderInfo={() => setOpenFleetLeaderInfo(true)}
      />
      <Modal open={openFleetLeaderInfo} onClose={() => setOpenFleetLeaderInfo(false)}>
        <FleetLeaderInfo onClose={() => setOpenFleetLeaderInfo(false)} />
      </Modal>
    </div>
  );
};