import {
  GetFleetDriversRawResponse,
  GetFleetDriversResponse,
  GetFleetLeadersResponse,
  GetFleetOptionsRawResponse,
  GetFleetOptionsResponse,
  GetOffloadDriversRawResponse,
  GetOffloadDriversResponse,
  GetOffloadOptionsRawResponse,
  GetOffloadOptionsResponse,
} from "./types";

const toNameValuePair = (obj: Record<string, string>) =>
  Object.entries(obj)
    .map(([key, label]) => ({ name: label, value: key }))
    .sort((a, b) => a.name.toString().localeCompare(b.name));

export const transformFleetDrivers = (res: GetFleetDriversRawResponse) => {
  const {
    _embedded: { driver: drivers },
    page,
    page_count,
    page_size,
    total_items,
  } = res;

  const response: GetFleetDriversResponse = {
    data: {
      drivers: drivers.map((i) => ({
        id: i.uuid,
        name: i.name,
        email: i.email,
        phone: i.phone,
        tier: i.tier,
        vehicleType: i.vehicle_type.name,
        status: i.status,
        operator: i.operator.name,
        avatar: i.avatar ?? "",
        state: i.state[0],
        isFleetLeader: i.is_fleet_leader,
        profileCompletion: i.metadata.profile_completion,
        rating: i.metadata.rating,
      })),
      page: {
        current: page,
        count: page_count,
        size: page_size,
        total: total_items,
      },
    },
  };

  return response;
};

export const transformFleetLeaders = (res: GetFleetDriversRawResponse) => {
  const {
    _embedded: { driver: drivers },
    page,
    page_count,
    page_size,
    total_items,
  } = res;

  const response: GetFleetLeadersResponse = {
    data: {
      items: drivers.map((i) => ({
        id: i.uuid,
        name: i.name,
        email: i.email,
        phone: i.phone,
        tier: i.tier,
        vehicleType: i.vehicle_type.name,
        status: i.status,
        operator: i.operator.name,
        avatar: i.avatar ?? "",
        state: i.state[0],
        isFleetLeader: i.is_fleet_leader,
        profileCompletion: i.metadata.profile_completion,
        rating: i.metadata.rating,
      })),
      page: {
        current: page,
        count: page_count,
        size: page_size,
        total: total_items,
      },
    },
  };

  return response;
};

export const transformFleetOptions = (res: GetFleetOptionsRawResponse) => {
  const response: GetFleetOptionsResponse = {
    data: {
      options: {
        states: toNameValuePair(res.state),
        tiers: toNameValuePair(res.tier),
        accountStatuses: toNameValuePair(res.account_status),
        vehicleBrands: toNameValuePair(res.vehicle_brand),
        vehicleColors: toNameValuePair(res.vehicle_color),
        vehicleTypes: toNameValuePair(res.vehicle_type),
        vehicleYears: {
          from: res.year.from,
          to: res.year.to,
        },
      },
    },
  };

  return response;
};

export const transformOffloadDrivers = (res: GetOffloadDriversRawResponse) => {
  const {
    _embedded: { driver: drivers },
    page,
    page_count,
    page_size,
    total_items,
  } = res;

  const response: GetOffloadDriversResponse = {
    data: {
      drivers: drivers.map((i) => ({
        id: i.uuid,
        name: i.name,
        state: i.state[0],
        vehicleType: i.vehicle_type.name,
        totalJobs: i.metadata.trip_count,
        operatorName: i.operator.name,
        operatorPhone: i.operator.phone,
        avatar: i.avatar ?? "",
        blockReason: i.block.reason,
        rating: i.metadata.rating,
      })),
      page: {
        current: page,
        count: page_count,
        size: page_size,
        total: total_items,
      },
    },
  };

  return response;
};

export const transformOffloadOptions = (res: GetOffloadOptionsRawResponse) => {
  const response: GetOffloadOptionsResponse = {
    data: {
      options: {
        states: toNameValuePair(res.state),
        vehicleBrands: toNameValuePair(res.vehicle_brand),
        vehicleColors: toNameValuePair(res.vehicle_color),
        vehicleYears: {
          from: res.year.from,
          to: res.year.to,
        },
      },
    },
  };

  return response;
};
