import { SearchInput, Skeleton } from "@/components/atoms";
import { FleetLeadersGrid, EmptyState } from "@/components/molecules";
import { useGetFleetLeadersQuery } from "@/redux/apis/driver/driverApi";
import { useAppSelector } from "@/redux/hooks";
import { networkFleetLeaderItemsSelector } from "@/redux/slices/network/selectors";
import { useGetFleetLeadersParams } from "../hooks/useGetFleetLeadersParams";

interface PrivateNetworkFleetLeadersProps {
  operatorName: string;
}

export const PrivateNetworkFleetLeaders = ({ operatorName }: PrivateNetworkFleetLeadersProps) => {
  const { params, search, setSearch } = useGetFleetLeadersParams();
  const { isFetching } = useGetFleetLeadersQuery(params, { refetchOnMountOrArgChange: true });
  const fleetLeaders = useAppSelector(networkFleetLeaderItemsSelector);

  return (
    <>
      <SearchInput isLoading={Boolean(search && isFetching)} search={search} onSearch={setSearch} className="my-4 w-[320px]" size="md" />
      {isFetching ? (
        <Skeleton />
      ) : fleetLeaders && fleetLeaders.length > 0 ? (
        <FleetLeadersGrid
          items={fleetLeaders.map((driver) => ({
            id: driver.id,
            email: driver.email,
            name: driver.name,
            phone: driver.phone,
            location: driver.state,
            avatar: driver.avatar,
          }))}
        />
      ) : (
        <EmptyState title="No Fleet Leaders" description={`Page where all list of ${operatorName}’s Fleet leaders.`} />
      )}
    </>
  );
};
