import { useMemo } from "react";
import { DropdownMenu, Icon, Stars, Typography } from "@/components/atoms";
import { DataTable } from "@/components/molecules";
import { RatingItem } from "@/redux/slices/rating/types";
import { clsx } from "@/utils";
import { RatingLink } from "./RatingLink";
import { generateColumns } from "./helpers";
import { RatingsTableData } from "./types";

interface RatingsTableProps extends React.HTMLAttributes<HTMLDivElement> {
  items: RatingItem[];
  setEditItem: (item: RatingItem) => void;
  setHistoryItem: (item: RatingItem) => void;
}

export const RatingsTable = ({ items, setEditItem, setHistoryItem, className, ...props }: RatingsTableProps) => {
  const data = useMemo(
    () =>
      items.map(
        (i) =>
          ({
            ...i,
            id: i.id,
            jobId: <RatingLink name={i.jobId} path={i.jobPath} />,
            customer: i.customerName,
            driver: <RatingLink name={i.driverName} path={i.driverPath} />,
            rating: (
              <div className="flex flex-row items-center gap-x-3">
                <Typography variant="paragraph">{i.rating}</Typography>
                <Stars value={i.rating} edit={false} size={18} color1="transparent" />
              </div>
            ),
            comments: i.comments,
            action: (
              <DropdownMenu button={<Icon name="options" isCustom size="lg" />} position="bottom-start" className="!flex max-w-[24px]">
                <DropdownMenu.Item onClick={() => setEditItem(i)}>
                  <Icon name="Edit2" size="sm" className="mr-2.5" />
                  <Typography>Update Rating</Typography>
                </DropdownMenu.Item>
                <DropdownMenu.Item onClick={() => setHistoryItem(i)}>
                  <Icon name="ArrowRight" size="sm" className="mr-2.5" />
                  <Typography>View Rating History</Typography>
                </DropdownMenu.Item>
              </DropdownMenu>
            ),
          } as RatingsTableData)
      ),
    [items, setEditItem, setHistoryItem]
  );
  const columns = useMemo(() => generateColumns(), []);

  return (
    <div className={clsx("relative flex", className)}>
      <DataTable className="[&_table]:table-auto overflow-x-auto w-full" columns={columns} data={data} {...props} />
    </div>
  );
};
