import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Divider, Icon, Panel, Typography } from "@/components/atoms";
import { Vehicle } from "@/redux/slices/vehicle/types";
import { capitalize, clsx, getAssetUrl } from "@/utils";
import { getCountryFlagSrc } from "@/utils/getCountryFlagSrc";
import { PageHeader } from "../../PageHeader/PageHeader";
import { ReactivateVehicleModal } from "../ArchivedVehicles/modals/ReactivateVehicleModal";
import { VehicleStatusBadge } from "../MyVehicles/common/VehicleStatusBadge";
import { getInsuranceStatus, getRegistrationStatus } from "../MyVehicles/helpers";
import { ArchiveVehicleModal } from "../MyVehicles/modals/ArchiveVehicleModal";
import { colors } from "../fixtures";
import { VehicleDetailCard } from "./common/VehicleDetailCard";
import { HistoryLogsDetailCard } from "./components/HistoryLogsDetailCard";
import { RegistrationExpiryDetailCard } from "./components/RegistrationExpiryDetailCard";
import { VehicleAccessDetailCard } from "./components/VehicleAccessDetailCard";
import { VehicleAccreditationDetailCard } from "./components/VehicleAccreditationDetailCard";
import { VehicleCategoryDetailCard } from "./components/VehicleCategoryDetailCard";
import { VehicleInsuranceDetailCard } from "./components/VehicleInsuranceDetailCard";
import { useViewVehicleContext, ViewVehicleProvider } from "./context";
import { VehicleAccessModal } from "./features/VehicleAccess/VehicleAccessModal";
import { VehicleCategoryModal } from "./features/VehicleCategory/VehicleCategoryModal";
import { VehicleDocuments } from "./features/VehicleDocuments/VehicleDocuments";
import { VehicleHistoryLogsModal } from "./features/VehicleHistoryLogs/VehicleHistoryLogsModal";

export const ViewVehiclePanel = () => {
  return (
    <ViewVehicleProvider>
      <PageHeader.Actions />
      <Panel>
        <Header />
        <Divider className="my-5 border-neutral-mid-gray" />
        <Details />
        <Footer />
        <Modals />
      </Panel>
      <VehicleDocuments />
    </ViewVehicleProvider>
  );
};

const Header = () => {
  const { vehicle } = useViewVehicleContext();
  const { avatar, model, registrationNumber, country, state, owner, currentDriver, identifier } = vehicle;

  const renderCurrentDriver = () => {
    if (!currentDriver) return null;

    return (
      <div className="mr-2 flex gap-2 border-r border-neutral-mid-gray pr-2">
        <img
          src={getAssetUrl(currentDriver.avatar, {
            width: 48,
            height: 48,
            fit: "contain",
          })}
          className="h-6 w-6 rounded-full"
        />
        <Typography variant="action" className="text-neutral-dark-gray">
          {currentDriver.name} (Current Driver)
        </Typography>
      </div>
    );
  };

  const renderState = () => {
    const flagSrc = getCountryFlagSrc(country.countryCode);

    return (
      <div className="flex gap-2 ">
        {flagSrc && <img src={flagSrc} className="h-6 w-6 rounded-full object-cover" />}
        <Typography variant="action" className="text-neutral-dark-gray">
          {state.name}
        </Typography>
      </div>
    );
  };

  const renderOwner = () => {
    return (
      <div className="flex items-center gap-2">
        <img
          src={getAssetUrl(owner.avatar, {
            width: 30,
            height: 30,
            background: "transparent",
          })}
          className="-mt-0.5 h-6 w-6 rounded-full border border-neutral-mid-gray object-cover p-1"
        />
        <Typography className="text-neutral-black">Owned by {owner.name}</Typography>
      </div>
    );
  };

  return (
    <div className="flex gap-3">
      <div className="flex flex-1 gap-7">
        <div className="relative self-start">
          <img
            src={getAssetUrl(avatar, {
              width: 200,
              height: 200,
              background: "transparent",
            })}
            alt={model.model}
            className="border-neutral-mid-gray-gray h-[100px] w-[100px] rounded-full border bg-neutral-surface-gray object-contain"
          />
          <div className="border-neutral-mid-gray-gray absolute bottom-0 right-0 flex items-center justify-center rounded-full border bg-neutral-surface-gray p-[7px]">
            <img
              src={getAssetUrl(model.avatar, {
                width: 36,
                height: 36,
                background: "transparent",
                fit: "contain",
              })}
              alt={`${model.make} ${model.model}`}
              className="h-[18px] w-[18px]"
            />
          </div>
        </div>
        <div className="flex flex-col">
          <Typography variant="h2">
            {`${model.make} ${model.model}`}
            {identifier && <span> ({identifier})</span>}
          </Typography>
          {registrationNumber && (
            <Typography variant="action" className="mt-2 flex items-center gap-2 text-neutral-dark-gray">
              Number Plate: {registrationNumber}
              <Status />
            </Typography>
          )}
          <div className="mt-1 flex ">
            {renderCurrentDriver()}
            {renderState()}
          </div>
        </div>
      </div>
      <div className="flex shrink-0 items-start justify-end">{renderOwner()}</div>
    </div>
  );
};

const Details = () => {
  const { vehicle } = useViewVehicleContext();

  return (
    <div className="mt-4 grid grid-cols-3 gap-2.5">
      <VehicleDetailCard
        icon={<Icon name="Calendar" className="text-neutral-black" size="xl" />}
        title="Vehicle Year"
        description={vehicle.year ? vehicle.year.toString() : "-"}
      />
      <VehicleDetailCard
        icon={
          <span
            className={clsx(
              "inline-block h-6 w-6 rounded-full border border-neutral-dark-gray",
              colors.find((c) => c.name === vehicle.color)?.bgColor
            )}
          />
        }
        title="Vehicle Color"
        description={vehicle.color ?? "-"}
      />
      <VehicleDetailCard
        icon={<Icon name="engine" isCustom className="text-neutral-black" size="xl" />}
        title="Engine Type"
        description={capitalize(vehicle.model.engine.toLowerCase())}
      />
      <VehicleDetailCard
        icon={<Icon name="Profile" className="text-neutral-black" size="xl" />}
        title="Vehicle Capacity"
        description={vehicle.capacity.pax.toString()}
      />
      <VehicleDetailCard
        icon={<Icon name="Briefcase" className="text-neutral-black" size="xl" />}
        title="Number of Bags"
        description={vehicle.capacity.bags.toString()}
      />
      <VehicleDetailCard
        icon={<Icon name="Story" className="text-neutral-black" size="xl" />}
        title="VIN"
        description={vehicle.vin ?? "-"}
      />
      <VehicleCategoryDetailCard />
      <VehicleDetailCard
        icon={<Icon name="Car" className="text-neutral-black" size="xl" />}
        title="Network Eligibility"
        description={capitalize(vehicle.model.class.toLowerCase())}
        tooltip={
          <div className="max-w-[295px] font-medium">
            This helps the system match your vehicle accurately within the platform and with external networks, ensuring proper job
            allocations. <br />
            <br />
            <a
              href="https://rideminder.freshdesk.com/en/support/solutions/articles/51000423941-vehicle-eligibility"
              rel="noreferrer"
              target="_blank"
              className="underline"
            >
              {" "}
              Learn more about Network Eligibility
            </a>
          </div>
        }
      />
      <RegistrationExpiryDetailCard />
      {!vehicle.metadata.isArchived && (
        <>
          <VehicleInsuranceDetailCard />
          <VehicleAccreditationDetailCard />
          <VehicleAccessDetailCard />
        </>
      )}
      <HistoryLogsDetailCard />
    </div>
  );
};

const Footer = () => {
  const navigate = useNavigate();
  const { vehicle } = useViewVehicleContext();
  const [pendingVehicle, setPendingVehicle] = useState<Vehicle | undefined>(undefined);

  if (vehicle.metadata.isArchived) {
    return (
      <>
        <div className="mt-5 flex justify-end gap-3">
          <Button variant="primary" startIcon="Refresh2" onClick={() => setPendingVehicle(vehicle)}>
            Reactivate Vehicle
          </Button>
        </div>
        <ReactivateVehicleModal pendingReactivate={pendingVehicle} setPendingReactivate={setPendingVehicle} />
      </>
    );
  }

  return (
    <>
      <div className="mt-5 flex justify-end gap-3">
        <Button variant="secondary" className="!border-danger text-danger" onClick={() => setPendingVehicle(vehicle)}>
          Archive Vehicle
        </Button>
        <Button variant="primary" startIcon="Edit2" onClick={() => navigate(`./edit`)}>
          Edit Vehicle
        </Button>
      </div>
      <ArchiveVehicleModal pendingArchive={pendingVehicle} setPendingArchive={setPendingVehicle} />
    </>
  );
};

const Modals = () => {
  const { vehicle, activeModal, setActiveModal } = useViewVehicleContext();

  return (
    <>
      <VehicleAccessModal
        vehicle={vehicle}
        open={activeModal === "access-manager"}
        onClose={() => {
          setActiveModal(undefined);
        }}
      />
      <VehicleCategoryModal open={activeModal === "vehicle-category"} onClose={() => setActiveModal(undefined)} />
      <VehicleHistoryLogsModal open={activeModal === "history-logs"} onClose={() => setActiveModal(undefined)} />
    </>
  );
};

const Status = () => {
  const { vehicle } = useViewVehicleContext();
  const registrationStatus = getRegistrationStatus(vehicle);
  const insuranceStatus = getInsuranceStatus(vehicle);

  const status = useMemo(() => {
    if (registrationStatus === "expired" || insuranceStatus === "expired") {
      return "expired";
    }

    if (registrationStatus === "expiring" || insuranceStatus === "expiring") {
      return "expiring";
    }

    return registrationStatus;
  }, [registrationStatus, insuranceStatus]);

  switch (status) {
    case "missing-details":
    case "expiring":
    case "expired":
      return (
        <VehicleStatusBadge vehicle={vehicle} size="sm">
          Attention Required
        </VehicleStatusBadge>
      );

    case "verified":
    default:
      return <VehicleStatusBadge vehicle={vehicle} size="sm" />;
  }
};
