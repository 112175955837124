import { DropdownMenu, Icon, Spinner, Toggle, Typography } from "@/components/atoms";
import { DataTableColumnDef } from "@/components/molecules";
import { getPhpHostUrl } from "@/helpers/apiHelpers";
import { FleetDriver, OffloadDriver } from "@/redux/slices/driver/types";
import { clsx, getAssetUrl } from "@/utils";
import { BannedOffloadTableData, FleetTableData, OffloadTableData } from "./types";

export const generateFleetColumns = () => {
  const columns: DataTableColumnDef<FleetTableData>[] = [
    { accessorKey: "name", header: "Name", cell: (props) => props.row.original.name, id: "name" },
    {
      accessorKey: "emailMobile",
      header: "Email / Mobile",
      cell: (props) => props.row.original.emailMobile,
      id: "email",
      size: 370,
    },
    {
      accessorKey: "rating",
      header: "Rating",
      cell: (props) => props.row.original.rating,
      id: "rating",
      size: 70,
    },
    {
      accessorKey: "state",
      header: "State",
      cell: (props) => props.row.original.state,
      enableSorting: false,
    },
    {
      accessorKey: "completion",
      header: "Completion",
      cell: (props) => props.row.original.completion,
      id: "completion",
    },
    {
      accessorKey: "tier",
      header: "Tier",
      cell: (props) => props.row.original.tier,
      enableSorting: false,
    },
    {
      accessorKey: "vehicleCategory",
      header: "Vehicle Category",
      cell: (props) => props.row.original.vehicleCategory,
      enableSorting: false,
    },
    {
      accessorKey: "status",
      header: "Status",
      cell: (props) => props.row.original.status,
      enableSorting: false,
    },
    {
      accessorKey: "action",
      header: "Action",
      cell: (props) => props.row.original.action,
      size: 75,
      enableSorting: false,
    },
  ];

  return columns;
};

export const generateFleetData = (
  items: FleetDriver[],
  loadingFleetDriver: string,
  isLoadingToggleFleetLeader: boolean,
  toggleFleetLeader: (id: string, isFleetLeader: boolean) => void,
  activeOperator?: string
) => {
  const phpHostUrl = getPhpHostUrl();
  const data = items.map(
    (i) =>
      ({
        name:
          isLoadingToggleFleetLeader && loadingFleetDriver === i.id ? (
            <Spinner />
          ) : (
            <div className="flex flex-row items-center gap-2.5">
              {renderFleetAvatar(i.avatar, i.name, i.isFleetLeader)}
              <div className="flex flex-col">
                <a href={`${phpHostUrl}/driver/${i.id}/profile/`} target="_blank" rel="noreferrer">
                  <Typography variant="paragraph">{i.name}</Typography>
                </a>
                <Typography variant="small" className="text-neutral-dark-gray">
                  {i.isFleetLeader && "Fleet Leader"}
                </Typography>
              </div>
            </div>
          ),
        emailMobile: (
          <div className="flex flex-col">
            <Typography variant="paragraph">{i.email}</Typography>
            <Typography variant="small" className="text-neutral-dark-gray">
              {i.phone}
            </Typography>
          </div>
        ),
        rating: renderRating(i.rating),
        state: i.state,
        completion: i.profileCompletion || i.profileCompletion === 0 ? `${i.profileCompletion}%` : "-",
        tier: i.tier || i.tier === 0 ? `${i.tier}` : "-",
        vehicleCategory: i.vehicleType,
        status: renderFleetStatus(i.status),
        action:
          i.operator === activeOperator ? renderFleetActions(i.id, i.isFleetLeader, isLoadingToggleFleetLeader, toggleFleetLeader) : null,
      } as FleetTableData)
  );

  return data;
};

const renderFleetAvatar = (avatar: string | null, name: string, isFleetLeader: boolean) => (
  <div className="ml-1">
    {avatar ? (
      <div className={clsx("h-6 w-6 rounded-full", { "ring-2 ring-success ring-offset-2": isFleetLeader })}>
        <img className="rounded-full" src={getAssetUrl(avatar, { height: 24, width: 24, fit: "cover" })} alt={name} />
      </div>
    ) : (
      <div
        className={clsx("h-6 w-6 rounded-full bg-neutral-mid-gray", {
          "ring-2 ring-success ring-offset-2": isFleetLeader,
        })}
      />
    )}
  </div>
);

const renderFleetStatus = (status: string) => {
  switch (status) {
    case "Pending Qualification":
    case "Application in Progress":
    case "Pending Approval":
      return (
        <Typography variant="paragraph" className="flex flex-row items-center gap-2">
          <span className="h-2 w-2 flex-shrink-0 rounded-full bg-neutral-dark-gray" /> {status}
        </Typography>
      );
    case "Approved":
      return (
        <Typography variant="paragraph" className="flex flex-row items-center gap-2">
          <span className="h-2 w-2 flex-shrink-0 rounded-full bg-success" /> {status}
        </Typography>
      );
    default:
      return (
        <Typography variant="paragraph" className="flex flex-row items-center gap-2">
          <span className="h-2 w-2 flex-shrink-0 rounded-full bg-danger" /> {status}
        </Typography>
      );
  }
};

const renderFleetActions = (
  driverId: string,
  isFleetLeader: boolean,
  isLoadingToggleFleetLeader: boolean,
  toggleFleetLeader: (id: string, isFleetLeader: boolean) => void
) => {
  const phpHostUrl = getPhpHostUrl();
  const updatePath = `${phpHostUrl}/driver/${driverId}/profile/`;

  return (
    <DropdownMenu button={<Icon name="options" isCustom size="lg" />} position="bottom-start" className="!flex max-w-[24px]">
      <a href={updatePath}>
        <DropdownMenu.Item>
          <Icon name="Edit2" size="sm" className="mr-2.5" />
          <Typography variant="paragraph">Update</Typography>
        </DropdownMenu.Item>
      </a>
      <DropdownMenu.Item disabled>
        <Toggle
          className={clsx("mr-2.5", { "hover:cursor-not-allowed": isLoadingToggleFleetLeader })}
          size="xs"
          checked={isFleetLeader}
          onChange={(checked) => toggleFleetLeader(driverId, checked)}
          disabled={isLoadingToggleFleetLeader}
        />
        <Typography variant="paragraph">Fleet Leader</Typography>
      </DropdownMenu.Item>
    </DropdownMenu>
  );
};

export const generateOffloadColumns = () => {
  const columns: DataTableColumnDef<OffloadTableData>[] = [
    { accessorKey: "name", header: "Name", cell: (props) => props.row.original.name, id: "name" },
    {
      accessorKey: "state",
      header: "State",
      cell: (props) => props.row.original.state,
      enableSorting: false,
    },
    {
      accessorKey: "rating",
      header: "Rating",
      cell: (props) => props.row.original.rating,
      enableSorting: false,
      size: 61,
    },
    {
      accessorKey: "vehicleCategory",
      header: "Vehicle Category",
      cell: (props) => props.row.original.vehicleCategory,
      enableSorting: false,
    },
    {
      accessorKey: "totalJobs",
      header: "Total Jobs",
      cell: (props) => props.row.original.totalJobs,
      enableSorting: false,
    },
    {
      accessorKey: "operator",
      header: "Operator",
      cell: (props) => props.row.original.operator,
      enableSorting: false,
    },
    {
      accessorKey: "operatorContact",
      header: "Operator Contact No.",
      cell: (props) => props.row.original.operatorContact,
      enableSorting: false,
    },
    {
      accessorKey: "action",
      header: "Action",
      cell: (props) => props.row.original.action,
      size: 75,
      enableSorting: false,
    },
  ];

  return columns;
};

export const generateOffloadData = (items: OffloadDriver[], onBanDriver: (driver: OffloadDriver) => void) => {
  const phpHostUrl = getPhpHostUrl();
  const data = items.map(
    (i) =>
      ({
        name: (
          <div className="flex flex-row items-center gap-2.5">
            {renderOffloadAvatar(i.avatar, i.name)}
            <a href={`${phpHostUrl}/driver/${i.id}/profile/`} target="_blank" rel="noreferrer">
              <Typography variant="paragraph">{i.name}</Typography>
            </a>
          </div>
        ),
        state: i.state,
        rating: renderRating(i.rating),
        vehicleCategory: i.vehicleType,
        totalJobs: i.totalJobs || i.totalJobs === 0 ? `${i.totalJobs}` : "-",
        operator: i.operatorName,
        operatorContact: i.operatorPhone,
        action: renderOffloadActions(i, onBanDriver),
      } as OffloadTableData)
  );

  return data;
};

const renderOffloadAvatar = (avatar: string | null, name: string) => (
  <div>
    {avatar ? (
      <div className={clsx("h-6 w-6 rounded-full")}>
        <img className="rounded-full" src={getAssetUrl(avatar, { height: 24, width: 24, fit: "cover" })} alt={name} />
      </div>
    ) : (
      <div className="h-6 w-6 rounded-full bg-neutral-mid-gray" />
    )}
  </div>
);

const renderOffloadActions = (driver: OffloadDriver, onBanDriver: (driver: OffloadDriver) => void) => {
  return (
    <DropdownMenu button={<Icon name="options" isCustom size="lg" />} position="bottom-start" className="!flex max-w-[24px]">
      <DropdownMenu.Item onClick={() => onBanDriver(driver)}>
        <Icon name="ban-bold" size="sm" className="mr-2.5 text-danger" isCustom />
        <Typography variant="paragraph">Ban Driver</Typography>
      </DropdownMenu.Item>
    </DropdownMenu>
  );
};

export const generateBannedOffloadColumns = () => {
  const columns: DataTableColumnDef<BannedOffloadTableData>[] = [
    { accessorKey: "name", header: "Name", cell: (props) => props.row.original.name, id: "name" },
    {
      accessorKey: "state",
      header: "State",
      cell: (props) => props.row.original.state,
      enableSorting: false,
    },
    {
      accessorKey: "rating",
      header: "Rating",
      cell: (props) => props.row.original.rating,
      enableSorting: false,
      size: 61,
    },
    {
      accessorKey: "vehicleCategory",
      header: "Vehicle Category",
      cell: (props) => props.row.original.vehicleCategory,
      enableSorting: false,
    },
    {
      accessorKey: "totalJobs",
      header: "Total Jobs",
      cell: (props) => props.row.original.totalJobs,
      enableSorting: false,
    },
    {
      accessorKey: "operator",
      header: "Operator",
      cell: (props) => props.row.original.operator,
      enableSorting: false,
    },
    {
      accessorKey: "reasonForBanning",
      header: "Reason for Banning",
      cell: (props) => props.row.original.reasonForBanning,
      enableSorting: false,
    },
  ];

  return columns;
};

export const generateBannedOffloadData = (items: OffloadDriver[], onViewReason: (driver: OffloadDriver) => void) => {
  const phpHostUrl = getPhpHostUrl();
  const data = items.map(
    (i) =>
      ({
        name: (
          <div className="flex flex-row items-center gap-3.5">
            <Icon name="ban-bold" className="flex-shrink-0 text-danger" isCustom />
            <div className="flex flex-row gap-x-2.5">
              {renderOffloadAvatar(i.avatar, i.name)}
              <a href={`${phpHostUrl}/driver/${i.id}/profile/`} target="_blank" rel="noreferrer">
                <Typography variant="paragraph">{i.name}</Typography>
              </a>
            </div>
          </div>
        ),
        state: i.state,
        rating: renderRating(i.rating),
        vehicleCategory: i.vehicleType,
        totalJobs: i.totalJobs || i.totalJobs === 0 ? `${i.totalJobs}` : "-",
        operator: i.operatorName,
        reasonForBanning: (
          <Typography variant="action" className="cursor-pointer text-info" onClick={() => onViewReason(i)}>
            View Reason
          </Typography>
        ),
      } as BannedOffloadTableData)
  );

  return data;
};

export const renderRating = (rating?: number | null) => (
  <div className="flex flex-row items-center gap-x-1.5">
    <Typography variant="paragraph">{rating || "-"}</Typography>
    <div className="grid flex-1 justify-items-end">
      <Icon name="Star1" className={clsx("flex-1 fill-warning text-warning", { "fill-neutral-gray text-neutral-gray": !rating })} />
    </div>
  </div>
);
