import { Listbox, ListboxButton, ListboxOption, ListboxOptions, Transition } from "@headlessui/react";
import { ComponentProps, Fragment, useMemo } from "react";
import { clsx } from "@/utils";
import { IconButton } from "../Button/IconButton";
import { Icon } from "../Icon/Icon";

export type DropdownSelectOption = { name: string; value?: string };

export interface DropdownSelectProps extends ComponentProps<typeof Listbox> {
  options: string[] | DropdownSelectOption[];
  value?: string;
  placeholder?: string;
  position?: "bottom" | "top";
  iconName?: string;
  disabled?: boolean;
  hasError?: boolean;
  className?: string;
  optionsClassName?: string;
  buttonClassName?: string;
  size?: "sm" | "md" | "lg";
  isNullable?: boolean;
}

export const DropdownSelect = ({
  options,
  placeholder = "Select",
  value,
  onChange,
  position = "bottom",
  iconName,
  disabled,
  hasError = false,
  className,
  optionsClassName,
  buttonClassName,
  size = "lg",
  isNullable = false,
  ...props
}: DropdownSelectProps) => {
  const label = useMemo(() => {
    if (typeof options[0] === "string") return value;
    return value ? (options as DropdownSelectOption[]).find((o) => o.value === value)?.name : undefined;
  }, [options, value]);

  const sizeClass = {
    "h-[32px] text-sm py-1": size === "sm",
    "h-[38px] text-base py-1": size === "md",
    "h-[46px] text-base py-2": size === "lg",
  };

  const errorClass = {
    "!border-danger": hasError,
  };

  return (
    <Listbox defaultValue={value} onChange={onChange} disabled={disabled} className={clsx("relative", className)} {...props}>
      {({ open }) => (
        <div>
          <ListboxButton
            className={clsx(
              "relative w-full cursor-pointer rounded-md border border-neutral-mid-gray bg-white pl-4 pr-10 text-left shadow-sm focus:border-primary focus:shadow-input focus:outline-0",
              sizeClass,
              errorClass,
              buttonClassName
            )}
          >
            <span className="flex items-center gap-2.5 text-base">
              {iconName && (
                <span className="flex items-center">
                  <Icon name={iconName} className="h-5 w-5 text-neutral-dark-gray" />
                </span>
              )}
              {label ? (
                <span
                  className={clsx("block truncate leading-tight", {
                    "text-neutral-mid-gray": disabled,
                  })}
                >
                  {label}
                </span>
              ) : (
                <span className="block h-full truncate  font-medium leading-tight text-neutral-mid-gray">{value || placeholder}</span>
              )}
            </span>
            {isNullable && value && onChange ? (
              <IconButton
                onClick={() => onChange("")}
                iconName="close"
                isCustomIcon
                className="absolute inset-y-0 right-0 px-2 py-4 text-black"
                variant="custom"
                size="sm"
              />
            ) : (
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <Icon name="arrow-down" isCustom className={clsx("h-5 w-5", `${disabled ? "text-neutral-mid-gray" : "text-black"}`)} />
              </span>
            )}
          </ListboxButton>
          <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <ListboxOptions
              className={clsx(
                "dropdown-panel absolute z-10 mt-2 max-h-72 w-full overflow-auto rounded-md border bg-white text-base shadow-lg sm:text-sm",
                {
                  "bottom-full mb-2": position === "top",
                },
                optionsClassName
              )}
            >
              {options.map((option, i) => (
                <ListboxOption
                  key={i}
                  className={({ selected }) =>
                    clsx(selected ? " bg-primary-light" : "text-gray-900", "dropdown relative cursor-pointer select-none py-3 pl-3 pr-9")
                  }
                  value={typeof option === "string" ? option : option.value}
                >
                  <div className="flex items-center">
                    <span className={clsx("ml-3 block min-h-[25px] truncate text-base font-medium")}>
                      {typeof option === "string" ? option : option.name}
                    </span>
                  </div>
                </ListboxOption>
              ))}
            </ListboxOptions>
          </Transition>
        </div>
      )}
    </Listbox>
  );
};

DropdownSelect.displayName = "DropdownSelect";
DropdownSelect.Option = ListboxOption;
