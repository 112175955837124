import { format, parseISO } from "date-fns";
import { isEmpty } from "ramda";
import { useEffect, useState } from "react";
import { Icon, Spinner, Stars, Typography } from "@/components/atoms";
import { EmptyState } from "@/components/molecules";
import { useProfile } from "@/hooks";
import { useLazyGetRatingHistoryQuery } from "@/redux/apis/rating/ratingApi";
import { RatingHistoryItem, RatingItem } from "@/redux/slices/rating/types";
import { addToast } from "@/utils";

interface RatingHistoryProps {
  item?: RatingItem;
  onClose: () => void;
}

export const RatingHistory = ({ item, onClose }: RatingHistoryProps) => {
  const { dateFormat } = useProfile();
  const [getRatingHistory, { isFetching }] = useLazyGetRatingHistoryQuery();
  const [historyItems, setHistoryItems] = useState<RatingHistoryItem[]>([]);

  useEffect(() => {
    if (item) {
      getRatingHistory(item.id)
        .unwrap()
        .then(({ data }) => {
          setHistoryItems(data.history);
        })
        .catch((e) => {
          const message = e.data?.detail;
          addToast("danger", message ? message : "Something went wrong.");
        });
    }
  }, [item, getRatingHistory]);

  return (
    <div className="flex flex-col">
      <div className="grow-0">
        <div className="my-3 flex items-center">
          <div>
            <Typography variant="title">Job ID{item?.jobId && `: ${item?.jobId}`}</Typography>
          </div>
          <button type="button" className="ml-auto rounded-md text-black" onClick={onClose}>
            <span className="sr-only">Close</span>
            <Icon name="close" isCustom size="lg" aria-hidden="true" />
          </button>
        </div>
        <div className="mt-3">
          <Typography className="text-neutral-dark-gray" variant="paragraph">
            Rating History Logs
          </Typography>
        </div>
      </div>
      <div className="mt-6 flex max-h-[580px] min-h-[315px] grow flex-col overflow-scroll overflow-y-auto overflow-x-hidden">
        {isFetching ? (
          <div className="flex h-full w-full grow items-center justify-center">
            <Spinner className="h-8 w-8" />
          </div>
        ) : isEmpty(historyItems) ? (
          <EmptyState title="History Rating" description="There are currently no history ratings available" />
        ) : (
          <div className="flex flex-col space-y-4">
            {historyItems.map((historyItem, i) => {
              const { date, operator, rating, comment, isOriginal } = historyItem;
              return (
                <div key={i} className=" rounded-lg bg-neutral-surface-gray p-5">
                  <div className="grid grid-flow-col justify-stretch gap-10">
                    <div className="flex flex-col">
                      <Typography variant="paragraph" className="text-neutral-dark-gray">
                        Date
                      </Typography>
                      <Typography variant="paragraph">{format(parseISO(date), dateFormat)}</Typography>
                    </div>
                    <div className="flex flex-col">
                      <Typography variant="paragraph" className="text-neutral-dark-gray">
                        {isOriginal ? "Guest Name" : "Operator"}
                      </Typography>
                      <Typography variant="paragraph">{isOriginal ? item?.customerName : operator}</Typography>
                    </div>
                    <div className="flex flex-col">
                      <Typography variant="paragraph" className="text-neutral-dark-gray">
                        {isOriginal ? "Original Rating" : "Updated Rating"}
                      </Typography>
                      <div className="flex flex-row items-center gap-x-3">
                        <Typography variant="paragraph">{rating}</Typography>
                        <Stars value={rating} size={18} color1="transparent" />
                      </div>
                    </div>
                  </div>
                  {!isOriginal && (
                    <div className="mt-5">
                      <Typography variant="paragraph" className="text-neutral-dark-gray">
                        {comment ? "Reason for Updating Rating" : "No Comment Available"}
                      </Typography>
                      {comment && <Typography variant="paragraph">{comment}</Typography>}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
