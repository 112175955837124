import { AccountsModal } from "./modals/AccountsModal";
import { InviteAccountManagerModal } from "./modals/InviteAccountManagerModal";

interface PassengerModalsProps extends React.HTMLAttributes<HTMLDivElement> {
  step?: string;
  onClose: () => void;
}

export const PassengerModals = ({ step, onClose, className, ...props }: PassengerModalsProps) => {
  return (
    <div className={className} {...props}>
      <AccountsModal
        open={step === "accounts"}
        onClose={onClose} 
      />
      <InviteAccountManagerModal
        open={step === "invite-account-manager"}
        onClose={onClose} 
      />
    </div>
  );
};
