import { GetRatingHistoryRawResponse, GetRatingHistoryResponse, GetRatingsRawResponse, GetRatingsResponse } from "./types";

export const transformRatings = (res: GetRatingsRawResponse) => {
  const {
    _embedded: { rating: items },
    page,
    page_count,
    page_size,
    total_items,
    _links,
  } = res;

  const response: GetRatingsResponse = {
    data: {
      items: items.map((i) => {
        const { job, driver, passenger, rating, comments } = i;

        return {
          id: i.uuid,
          jobId: job?.id ? job.id.toString() : "",
          jobPath: job?.path ?? "",
          customerName: passenger?.name ?? "",
          driverName: driver?.name ?? "",
          driverPath: driver?.path ?? "",
          rating,
          comments,
        };
      }),
      page: {
        current: page,
        count: page_count,
        size: page_size,
        total: total_items,
      },
    },
    links: _links,
  };

  return response;
};

export const transformRatingHistory = (res: GetRatingHistoryRawResponse) => {
  const {
    _embedded: { history: items },
    _links,
  } = res;

  const response: GetRatingHistoryResponse = {
    data: {
      history: items.map((i) => {
        const { created_at, account, rating, comment, is_original } = i;
        return {
          date: created_at,
          operator: account?.name ?? "",
          rating,
          comment,
          isOriginal: is_original,
        };
      }),
    },
    links: _links,
  };

  return response;
};
