import { isEmpty } from "ramda";
import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { CheckboxInput, DropdownSelect, Loading, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useOperator } from "@/hooks";
import { useLazyGetCountryStatesQuery } from "@/redux/apis/resource/resourceApi";
import { addToast, getCountryName } from "@/utils";
import { FleetFilterFormData, OffloadFilterFormData } from "../types";

export const StateFilter = () => {
  const { bookableCountryList, country: defaultCountry } = useOperator();
  const [getCountryStates, { isFetching }] = useLazyGetCountryStatesQuery();
  const [country, setCountry] = useState(defaultCountry);
  const [options, setOptions] = useState<Array<{ name: string; value: string }>>([]);

  const { control, setValue, watch } = useFormContext<FleetFilterFormData | OffloadFilterFormData>();
  const watchState = watch("state");

  useEffect(() => {
    getCountryStates(country)
      .unwrap()
      .then((data) => setOptions(data.data.states.map((state) => ({ name: state.abbreviation, value: state.id }))))
      .catch((e) => {
        getErrorMessages(e).forEach((m) => {
          if (m === "Not found") addToast("danger", "No states found.");
          else addToast("danger", m);
        });
        setOptions([]);
        setValue("state", []);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  const getCountryOptions = () => {
    const options: Array<{ name: string; value: string }> = [];
    bookableCountryList.forEach((countryCode) => {
      const country = getCountryName(countryCode);
      if (country) options.push({ name: country, value: countryCode });
    });

    return options;
  };

  const renderSelectAll = () => {
    const watchStateLength = watchState.length;
    return (
      <CheckboxInput
        id="stateSelectAll"
        label="Select all"
        disabled={isEmpty(options)}
        onChange={({ target }) =>
          target.checked
            ? setValue(
                "state",
                options.map((i) => i.value)
              )
            : setValue("state", [])
        }
        checked={watchStateLength > 0 && watchStateLength === options.length}
      />
    );
  };

  return (
    <div className="relative rounded-lg bg-neutral-surface-gray p-5">
      {isFetching && <Loading />}
      <Typography variant="paragraph">Select State</Typography>
      <Controller
        control={control}
        name="state"
        render={({ field }) => (
          <div className="mt-[18px] flex flex-col gap-6">
            <div className="flex flex-row items-center gap-4">
              {bookableCountryList.length > 1 && (
                <DropdownSelect
                  className="flex-1"
                  options={getCountryOptions()}
                  value={country}
                  onChange={setCountry}
                  placeholder="Select Country"
                />
              )}
              {renderSelectAll()}
            </div>
            <div className="grid grid-cols-4 gap-y-6">
              {options.map((i, idx) => (
                <CheckboxInput
                  key={idx}
                  label={i.name}
                  value={i.value}
                  checked={field.value.includes(i.value)}
                  onChange={({ target }) => {
                    if (target.checked) {
                      field.onChange([...field.value, target.value]);
                    } else {
                      field.onChange(field.value.filter((value) => value !== target.value));
                    }
                  }}
                  variant="check"
                />
              ))}
            </div>
          </div>
        )}
      />
    </div>
  );
};
