import { Button, Icon, Loading, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useUnbanDriverMutation } from "@/redux/apis/block/blockApi";
import { OffloadDriver } from "@/redux/slices/driver/types";
import { addToast } from "@/utils";

export interface UnbanDriverFormProps {
  driver?: OffloadDriver;
  onClose: () => void;
  refetchDrivers: () => void;
}

export const UnbanDriverForm = ({ driver, onClose, refetchDrivers }: UnbanDriverFormProps) => {
  const [unbanDriver, { isLoading }] = useUnbanDriverMutation();

  const onUnbanDriver = () => {
    if (driver) {
      unbanDriver({ id: driver.id })
        .unwrap()
        .then(() => {
          addToast("success", `Unbanned ${driver.name}`);
          refetchDrivers();
          onClose();
        })
        .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
    } else {
      onClose();
    }
  };

  const renderModalHeader = () => {
    return (
      <div className="sm:flex sm:items-start">
        <div className="my-3 w-full text-center sm:mt-0 sm:text-left">
          <div className="flex flex-row items-center">
            <Typography variant="title" className="flex-1">
              Reason for Banning Driver
            </Typography>
            <Icon name="close" isCustom className="flex cursor-pointer justify-end" onClick={onClose} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="relative">
      {isLoading && <Loading />}
      {renderModalHeader()}
      <div className="flex w-[550px] max-w-full flex-col">
        <Typography variant="paragraph">{driver?.blockReason}</Typography>
        <div className="mt-4 flex flex-row">
          <Typography variant="paragraph" className="flex-1 text-neutral-dark-gray">
            Operator Contact Number
          </Typography>
          <Typography variant="paragraph" className="flex">
            {driver?.operatorPhone}
          </Typography>
        </div>
        <Button
          size="lg"
          className={"mt-8 [&>svg]:text-danger"}
          variant="secondary"
          startIcon="MinusCircle"
          iconVariant="Bold"
          onClick={() => onUnbanDriver()}
        >
          Unban Driver
        </Button>
      </div>
    </div>
  );
};
