import { Typography } from "@/components/atoms";
import { useViewVehicleContext } from "../../context";
import { UploadVehicleDocument } from "./UploadVehicleDocument";
import { VehicleDocumentCard } from "./VehicleDocumentCard";

export const VehicleDocuments = () => {
  const {
    vehicle: {
      uuid,
      documents: allDocuments,
      metadata: { isArchived },
    },
  } = useViewVehicleContext();

  const documents = allDocuments.filter((doc) => doc.documentType === "OTHER");

  if (isArchived && documents.length === 0) return null;

  return (
    <div className="mt-8">
      <Typography variant="h3">Vehicle Files</Typography>
      {!isArchived && (
        <Typography className="text-neutral-dark-gray">Upload any documents related to this vehicle for audit purposes</Typography>
      )}
      <div className="mt-4">
        <div className="flex space-x-3">
          {documents.map((document) => (
            <VehicleDocumentCard key={document.uuid} document={document} vehicleId={uuid} />
          ))}
          {!isArchived && <UploadVehicleDocument />}
        </div>
      </div>
    </div>
  );
};
