import { format } from "date-fns";
import { useMemo, useState } from "react";
import { Icon, Spinner, Typography } from "@/components/atoms";
import { DataTable } from "@/components/molecules";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useProfile } from "@/hooks";
import {
  useApproveInvitationMutation,
  useRejectInvitationMutation,
  useToggleOffloadFeeExemptionMutation,
} from "@/redux/apis/network/networkApi";
import { useAppDispatch } from "@/redux/hooks";
import { togglePaysCommission, updateNetworkStatus } from "@/redux/slices/network/networkSlice";
import { NetworkItem } from "@/redux/slices/network/types";
import { addToast, clsx } from "@/utils";
import { TableActionsDropdown } from "../actions/TableActionsDropdown";
import { generateNetworkColumns } from "../helpers";
import { PrivateNetworkTableData } from "../types";

interface PrivateNetworkTableProps extends React.HTMLAttributes<HTMLDivElement> {
  items: NetworkItem[];
  setPendingDisconnectRelationship: (item: NetworkItem) => void;
}

export const PrivateNetworkTable = ({ items, setPendingDisconnectRelationship, className, ...props }: PrivateNetworkTableProps) => {
  const { dateFormat } = useProfile();
  const [toggle, { isLoading: isLoadingToggle }] = useToggleOffloadFeeExemptionMutation();
  const [approve, { isLoading: isLoadingApprove }] = useApproveInvitationMutation();
  const [reject, { isLoading: isLoadingReject }] = useRejectInvitationMutation();
  const [loadingNetwork, setLoadingNetwork] = useState("");
  const dispatch = useAppDispatch();

  const toggleOffloadFeeExemption = (id: string, active: boolean) => {
    setLoadingNetwork(id);
    toggle({ id, paysCommission: active })
      .unwrap()
      .then(() => {
        dispatch(togglePaysCommission({ id, active }));
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  };

  const acceptInvitation = (id: string) => {
    setLoadingNetwork(id);
    approve(id)
      .unwrap()
      .then(() => {
        dispatch(updateNetworkStatus({ id, status: "active" }));
        addToast("success", "Successfully accepted invitation");
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  };

  const declineInvitation = (id: string) => {
    setLoadingNetwork(id);
    reject(id)
      .unwrap()
      .then(() => {
        dispatch(updateNetworkStatus({ id, status: "rejected" }));
        addToast("success", "Successfully declined invitation");
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  };

  const renderStatus = (status: string) => {
    switch (status) {
      case "pending":
        return (
          <div className="flex flex-row items-center gap-2">
            <Icon name="InfoCircle" variant="Bold" size="md" className="text-neutral-dark-gray" />
            <Typography variant="paragraph">Invitation Sent</Typography>
          </div>
        );
      case "rejected":
        return (
          <div className="flex flex-row items-center gap-2">
            <Icon name="CloseCircle" variant="Bold" size="md" className="text-danger" />
            <Typography variant="paragraph">Rejected</Typography>
          </div>
        );
      case "disconnected":
        return (
          <div className="flex flex-row items-center gap-2">
            <Icon name="CloseCircle" variant="Bold" size="md" className="text-danger" />
            <Typography variant="paragraph">Disconnected</Typography>
          </div>
        );
      case "active":
        return (
          <div className="flex flex-row items-center gap-2">
            <Icon name="TickCircle" variant="Bold" size="md" className="text-success" />
            <Typography variant="paragraph">Connected</Typography>
          </div>
        );
      default:
        return <></>;
    }
  };

  const data = useMemo(
    () =>
      items.map(
        (i) =>
          ({
            ...i,
            id: i.id,
            operator: (
              <div className="flex flex-row gap-2.5">
                {i.operator.logo ? (
                  <img src={i.operator.logo} className="h-6 w-6" />
                ) : (
                  <div className="h-6 w-6 rounded-full bg-neutral-mid-gray" />
                )}
                <Typography variant="paragraph">{i.operator.name}</Typography>
              </div>
            ),
            status: (isLoadingApprove || isLoadingReject) && loadingNetwork === i.id ? <Spinner /> : renderStatus(i.status),
            linkedAt: format(new Date(i.created), dateFormat),
            offloadFeeExemption:
              isLoadingToggle && loadingNetwork === i.id ? (
                <Spinner />
              ) : (
                i.paysCommission !== undefined && (i.paysCommission ? "No" : "Yes")
              ),
            keyContact: (
              <div className="flex flex-col">
                <Typography variant="paragraph">{i.operator.contact.email}</Typography>
                <Typography variant="small" className="text-neutral-dark-gray">
                  {i.operator.contact.phone}
                </Typography>
              </div>
            ),
            action: (
              <TableActionsDropdown
                item={i}
                acceptInvitation={acceptInvitation}
                declineInvitation={declineInvitation}
                isLoadingToggle={isLoadingToggle}
                setPendingDisconnectRelationship={setPendingDisconnectRelationship}
                toggleOffloadFeeExemption={toggleOffloadFeeExemption}
              />
            ),
          } as PrivateNetworkTableData)
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items]
  );
  const columns = useMemo(() => generateNetworkColumns(), []);

  return (
    <div className={clsx("relative flex", className)}>
      <DataTable columns={columns} data={data} className="[&_table]:table-auto overflow-x-auto w-full" {...props} />
    </div>
  );
};
