import { useDebouncedState } from "@react-hookz/web";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Autocomplete, Button, DropdownSelect, Icon, IconButton, Loading, Modal, SidePanel, Typography } from "@/components/atoms";
import { useOperator } from "@/hooks";
import { useLazySearchDriverQuery } from "@/redux/apis/typeahead/typeaheadApi";
import { UpdateVehicleAccessParams } from "@/redux/apis/vehicle/types";
import {
  useGetVehicleAccessQuery,
  useGrantVehicleAccessMutation,
  useRemoveVehicleAccessMutation,
  useUpdateVehicleAccessMutation,
  vehicleApi,
} from "@/redux/apis/vehicle/vehicleApi";
import { useAppDispatch } from "@/redux/hooks";
import { Vehicle, VehicleAccess } from "@/redux/slices/vehicle/types";
import { addToast, clsx, getAssetUrl } from "@/utils";

interface VehicleAccessModalProps {
  open: boolean;
  onClose: () => void;
  vehicle: Vehicle;
}

export const VehicleAccessModal = ({ vehicle, open, onClose }: VehicleAccessModalProps) => {
  return (
    <SidePanel open={open} onClose={onClose} width={550} focused>
      <section className="flex items-center justify-between p-5">
        <div className="flex items-center gap-2">
          <Icon name="People" size="lg" />
          <Typography variant="title">People with Access</Typography>
        </div>
        <div className="ml-3 flex h-7 items-center">
          <IconButton iconName="ArrowRight" onClick={onClose} className="-mr-2" variant="custom" />
        </div>
      </section>
      <section className="p-5">
        <Typography variant="action">General Access</Typography>
        <ManageVehicle vehicle={vehicle} />
      </section>
    </SidePanel>
  );
};

const ManageVehicle = ({ vehicle }: { vehicle: Vehicle }) => {
  const { accessType, owner } = vehicle;
  const [setVehicleAccess, { isLoading }] = useUpdateVehicleAccessMutation();
  const [transferMode, setTransferMode] = useState(false);

  const handleChange = (type: string) => {
    if (type === accessType) return;

    if (type === "TRANSFER") {
      setTransferMode(true);
      return;
    }

    setVehicleAccess({ id: vehicle.uuid, access: type as UpdateVehicleAccessParams["access"] })
      .unwrap()
      .then(() => {
        addToast("success", "Vehicle access updated successfully");
      })
      .catch(() => {
        addToast("danger", "Failed to update vehicle access");
      });
  };

  const renderAvatar = () => {
    if (accessType === "ALL") {
      return (
        <img
          src={getAssetUrl(owner.avatar, {
            height: 48,
            width: 48,
            background: "transparent",
          })}
          className="h-10 w-10 rounded-full border border-neutral-mid-gray object-cover p-1.5"
        />
      );
    }

    if (transferMode) {
      return (
        <div className="flex h-10 w-10 items-center justify-center rounded-full border border-neutral-mid-gray bg-white p-1.5 text-neutral-black">
          <Icon name="ArrowSwapHorizontal" size="lg" />
        </div>
      );
    }

    return (
      <div className="flex h-10 w-10 items-center justify-center rounded-full border border-neutral-mid-gray bg-white p-1.5 text-neutral-black">
        <Icon name="Lock1" size="lg" />
      </div>
    );
  };

  const value = useMemo(() => {
    if (transferMode) return "TRANSFER";

    return accessType;
  }, [accessType, transferMode]);

  return (
    <>
      <div className="mt-3 flex items-center gap-2 rounded bg-neutral-surface-gray p-4">
        <div className="flex shrink-0 items-center gap-1">
          {renderAvatar()}
          <DropdownSelect
            options={[
              {
                name: "All Drivers",
                value: "ALL",
              },
              {
                name: "Restricted",
                value: "RESTRICTED",
              },
              {
                name: "Transfer Management",
                value: "TRANSFER",
              },
            ]}
            value={value}
            onChange={handleChange}
            disabled={isLoading}
            className="[&>.dropdown-panel]:w-[220px] [&>button]:border-none [&>button]:bg-transparent [&>button]:pl-2 [&>button]:shadow-none"
          />
        </div>
        <div className="ml-auto flex  flex-1 flex-col items-end">
          <Typography variant="small" className="text-right text-neutral-dark-gray  ">
            {value === "ALL"
              ? `All drivers within ${owner.name} can use this vehicle`
              : value === "RESTRICTED"
              ? "Only drivers with access can use this vehicle"
              : "Transfer to another person"}
          </Typography>
        </div>
      </div>
      <DriverManagement vehicle={vehicle} mode={value} />
    </>
  );
};

const DriverManagement = ({ vehicle, mode }: { vehicle: Vehicle; mode: string }) => {
  const { uuid } = vehicle;
  const { data: response, isFetching } = useGetVehicleAccessQuery(uuid);

  const access = useMemo(() => {
    if (!response) return [];

    return response;
  }, [response]);

  if (isFetching && access.length === 0) return <Loading />;

  return (
    <div className="mt-12 flex flex-col">
      {mode === "ALL" ? (
        <AllDriversList access={access} />
      ) : mode === "RESTRICTED" ? (
        <>
          <Typography variant="action">Enter the driver&#39;s email to share this vehicle for bookings</Typography>
          <RestrictedDriversList access={access} vehicleId={uuid} />
        </>
      ) : (
        <>
          <Typography variant="action">Choose a driver to assign this vehicle to</Typography>
          <TransferOwnership access={access} vehicle={vehicle} />
        </>
      )}
    </div>
  );
};

type DriverOption = {
  id: string;
  name: string;
  avatar: string;
  email: string;
};

const RestrictedDriversList = ({ access, vehicleId }: { access?: VehicleAccess[]; vehicleId: string }) => {
  const [value, setValue] = useState<DriverOption | null>(null);
  const [query, setQuery] = useDebouncedState("", 300);
  const [searchDriver, { isFetching, data }] = useLazySearchDriverQuery();
  const [removeAccess, { isLoading: isRemoving }] = useRemoveVehicleAccessMutation();
  const [grantAccess, { isLoading: isGranting }] = useGrantVehicleAccessMutation();

  useEffect(() => {
    if (query.length > 1) searchDriver({ q: query });
  }, [query, searchDriver]);

  const options = useMemo(() => {
    return data?.map((i) => ({
      id: i.id,
      name: i.name,
      avatar: i.avatar,
      email: i.email,
    })) as DriverOption[];
  }, [data]);

  const handleChange = useCallback(
    (option: DriverOption | null) => {
      setValue(option);
      if (!option) return;

      grantAccess({
        vehicleId: vehicleId,
        access: [{ identifier: option.id, role: "VIEWER" }],
      })
        .unwrap()
        .then(() => {
          addToast("success", `Driver ${option.name} access granted successfully`);
          setQuery("");
          setValue(null);
        })
        .catch(() => {
          addToast("danger", `Failed to grant driver ${option.name} access`);
        });
    },
    [grantAccess, setQuery, vehicleId]
  );

  const handleRemoveAccess = useCallback(
    (uuid: string) => {
      removeAccess({ vehicleId: vehicleId, accessId: uuid })
        .unwrap()
        .then(() => {
          addToast("success", "Driver access removed successfully");
        })
        .catch(() => {
          addToast("danger", "Failed to remove driver access");
        });
    },
    [removeAccess, vehicleId]
  );

  return (
    <>
      <Autocomplete
        options={options}
        isLoading={isFetching}
        value={value}
        onValueChange={handleChange}
        onQueryChange={setQuery}
        className="mt-2"
        placeholder="Search and add driver"
        focused
      >
        <Autocomplete.Options className="mt-2 max-h-72 w-full overflow-auto rounded-md border border-neutral-gray bg-white text-base shadow-lg empty:mt-0">
          {options?.map((i, idx) => {
            const isAdded = access?.some((d) => d.account.uuid === i.id);

            return (
              <Autocomplete.Option
                key={idx}
                value={i}
                className={clsx(
                  "flex w-full cursor-pointer px-4 pb-3 pt-4 hover:bg-primary-light",
                  isAdded && "pointer-events-none cursor-not-allowed"
                )}
              >
                <div className="flex-1">
                  <Typography className={`leading-6 ${isAdded ? "text-neutral-dark-gray" : "text-neutral-black"}`}>{i.name}</Typography>
                  <Typography variant="small" className="text-neutral-dark-gray">
                    {i.email}
                  </Typography>
                </div>
                {isAdded ? (
                  <div className="flex shrink-0 items-center justify-end">
                    <Typography variant="action" className="text-neutral-dark-gray">
                      Already added
                    </Typography>
                  </div>
                ) : null}
              </Autocomplete.Option>
            );
          })}
        </Autocomplete.Options>
      </Autocomplete>
      <div className="flex flex-col">
        <Typography variant="action" className="mt-3">
          Driver with Access ({access ? access.length : 0})
        </Typography>
        <div className="relative mt-3 flex flex-col gap-2">
          {(isRemoving || isGranting) && <Loading />}
          {access?.map((i, idx) => (
            <div key={idx} className="flex items-center gap-3 rounded-lg bg-neutral-surface-gray px-3 py-3">
              <div className="flex items-center justify-center rounded-full">
                <img
                  src={getAssetUrl(i.account.avatar, { height: 80, width: 80, background: "transparent" })}
                  className="h-6 w-6 rounded-full object-contain"
                />
              </div>
              <div className="flex flex-1 flex-col">
                <Typography variant="paragraph" className="text-neutral-black">
                  {i.account.name}
                </Typography>
                <Typography variant="small" className="text-neutral-dark-gray">
                  {i.account.email}
                </Typography>
              </div>
              <div className="flex shrink-0 items-center justify-end empty:hidden">
                {i.role !== "OWNER" && (
                  <IconButton iconName="close" isCustomIcon variant="custom" iconSize="lg" onClick={() => handleRemoveAccess(i.uuid)} />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

const AllDriversList = ({ access }: { access?: VehicleAccess[] }) => {
  const { id: operatorId } = useOperator();
  const toDriverManagement = `/operator/${operatorId}/drivers`;

  return (
    <>
      <div className="flex flex-col">
        <div className="mt-3 flex items-center">
          <Typography variant="action">Driver with Access ({access ? access.length : 0})</Typography>
          <Link to={toDriverManagement} className="ml-auto">
            <Typography className="!p-0 text-info underline">View in Driver Management</Typography>
          </Link>
        </div>

        <div className="mt-3 flex flex-col gap-2">
          {access?.map((i, idx) => (
            <div key={idx} className="flex items-center gap-3 rounded-lg bg-neutral-surface-gray px-3 py-3">
              <div className="flex items-center justify-center rounded-full ">
                <img
                  src={getAssetUrl(i.account.avatar, { height: 48, width: 48, background: "transparent" })}
                  className="h-6 w-6 rounded-full object-contain"
                />
              </div>
              <div className="flex flex-1 flex-col">
                <Typography variant="paragraph" className="text-neutral-black">
                  {i.account.name}
                </Typography>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

const TransferOwnership = ({ access, vehicle }: { access?: VehicleAccess[]; vehicle: Vehicle }) => {
  const dispatch = useAppDispatch();
  const { id: operatorId } = useOperator();
  const redirectTo = `/operator/${operatorId}/vehicles`;
  const navigate = useNavigate();
  const [pendingTransferTo, setPendingTransferTo] = useState<{ id: string; name: string } | null>(null);
  const [transferOwnership, { isLoading }] = useGrantVehicleAccessMutation();

  const [value, setValue] = useState<DriverOption | null>(null);
  const [query, setQuery] = useDebouncedState("", 300);
  const [searchDriver, { isFetching, data }] = useLazySearchDriverQuery();

  useEffect(() => {
    if (query.length > 1) searchDriver({ q: query });
  }, [query, searchDriver]);

  const options = useMemo(() => {
    return data?.map((i) => ({
      id: i.id,
      name: i.name,
      avatar: i.avatar,
      email: i.email,
    })) as DriverOption[];
  }, [data]);

  const handleChange = useCallback((option: DriverOption | null) => {
    setValue(option);
    if (!option) return;
    setPendingTransferTo({
      id: option.id,
      name: option.name,
    });
  }, []);

  const handleTransfer = () => {
    if (!pendingTransferTo) return;

    transferOwnership({
      vehicleId: vehicle.uuid,
      access: [{ identifier: pendingTransferTo.id, role: "OWNER" }],
    })
      .unwrap()
      .then(() => {
        addToast("success", "Vehicle ownership transferred successfully");
        setPendingTransferTo(null);
        navigate(redirectTo);
        dispatch(vehicleApi.util.invalidateTags(["Vehicle", "VehicleAccess"]));
      })
      .catch(() => {
        addToast("danger", "Failed to transfer vehicle ownership");
      });
  };

  const renderButtons = () => {
    return (
      <div className="flex justify-end gap-4">
        <Button variant="secondary" onClick={() => setPendingTransferTo(null)}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleTransfer} disabled={isLoading}>
          Transfer Management
        </Button>
      </div>
    );
  };

  return (
    <>
      <div className="flex flex-col">
        <Autocomplete
          options={options}
          isLoading={isFetching}
          value={value}
          onValueChange={handleChange}
          onQueryChange={setQuery}
          className="mt-2"
          placeholder="Search and select driver"
          focused
        >
          <Autocomplete.Options className="mt-2 max-h-72 w-full overflow-auto rounded-md border border-neutral-gray bg-white text-base shadow-lg empty:mt-0">
            {options?.map((i, idx) => {
              const isOwner = access?.find((d) => d.account.uuid === i.id)?.role === "OWNER";

              return (
                <Autocomplete.Option
                  key={idx}
                  value={i}
                  className={clsx(
                    "flex w-full cursor-pointer px-4 pb-3 pt-4 hover:bg-primary-light",
                    isOwner && "pointer-events-none cursor-not-allowed"
                  )}
                >
                  <div className="flex-1">
                    <Typography className={`leading-6 ${isOwner ? "text-neutral-dark-gray" : "text-neutral-black"}`}>{i.name}</Typography>
                    <Typography variant="small" className="text-neutral-dark-gray">
                      {i.email}
                    </Typography>
                  </div>
                  {isOwner ? (
                    <div className="flex shrink-0 items-center justify-end">
                      <Typography variant="action" className="text-neutral-dark-gray">
                        Current Owner
                      </Typography>
                    </div>
                  ) : null}
                </Autocomplete.Option>
              );
            })}
          </Autocomplete.Options>
        </Autocomplete>
        <div className="mt-3 flex flex-col gap-2">
          {access?.map((i, idx) => (
            <div key={idx} className="flex items-center gap-2 rounded-lg bg-neutral-surface-gray px-3 py-3">
              <div className="flex items-center justify-center rounded-full ">
                <img
                  src={getAssetUrl(i.account.avatar, { height: 48, width: 48, background: "transparent" })}
                  className="h-6 w-6 rounded-full object-contain"
                />
              </div>
              <div className="flex flex-1 flex-col">
                <Typography variant="paragraph" className="text-neutral-black">
                  {i.account.name}
                </Typography>
              </div>
              {i.role !== "OWNER" && (
                <IconButton
                  iconName="ArrowRight"
                  variant="custom"
                  onClick={() =>
                    setPendingTransferTo({
                      id: i.account.uuid,
                      name: i.account.name,
                    })
                  }
                />
              )}
            </div>
          ))}
        </div>
      </div>
      <Modal
        open={!!pendingTransferTo}
        onClose={() => setPendingTransferTo(null)}
        title="Transfer Management"
        description={`Transfer management of the ${vehicle.model.make} ${vehicle.model.model} (${vehicle.registrationNumber}) to ${pendingTransferTo?.name}. The new owner will be notified, and the vehicle will no longer appear in your list.`}
        renderButtons={renderButtons}
        className="w-[515px] !p-5"
      />
    </>
  );
};
