import { format } from "date-fns";
import { Icon, IconButton } from "@/components/atoms";
import { useProfile } from "@/hooks";
import { useLazyGetVehicleDocumentQuery } from "@/redux/apis/vehicle/vehicleApi";
import { downloadFile } from "@/utils";
import { VehicleDetailCard } from "../common/VehicleDetailCard";
import { useViewVehicleContext } from "../context";

export const VehicleAccreditationDetailCard = () => {
  const { dateFormat } = useProfile();
  const { vehicle } = useViewVehicleContext();
  const [getDocument, { isLoading }] = useLazyGetVehicleDocumentQuery();

  const accreditationDoc = vehicle.documents.find((doc) => doc.documentType === "ACCREDITATION");

  const handleDownload = () => {
    if (!accreditationDoc) return;

    getDocument({
      vehicleId: vehicle.uuid,
      vehicleDocumentId: accreditationDoc.uuid,
    })
      .unwrap()
      .then((res) => {
        downloadFile(res.filename, res.uuid);
      });
  };

  const ActionButton = () => {
    return (
      <IconButton
        disabled={isLoading}
        iconName="ImportCurve"
        className="!bg-transparent p-1.5 text-neutral-black"
        iconSize="lg"
        variant="tertiary"
        onClick={handleDownload}
      />
    );
  };

  return (
    <VehicleDetailCard
      title="Vehicle Accreditation"
      description={
        accreditationDoc && accreditationDoc.expiryDate ? `Expires at: ${format(new Date(accreditationDoc.expiryDate), dateFormat)}` : "N/A"
      }
      icon={<Icon name="file-pdf" isCustom size="xl" className="text-neutral-black" />}
      action={<ActionButton />}
      actionClick={handleDownload}
    />
  );
};
