import { equals } from "ramda";
import { FormProvider, useForm } from "react-hook-form";
import { Button, Divider, Icon, IconButton, Loading, Typography, SidePanel } from "@/components/atoms";
import { usePagination } from "@/hooks/usePagination";
import { useGetOffloadOptionsQuery } from "@/redux/apis/driver/driverApi";
import { useAppSelector } from "@/redux/hooks";
import { offloadOptionsSelector } from "@/redux/slices/driver/selectors";
import { Filter } from "@/redux/types";
import { DriverRatingFilter } from "./filters/DriverRatingFilter";
import { StateFilter } from "./filters/StateFilter";
import { VehicleBrandFilter } from "./filters/VehicleBrandFilter";
import { VehicleColorFilter } from "./filters/VehicleColorFilter";
import { VehicleYearFilter } from "./filters/VehicleYearFilter";
import { defaultOffloadFilters } from "./fixtures";
import { OffloadFilterFormData } from "./types";

interface OffloadFilterPanelProps {
  open: boolean;
  onClose: () => void;
  filter: Filter;
  setFilter: (filter: Filter) => void;
}

export const OffloadFilterPanel = (offloadFilterPanelProps: OffloadFilterPanelProps) => {
  const { open, onClose, filter, setFilter } = offloadFilterPanelProps;
  const { isFetching } = useGetOffloadOptionsQuery();
  const options = useAppSelector(offloadOptionsSelector);
  const { clearPagination } = usePagination();
  const methods = useForm<OffloadFilterFormData>({ defaultValues: defaultOffloadFilters });
  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = methods;

  const handleOnClose = (data?: OffloadFilterFormData) => {
    if (isDirty && data) reset(data);
    onClose();
  };

  const onSubmit = handleSubmit((data) => {
    const filterData: Filter = {};

    data.state.map((val, i) => (filterData[`state[${i.toString()}]`] = val));
    data.driverRating.map((val, i) => (filterData[`rating[${i.toString()}]`] = val));
    data.vehicleBrand.map((val, i) => (filterData[`vehicle_brand[${i.toString()}]`] = val));
    if (data.vehicleYearFrom) filterData["vehicle_year_from"] = data.vehicleYearFrom;
    if (data.vehicleYearTo) filterData["vehicle_year_to"] = data.vehicleYearTo;
    data.vehicleColor.map((val, i) => (filterData[`vehicle_color[${i.toString()}]`] = val));

    if (!equals(filterData, filter)) {
      clearPagination();
      setFilter(filterData);
    }
    handleOnClose(data);
  });

  return (
    <SidePanel open={open} onClose={() => handleOnClose()} focused>
      <div className="flex items-center justify-between p-5">
        <div className="flex flex-row items-center gap-2">
          <Icon name="Sort" size="lg" />
          <Typography variant="title">Filter</Typography>
        </div>
        <div className="ml-3 flex h-7 items-center">
          <IconButton iconName="ArrowRight" onClick={() => handleOnClose()} variant="tertiary" className="-mr-4 hover:bg-transparent" />
        </div>
      </div>
      <FormProvider {...methods}>
        <form className="relative flex h-full flex-col" onSubmit={onSubmit}>
          {isFetching && <Loading />}
          <div className="h-full flex-1 overflow-y-auto">
            <div className="flex flex-col gap-y-4 px-4 py-6">
              <StateFilter />
              <VehicleBrandFilter options={options.vehicleBrands} />
              <DriverRatingFilter />
              <VehicleYearFilter from={options.vehicleYears.from} to={options.vehicleYears.to} />
              <VehicleColorFilter options={options.vehicleColors} />
            </div>
          </div>
          <div>
            <Divider />
            <div className="flex flex-col gap-3 px-4 py-5">
              <Button type="submit" className="w-full text-center" size="lg">
                Filter Results
              </Button>
              <Button className="w-full text-center" size="lg" variant="secondary" onClick={() => reset(defaultOffloadFilters)}>
                Reset Filters
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </SidePanel>
  );
};
