import { PageHeader, PrivateNetworkHistoryPanel } from "@/components/organisms";

export const PrivateNetworkHistory = () => {
  return (
    <div className="mb-12 flex flex-1 flex-col">
      <PageHeader title="Private Network" />
      <PrivateNetworkHistoryPanel />
    </div>
  );
};
